import { useState } from 'react';
import {
  SubmitMessageContainer,
  RegenerateMessageContainer,
  ChatHintsContainer,
  VariantMessageContainer,
  ChatPermissionsContainer,
} from '@/components/Chat';
import {
  AuthorContext,
  MessagesListRefContext,
} from '@/components/Chat/context';
import { QueryFeedbackModalContainer } from '@/components/Chat.Feedback';
import { GetStartedContainer } from '@/components/Chat.GetStarted';
import { ChatHistoryContainer } from '@/components/Chat.History';
import { TopLevelTTSContainer } from '@/containers/TextToSpeechAudioPlayer/Container';
import type { BrandInsightsCapabilities, Chat } from '@/types';
import { ChatEventsContainer } from '../Chat/Chat.Events.Container';
import { ChatSessionEndGuard } from '../Chat/Chat.SessionEndGuard';
import { SubmitChatActionContainer } from '../Chat/Chat.SubmitAction.Container';
import { ChatScrollContainer } from '../Chat/ScrollContainer';
import { CapabilitiesContext } from './context';
import { SidebarContainer } from './Sidebar.Container';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
  children: React.ReactNode;
};

export const ChatContainer = (props: Props) => {
  const [messagesRef, setMessagesRef] = useState<HTMLDivElement>(null);

  return (
    <CapabilitiesContext.Provider value={props.capabilities}>
      <AuthorContext.Provider value={props.author}>
        <MessagesListRefContext.Provider value={[messagesRef, setMessagesRef]}>
          <ChatScrollContainer>
            <GetStartedContainer>
              <SidebarContainer>
                <QueryFeedbackModalContainer>
                  <ChatHistoryContainer>
                    <ChatPermissionsContainer>
                      <SubmitMessageContainer>
                        <SubmitChatActionContainer>
                          <RegenerateMessageContainer>
                            <VariantMessageContainer>
                              <ChatHintsContainer>
                                <TopLevelTTSContainer>
                                  <ChatSessionEndGuard>
                                    <ChatEventsContainer>
                                      {props.children}
                                    </ChatEventsContainer>
                                  </ChatSessionEndGuard>
                                </TopLevelTTSContainer>
                              </ChatHintsContainer>
                            </VariantMessageContainer>
                          </RegenerateMessageContainer>
                        </SubmitChatActionContainer>
                      </SubmitMessageContainer>
                    </ChatPermissionsContainer>
                  </ChatHistoryContainer>
                </QueryFeedbackModalContainer>
              </SidebarContainer>
            </GetStartedContainer>
          </ChatScrollContainer>
        </MessagesListRefContext.Provider>
      </AuthorContext.Provider>
    </CapabilitiesContext.Provider>
  );
};
