import { useReducer } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { KolSearchTableFilters } from '../interfaces.state';

type TableStateProps = {
  pageSize?: number;
};

export const useTableFilterState = ({ pageSize = 5 }: TableStateProps) => {
  return useReducer(reducerFn, {
    filters: [],
    originalPageSize: pageSize,
    pageIndex: 0,
    pageSize,
    sort: {
      by: null,
      direction: null,
    },
  });
};

function reducerFn(state: KolSearchTableFilters.State, action: KolSearchTableFilters.Actions): KolSearchTableFilters.State {
  let newState = state;

  switch (action.type) {
    case 'filters-cleared':
      newState = {
        ...state,
        filters: [],
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    case 'filters-set':
      newState = {
        ...state,
        filters: action.payload,
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    case 'filter-removed': {
      newState = {
        ...state,
        filters: state.filters.filter(f => f.identifier !== action.payload.identifier),
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    }
    case 'filter-updated': {
      newState = {
        ...state,
        filters: state.filters.map(x => x.identifier === action.payload.identifier ? { ...x, ...action.payload } : x),
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    }
    case 'filter-added': {
      newState = {
        ...state,
        filters: [...state.filters, { identifier: createId(), type: 'contains', value: '', ...action.payload }],
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    }
    case 'sort-set': {
      newState = {
        ...state,
        sort: action.payload,
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    }
    case 'sort-key-toggled': {
      newState = {
        ...state,
        sort: {
          by: action.payload,
          direction: state.sort.by === action.payload ? (state.sort.direction === 'asc' ? 'desc' : 'asc') : 'asc',
        },
        pageSize: state.originalPageSize,
        pageIndex: 0,
      };
      break;
    }
    case 'page-size-set': {
      newState = {
        ...state,
        pageSize: action.pageSize,
      };
      break;
    }
    case 'page-index-set': {
      newState = {
        ...state,
        pageIndex: action.pageIndex,
      };
      break;
    }
  }

  // console.log('state', state);
  // console.log('action', action);
  // console.log('newState', newState);

  return newState;
}