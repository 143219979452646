import { useCallback } from 'react';
import styled from '@emotion/styled';
import { ChatCitationType, ChatQueryStatus, ChatResponseType } from '@/enums';
import type { Chat } from '@/types';
import AudioStatusListener from './AudioStatusListener';
import { KolProfileMessage } from './KolSearch.Profile';
import { AvailabilityInsightsMessage } from './KolSearch.Profile.Availability';
import { LocationForm } from './Location.Form';
import { HcpSelectionMessage } from './Message.Response.HcpSelection';
import { SchedulingAssistantResponse } from './Message.Response.SchedulingAssistant';
import { SystemUserMessage } from './Message.System';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { SystemMessageContainer } from './Message.System.Container';
import { SystemUserErrorMessage } from './Message.System.Error';
import { SystemUserPlaceholderMessage } from './Message.System.Placeholder';
import { MessageWrapper } from './Message.System.Wrapper';
import { UserMessage } from './Message.User';
import { QueryFollowupsContainer } from './Query.FollowupsContainer';
import { QueryEventsContainer } from './QueryEventsContainer';

type Props = {
  canRegenerate: boolean;
  item: Chat.InstanceQueryItem;
};

export const QueryItem = ({ canRegenerate, item }: Props) => {
  const renderSystemResponse = useCallback(() => {
    if (item.query.response?.type === ChatResponseType.HcpSelection) {
      return (
        <HcpSelectionMessage
          queryId={item.query.id}
          queryIdentifier={item.query.identifier}
          value={item.query.state as Chat.QueryState.HcpSelection} />
      );
    }

    if (item.query.response?.type === ChatResponseType.HcpSummary) {
      return (
        <QueryFollowupsContainer queryIdentifier={item.query.identifier} queryStatus={item.query.status}>
          <AudioStatusListener queryIdentifier={item.query.identifier} queryId={item.query.id}>
            <SystemMessageContainer citations={item.query.citations}>
              <KolProfileMessage
                followupQuestions={item.query.suggestedFollowups}
                profile={item.query.response.data.profile}
                queryId={item.query.id} />
            </SystemMessageContainer>
          </AudioStatusListener>
        </QueryFollowupsContainer>
      );
    }

    if (item.query.response?.type === ChatResponseType.HcpAvailability) {
      return (
        <MessageContainer>
          <MessageWrapper>
            <SystemMessageContainer citations={item.query.citations.filter(x => x.type === ChatCitationType.FbmsInteractions)}>
              <BasicSystemUserMessage>
                <AvailabilityInsightsMessage
                  citations={item.query.citations}
                  profile={item.query.response.data.profile} />
              </BasicSystemUserMessage>
            </SystemMessageContainer>
          </MessageWrapper>
        </MessageContainer>
      );
    }

    if (item.query.response?.type === ChatResponseType.LocationSelection) {
      return (
        <MessageContainer>
          <MessageWrapper>
            <BasicSystemUserMessage>
              <LocationForm
                queryId={item.query.id}
                queryIdentifier={item.query.identifier}
                value={item.query.state as Chat.QueryState.LocationSelection}
                disabled={!canRegenerate} />
            </BasicSystemUserMessage>
          </MessageWrapper>
        </MessageContainer>
      );
    }

    if (item.query.response?.type === ChatResponseType.SchedulingAssistant) {
      return (
        <MessageWrapper>
          <BasicSystemUserMessage>
            <SchedulingAssistantResponse
              queryId={item.query.id}
              state={item.query.state as Chat.QueryState.SchedulingAssistant} />
          </BasicSystemUserMessage>
        </MessageWrapper>
      );
    }

    return (
      <MessageContainer>
        <MessageResponse
          item={item.query}
          hasRegenerateFooter={canRegenerate} />
      </MessageContainer>
    );

  }, [item.query, canRegenerate]);

  const renderUserMessage = useCallback(() => {
    if (item.query.hideUserMessage) {
      return null;
    }

    return (
      <MessageContainer>
        <UserMessage
          value={item.query.value} />
      </MessageContainer>
    );
  }, [item.query.value, item.query.hideUserMessage]);

  return (
    <>
      {renderUserMessage()}
      {renderSystemResponse()}
    </>
  );
};

type MessageResponseProps = {
  item: Chat.Query;
  hasRegenerateFooter: boolean;
};

// todo: possibly refactor to inverse the layout and have the current inner UI elements
// like variant selector and other actions contain the message response component
const MessageResponse = ({ item, hasRegenerateFooter }: MessageResponseProps) => {
  switch (item.status) {
    case ChatQueryStatus.LoadingExtended:
    case ChatQueryStatus.Loading: {
      const isTakingLong = item.status === ChatQueryStatus.LoadingExtended;
      return (
        <QueryEventsContainer queryIdentifier={item.identifier}>
          <SystemUserPlaceholderMessage isTakingLong={isTakingLong} />
        </QueryEventsContainer>
      );
    }

    case ChatQueryStatus.Error:
      return (
        <SystemUserErrorMessage
          item={item}
          traceId={item.sentryTraceId}
          hasRegenerateFooter={false} /> // hide for now, can be shown for errored variants but hidden for normal errored queries
      );

    case ChatQueryStatus.Complete:
    case ChatQueryStatus.FollowupsAvailable:
      return (
        <QueryFollowupsContainer queryIdentifier={item.identifier} queryStatus={item.status}>
          <AudioStatusListener queryIdentifier={item.identifier} queryId={item.id}>
            <SystemMessageContainer citations={item.citations}>
              <SystemUserMessage
                item={item}
                hasRegenerateFooter={hasRegenerateFooter} />
            </SystemMessageContainer>
          </AudioStatusListener>
        </QueryFollowupsContainer>
      );
  }
};

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});