import * as $api from '@/api/queries';
import { useQueryDownloader } from '@/containers/QueryDownloader/hooks';

type Params = {
  chatInstanceId: number;
  queryIdentifier: string;
};

export const useDownloadHcpResults = (params: Params) => {

  return useQueryDownloader({
    queryKey: [
      `brand-insights:query:download-hcp-results`,
      params.chatInstanceId,
      params.queryIdentifier,
    ],
    queryFn: ({ queryKey: [, chatInstanceId, queryIdentifier] }) => {
      return $api.downloadHcpResults({ chatInstanceId, queryIdentifier });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

};