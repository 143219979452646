import { useCallback } from 'react';
// import {
//   useInvalidateFilePreviewQueries,
//   useInvalidateFilesQueries,
//   useInvalidateFolderQueries,
// } from '@utils/api';
import * as $groupsApi from '@/api/groups';
import * as $objectsApi from '@/api/workspaces.object';
import Toast from '@/components/Toast';
import { WorkspaceObjectType } from '@/enums';
import { BaseObjectAccessContainer } from './BaseObjectAccessContainer';
import { useObjectAccessState } from './hooks';
import type { ObjectAccess as OA } from './interfaces';
import { ObjectAccessState } from './ObjectAccessState';

type Props =
  IWorkspaceObjectId &
  IWorkspaceId &
  ChildrenProps;

const ObjectAccessContainer = (props: Props) => {

  // const invalidateFileQueries = useInvalidateFilesQueries();
  // const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();
  // const invalidateFolderQueries = useInvalidateFolderQueries();

  const [state] = useObjectAccessState();

  const fetchAccess = useCallback(() => {
    return $objectsApi.getObjectAccess({
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    });
  }, [
    props.objectId,
    props.workspaceId,
  ]);

  const saveAccess = useCallback((data: OA.SaveAccessParams) => {
    return $objectsApi.saveObjectAccess({
      items: data.items,
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    }).then(res => {

      // invalidateFileQueries();

      // if (state.object.typeId === WorkspaceObjectType.File) {
      //   invalidateFilePreviewQueries({
      //     fileId: state.object.entityId,
      //   });
      // } else if (state.object.typeId === WorkspaceObjectType.Folder) {
      //   invalidateFolderQueries({
      //     folderId: state.object.entityId,
      //   });
      // }

      Toast.alert({
        title: `Access Updated`,
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue updating access`,
      });
    });
  }, [
    // invalidateFileQueries,
    // invalidateFilePreviewQueries,
    // invalidateFolderQueries,
    props.objectId,
    props.workspaceId,
    state.object,
  ]);

  const fetchGroupUsers: OA.FetchGroupUsers = useCallback(data => {
    return $groupsApi.getGroupUsers({
      allGroups: false,
      ...data,
    });
  }, []);

  const containerProps = {
    fetchAccess,
    fetchGroupUsers,
    saveAccess,
  };

  return (
    <BaseObjectAccessContainer {...containerProps}>
      {props.children}
    </BaseObjectAccessContainer>
  );
};

const WithState = (props: Props) => {

  return (
    <ObjectAccessState>
      <ObjectAccessContainer {...props} />
    </ObjectAccessState>
  );
};

export { WithState as ObjectAccessContainer };