/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CancelAction, DownloadQueryHint } from '@/components/presentation/Message.QueryHint';
import { KolSearchStatus } from '@/enums/kol';
import type { KolSearchForm } from '@/types/kol';
import {
  KolSearchFormContext,
  KolSearchResultsContext,
  KolSearchCancelContext,
  KolSearchStatusContext,
  UpdateKolSearchProfileContext,
  KolResetSearchContext,
  KolSearchIdContext,
} from './context';
import { GenerateRouteButton } from './GenerateRouteButton';
import { useDownloadKolSearch, useIsSmartRouting } from './hooks';
import { KolResultsTable, KolResultsTablePaginationContainer } from './KolSearch.Table';
import { KolTableContainer } from './KolTableContainer';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolResultsMessage = () => {
  const status = useContext(KolSearchStatusContext);
  const formState = useContext(KolSearchFormContext);
  const results = useContext(KolSearchResultsContext);
  const kolSearchId = useContext(KolSearchIdContext);
  const cancelSearch = useContext(KolSearchCancelContext);
  const selectProfile = useContext(UpdateKolSearchProfileContext);
  const handleNewSearch = useContext(KolResetSearchContext);
  const { download: downloadKolSearch } = useDownloadKolSearch({ kolSearchId });
  const isSmartRouting = useIsSmartRouting();

  const copy = `${buildMessageFilter(formState)}. Select a profile by clicking "View" and ask additional questions.`;

  const isInteractive = status === KolSearchStatus.MultipleResultsReturned;

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={css({
        // minWidth: '100%',
        maxWidth: '100%',
      })}>
        <Body>
          <KolTableContainer pageSize={10}>
            <KolResultsTablePaginationContainer records={results}>
              {copy}
              <StyledResultsTable
                onSelectProfile={selectProfile}
                isInteractive={isInteractive}
              />
              {isInteractive && (
                <>
                  <StyledDownloadButton
                    value="Download List"
                    onClick={downloadKolSearch} />
                  {isSmartRouting && <GenerateRouteButton />}
                  <StyledCancelButton
                    displayValue="Start a new search"
                    onClick={handleNewSearch} />
                  <StyledCancelButton
                    displayValue="View recommended HCPs"
                    onClick={cancelSearch} />
                </>
              )}
            </KolResultsTablePaginationContainer>
          </KolTableContainer>
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

const StyledDownloadButton = styled(DownloadQueryHint)({
  marginTop: 15,
});

const StyledCancelButton = styled(CancelAction)({
  marginTop: 15,
});

const StyledResultsTable = styled(KolResultsTable)({
  marginTop: 15,
});

const Body = styled.div({
  // width: 725,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

function buildMessageFilter(formState: Partial<KolSearchForm>) {
  const filters: string[] = [];
  if (formState.name) filters.push(`matching the name "${formState.name}"`);

  if (formState.location) filters.push(`near ${formState.location.name ?? `the location (${formState.location.latitude}, ${formState.location.longitude})`}`);

  return `Sure, I found the following HCPs ${filters.join(' and ')}`;
}