import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { Props as SelectProps } from 'react-select';
import Select from 'react-select/async';
import { searchLocation } from '@/api/search';
import { buildSelectStyles, buildSelectTheme } from '@/components/Chat.Filters/Presentation';
import { useTheme } from '@/components/Theme';
import type { LocationItem } from '@/types/location';
import { useBrowserLocation } from '@/utils/hooks/useBrowserLocation';

type Props = {
  placeholder?: string;
  value: LocationItem;
  onChange: (value: LocationItem) => void;
} & Omit<SelectProps<LocationItem, false>, 'value' | 'onChange' | 'loadOptions' | 'getOptionLabel' | 'getOptionValue'>;

export const SelectLocation = ({
  value,
  onChange,
  placeholder = 'Start typing an address',
  styles,
  ...props
}: Props) => {
  const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
  const { getLocation } = useBrowserLocation({ onSuccess: setLocation, onError: () => { } });

  const searchMutation = useMutation({
    mutationKey: ['searchLocation'],
    mutationFn: (val: string) => val?.length > 1 ? searchLocation({ query: val, lat: location?.latitude, lon: location.longitude }).then(r => r.results) : Promise.resolve([] as LocationItem[]),
  });

  const onFocus = useCallback(() => {
    getLocation();
  }, [getLocation]);

  const theme = useTheme();

  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const mergedStyles = useMemo(() => {
    return Object.keys(styles || {}).reduce((acc, key) => {
      return {
        ...acc,
        [key]: (provided, state) => {
          return {
            ...(selectStyles[key]?.(provided, state) || {}),
            ...styles[key](provided, state),
          };
        },
      };
    }, selectStyles);
  }, [styles, selectStyles]);

  return (
    <Select
      cacheOptions
      loadOptions={val => searchMutation.mutateAsync(val)}
      defaultOptions
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      placeholder={placeholder}
      theme={selectTheme}
      styles={mergedStyles}
      {...props} />
  );
};