import React, { useContext, useEffect, useMemo } from 'react';
import { OnTextToSpeechAudioReadyContext, TextToSpeechStateContext, VoiceModeContext } from '@/containers/TextToSpeechAudioPlayer/Context';
import { QuerySocketEvent } from '@/enums/websocket';
import { useChatQuerySocket } from '@/websocket';

type Props = {
  children: React.ReactNode;
  kolSearchId?: number;
  queryId?: number;
  queryIdentifier: string;
};

export const AudioStatusListener = (props: Props) => {
  const audioState = useContext(TextToSpeechStateContext);
  const socket = useChatQuerySocket(props.queryIdentifier);
  const onAudioReady = useContext(OnTextToSpeechAudioReadyContext);
  const voicemode = useContext(VoiceModeContext);

  const enabled = useMemo(() => {
    if (!audioState) return false;

    return audioState?.queryId === props.queryId ||
      audioState?.kolSearchId === props.kolSearchId;
  }, [audioState, props.queryId, props.kolSearchId]);

  useEffect(() => {
    if (!socket) return;

    if (enabled) {
      const handleAudioAvailable = (data: { audioUrl: string; queryIdentifier: string }) => {
        if (data.queryIdentifier !== props.queryIdentifier) return;
        console.log('WebSocket event received, audio available:', data.audioUrl);
        onAudioReady(data.audioUrl);
      };

      socket.on(QuerySocketEvent.QueryAudioAvailable, handleAudioAvailable);

      return () => {
        socket.off(QuerySocketEvent.QueryAudioAvailable, handleAudioAvailable);
      };
    }
  }, [enabled, socket, props.queryIdentifier, onAudioReady, voicemode]);

  return (
    <>
      {props.children}
    </>
  );
};

export default AudioStatusListener;