import { useEffect, useMemo } from 'react';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { useFetchObjectAccess, useObjectAccessState } from '@/containers/ObjectAccess/hooks';
import ObjectAccessForm from './ObjectAccess';
import ObjectAccess from './ReadOnly';
import styles from './style/Modal.module.css';
import { sortObjectAccess } from './utils';

type Props =
  Pick<ModalProps,
    'onClose' |
    'open'>;

export const ObjectAccessModal = ({ onClose, open }: Props) => {

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      open={open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header
            className={styles.header}
            text="Manage Access" />
          <ObjectAccessForm onCancel={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export const ReadOnlyObjectAccessModal = ({ onClose, open }: Props) => {

  const [state] = useObjectAccessState();
  const fetchAccess = useFetchObjectAccess();

  useEffect(() => { fetchAccess(); }, [fetchAccess]);

  const sorted = useMemo(() => state.items.sort(sortObjectAccess), [state.items]);

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      open={open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header
            className={styles.header}
            text="Manage Access" />
          <ObjectAccess items={sorted} />
        </div>
      </div>
    </Modal>
  );
};

export const useObjectAccessModal = () => useModal(ObjectAccessModal);
export const useReadOnlyObjectAccessModal = () => useModal(ReadOnlyObjectAccessModal);

export default ObjectAccessModal;