import { memo, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { isJnJEnvironment } from '@/config/utils';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { MediaOrientation } from '@/enums';
import horizontalJnJ from '@/static/logos/logo-jnj-horizontal.png?url';
import icontrinity from '@/static/logos/logo-trinity-icon.svg?url';
import horizonaltrinity from '@/static/logos/logo-trinity.svg?url';
import { cx } from '@/utils';
import { useNavbarLogo } from './hooks/useNavbarLogo';
import styles from './style/Logo.module.css';

type SquareProps = {
  size: number;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

type LandscapeProps = {
  className?: string;
};

export const Responsive = (props: unknown) => {

  const classes = {
    lg: styles.lg,
    sm: styles.sm,
  };

  return (
    <div className={styles.responsive}>
      <IconTrinity
        className={classes.sm}
        size={32} />
      <HorizontalNavLogo className={classes.lg} />
    </div>
  );
};

Responsive.displayName = 'Branding.Logo.Responsive';

export const HorizontalNavLogo = memo((props: LandscapeProps) => {
  const ctx = useContext(AppThemingContext);
  const isJnJEnv = isJnJEnvironment();

  const whitelabel = useNavbarLogo({
    horizontal: ctx?.query?.data?.logo?.horizontal,
    square: ctx?.query?.data?.logo?.square,
  });
  const classes = cx(styles.root, styles.landscape, props.className);

  if (isJnJEnv) {
    return (
      <div className={cx(classes, styles.horizontal)}>
        <img
          className={classes}
          draggable={false}
          src={horizontalJnJ}
          width={165}
          height={48} />
      </div>
    );
  }

  if (ctx?.query?.isInitialLoading || ctx?.query?.isFetching) {
    return (
      <div className={styles.root}>
        <div className={styles.loading}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40} />
        </div>
      </div>
    );
  }

  if (!ctx?.query?.data?.enabled || !whitelabel?.url) {
    return (
      <div className={cx(classes, styles.trinity)}>
        <img
          className={classes}
          draggable={false}
          src={horizonaltrinity} />
      </div>
    );
  }

  if (ctx?.query?.data?.enabled && whitelabel?.url) {
    return (
      <div className={cx(classes, { [styles.horizontal]: whitelabel.orientation === MediaOrientation.Horizontal })}>
        <img
          className={classes}
          draggable={false}
          src={whitelabel.url} />
      </div>
    );
  }
});

HorizontalNavLogo.displayName = 'Branding.Logo.Horizonal.Trinity';

const IconTrinity = memo(({ size, ...props }: SquareProps) => {
  return (
    <img
      {...props}
      src={icontrinity}
      style={{ width: size, height: size }} />
  );
});

IconTrinity.displayName = 'Branding.Logo.Icon.Trinity';