import { useCallback, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { X } from 'react-feather';
import MultiSelect from 'react-select';
import * as $api from '@/api';
import { PrimaryButton } from '@/components/presentation/Button.Primary';
import { SecondaryButton } from '@/components/presentation/Button.Secondary';
import { colors } from '@/components/Theme/theme';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import type { Chat } from '@/types';

type Props = {
  chatInstanceId: number;
  selected: Chat.User[];
  onAddUser: (user: Chat.User) => unknown;
  onRemoveUser: (user: Chat.User) => unknown;
};

export const ModalShareChatPicker = ({ chatInstanceId, selected, onAddUser, onRemoveUser }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [value, setValue] = useState<Chat.User>();

  const query = useQuery<{ items: Chat.User[] }>({
    queryKey: [`chat:get-shareable-users`, chatInstanceId, keyword],
    queryFn: () => {
      if (!keyword?.trim().length) return { items: [] };

      return $api.getChatShareableUsers({
        chatInstanceId,
        search: keyword,
      });
    },
    enabled: true,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    placeholderData: {
      items: [],
    },
  });

  const handleAdd = useCallback(() => {
    setKeyword('');
    setValue(null);
    onAddUser(value);
  }, [onAddUser, value]);

  const handleRemove = useCallback((user: Chat.User) => () => {
    onRemoveUser(user);
  }, [onRemoveUser]);

  const options = useMemo(() => {
    return query.data.items.filter(i => !selected.find(s => s.id === i.id));
  }, [query.data.items, selected]);

  const { theme } = useContext(AppThemingContext);

  return (
    <Root>
      <ColumnLayer>
        <Label>Enter Name or Email</Label>
        <SelectorContainer>
          <Selector
            isMulti={false}
            value={value}
            placeholder={`Search...`}
            onChange={(item: Chat.User) => setValue(item)}
            getOptionLabel={(i: Chat.User) => `${i.profile.firstName} ${i.profile.lastName} (${i.profile.email})`}
            getOptionValue={(i: Chat.User) => `${i.id}`}
            onInputChange={value => setKeyword(value)}
            options={options}
            noOptionsMessage={_ => `No people found.`} />
          {!theme?.enabled
            ? <SecondaryButton
              disabled={!value}
              onClick={handleAdd}>
              Add
            </SecondaryButton>
            : <PrimaryButton
              disabled={!value}
              onClick={handleAdd}>
              Add
            </PrimaryButton>}
        </SelectorContainer>
      </ColumnLayer>
      <ColumnLayer>
        <Label>Send To</Label>
        <ShareeContainer>
          {selected.map(selected => (
            <Sharee key={`${selected.id}`}>
              <ShareeName>{selected.profile.firstName} {selected.profile.lastName} ({selected.profile.email})</ShareeName>
              <ShareeActions><StyledX onClick={handleRemove(selected)} /></ShareeActions>
            </Sharee>
          ))}
          {!selected.length && (
            <Empty>None selected!</Empty>
          )}
        </ShareeContainer>
      </ColumnLayer>
    </Root>
  );
};

const WrappedMultiSelect = (props: Parameters<typeof MultiSelect>[0]) => {
  const { palette, theme } = useContext(AppThemingContext);

  return (
    <MultiSelect
      styles={theme?.enabled ? {
        control: (provided, state) => ({
          ...provided,
          borderColor: state.isFocused ? palette?.primary?.main : colors.gray.main,
          boxShadow: `0 0 0 1px ${palette?.primary?.main}`,
          '&:hover': {
            borderColor: palette?.primary?.main,
          },
        }),
      } : {}}
      {...props} />
  );
};

const Root = styled.div``;

const Label = styled.label`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.bold};
  margin-bottom: 8px;
`;

const SelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Selector = styled(WrappedMultiSelect)`
  flex: 1;
`;

const ColumnLayer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const ShareeContainer = styled.div`
  /* padding: 0 40px; */
`;

const Sharee = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.palette.gray.light2};
    margin-bottom: 10px;
  }
`;

const ShareeName = styled.div`
  flex: 1;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ShareeActions = styled.div`
  flex: 0 0 30px;
`;

const StyledX = styled(X)`
  cursor: pointer;
  color: ${props => props.theme.palette.black.light2};
  transition: color 0.2s;

  &:hover {
    color: ${props => props.theme.palette.black.main};
  }
`;

const Empty = styled.div`
  color: ${props => props.theme.palette.gray.main};
  font-family: ${props => props.theme.fonts.regular};
`;