import { cx } from '@/utils';
import styles from './style/PopperWrapper.module.css';

type Props = {
  className?: string;
} & ChildrenProps;

export const PopperWrapper = (props: Props) => {
  return (
    <div className={cx(styles.popper, props.className)}>
      {props.children}
    </div>
  );
};