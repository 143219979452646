import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { ProjectEntitiesContainer } from '@/containers/Project.Entities';
import { ProjectDetailsContext } from './Context';
import { ProjectContainer } from './Project.Container';
import { ProjectTabs } from './Project.Tabs';

type Props = unknown;

const ProjectTabStateContainer = (props: Props) => {
  const ctx = useContext(ProjectDetailsContext);

  const entities = useMatch({
    path: `/projects/:projectId/entities`,
  });

  const projectId = ctx.query.data?.project?.id;
  const isReady = !!projectId;

  return (
    <ProjectEntitiesContainer
      enabled={!!entities && isReady}
      projectId={projectId}>
      <ProjectTabs />
    </ProjectEntitiesContainer>
  );
};

export const ProjectMain = (props: Props) => {

  return (
    <ProjectContainer>
      <ProjectTabStateContainer />
    </ProjectContainer>
  );
};

ProjectMain.displayName = 'Project.Main';