import { useCallback, useContext, useEffect } from 'react';
import { ChatSocketEvent } from '@/enums/websocket';
import { useAppSelector } from '@/store';
import { useChatSocket } from '@/websocket';
import { ActiveChatSessionContext } from './context';

type Props = {
  children: React.ReactNode;
};

export const ChatEventsContainer = (props: Props) => {

  const session = useContext(ActiveChatSessionContext);
  const chatIdentifier = session.identifier;

  const socket = useChatSocket(chatIdentifier);
  const enabled = useAppSelector(state => state.group.features?.deleteChatsOnSessionEnd); // Only enable for this case so far

  const handleChatSessionDeleted = useCallback(() => {
    console.log('WebSocket event received, chat session ended');
    window.location.reload();
  }, []);

  useEffect(() => {
    if (!socket) return;

    if (enabled) {
      socket.on(ChatSocketEvent.ChatDeleted, handleChatSessionDeleted);

      return () => {
        socket.off(ChatSocketEvent.ChatDeleted, handleChatSessionDeleted);
      };
    }
  }, [enabled, handleChatSessionDeleted, chatIdentifier, socket]);

  return (
    <>
      {props.children}
    </>
  );
};