export enum ChatContextType {
  // Brand = 'brand',
  Document = 'document',
  Folder = 'folder',
  Global = 'global',
  // Project = 'project',
  Transcript = 'transcript',
  KolProfile = 'kol-profile',
}

export enum ChatInstanceItemType {
  Query = 1,
  // FileUpload = 2,
  KolSearch = 3,
}

export enum ChatFileUploadStatus {
  Errored = 'errored',
  Initiated = 'initiated',
  Started = 'started',
  Ready = 'ready',
  Uploaded = 'uploaded',
}

export enum ChatHintType {
  AnalysisTags = 'analysis-tags',
  FindQuotes = 'find-quotes',
  Text = 'text',
}

export enum ChatCitationType {
  KolProfile = 'kol-profile',

  Document = 'document',
  Transcript = 'transcript',
  CrmNote = 'crm-note',
  StrategicImperative = 'strategic-imperative',

  ScientificLeaderTopic = 'scientific-leader-topic',
  ClinicalLeaderTopic = 'clinical-leader-topic',
  DigitalLeaderTopic = 'digital-leader-topic',

  Association = 'association',
  Affiliation = 'affiliation',
  Collaboration = 'collaboration',
  CompanySponsorship = 'company-sponsorship',

  ClinicalTrial = 'clinical-trial',
  Conference = 'conference',
  Publication = 'publication',
  CongressAbstract = 'congress-abstract',

  UnmetNeed = 'unmet-need',
  ContactDetail = 'contact-detail',

  DocumentedInterests = 'documented-interests',
  MedicalInsights = 'medical-insights',
  CallCenterInteractions = 'call-center-interactions',
  FbmsInteractions = 'fbms-interactions',
}

export enum ChatQueryStatus {
  Error = 'error',
  Loading = 'loading',
  LoadingExtended = 'loading-extended',
  Complete = 'complete',
  FollowupsAvailable = 'followups-available',
}

export enum InteractionType {
  CallCenterInteraction = 'call-center-interaction',
  FbmsInteraction = 'fbms-interaction',
  MedicalInsight = 'medical-insight',
  DocumentedInterest = 'documented-interest',
}

export enum ChatResponseType {
  HcpAvailability = 'hcp-availability',
  HcpSummary = 'hcp-summary',
  HcpSelection = 'hcp-selection',
  Markdown = 'markdown',
  Text = 'raw-text',
  LocationSelection = 'location-selection',
  Chart = 'chart',
  LocationRouting = 'routing-response',
  SchedulingAssistant = 'scheduling-assistant',
}