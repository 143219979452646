import { useCallback, memo } from 'react';
import styled from '@emotion/styled';
import { cx } from '@/utils';
import { shouldForwardProp } from '@/utils/emotion';

export type SwitchTab = 'left' | 'right';

type SwitchProps = {
  className?: string;
  classes?: {
    root?: string;
    toggle?: string;
    left?: string;
    right?: string;
  };
  active: SwitchTab;
  labelLeft: string;
  labelRight: string;
  onClick: (value: SwitchTab) => unknown;
  style?: React.CSSProperties;
};

export const Switch = memo(({ className, classes = {}, active, labelLeft, labelRight, onClick, style = {} }: SwitchProps) => {
  const handleClick = useCallback((tab: SwitchTab) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (tab === active) return;
    onClick(active === 'left' ? 'right' : 'left');
  }, [active, onClick]);

  return (
    <Root
      className={cx(className, classes.root)}
      onClick={handleClick(null)}
      style={style}>
      <Toggle
        active={active}
        className={classes.toggle} />
      <Options>
        <Left
          className={classes.left}
          onClick={handleClick('left')}
          active={active === 'left'}>
          {labelLeft}
        </Left>
        <Right
          className={classes.right}
          onClick={handleClick('right')}
          active={active === 'right'}>
          {labelRight}
        </Right>
      </Options>
    </Root>
  );
});

const Height = 30;
const TextCentering = 6;

const Root = styled.label`
  min-height: ${Height}px;
  background-color: ${props => props.theme.palette.gray.dark1};
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
`;

const Toggle = styled('div', { shouldForwardProp: shouldForwardProp('active') }) <ToggleActive>`
  position: absolute;
  height: ${Height}px;
  width: 50%;
  background-color: ${props => props.theme.palette.gray.main};
  box-shadow: 0 2px 15px rgba(0,0,0,.15);
  border-radius: 100px;
  transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: ${props => props.active === 'right' ? `translateX(100%)` : `none`};
  box-sizing: border-box;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.bold};
  position: absolute;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
`;

const Left = styled('div', { shouldForwardProp: shouldForwardProp('active') }) <OptionActive>`
  color: ${props => props.theme.palette.white.main};
  opacity: ${props => props.active ? 1 : 0.5};
  margin: ${TextCentering}px 0;
  width: 50%;
  text-align: center;
`;
const Right = styled('div', { shouldForwardProp: shouldForwardProp('active') }) <OptionActive>`
  color: ${props => props.theme.palette.white.main};
  opacity: ${props => props.active ? 1 : 0.5};
  margin: ${TextCentering}px 0;
  width: 50%;
  text-align: center;
`;

type ToggleActive = {
  active: SwitchTab;
};

type OptionActive = {
  active?: boolean;
};