import type {
  Workbook,
  WorkspaceFile,
  WorkspaceFilePreviewData,
  WorkspaceObject,
  WorkspaceFileSummaryData,
} from '@/types';
import type { FileCapabilities } from '@/types/workspace.access';
import { createNamedContext } from '@/utils';

type FileStateContextValue = {
  capabilities: FileCapabilities;
  file: WorkspaceFile;
  meta: {
    accessCount: number;
  };
  object: WorkspaceObject;
};

type FetchPreview = () => Promise<unknown>;

export type FileSummaryContextValue = {
  summary: WorkspaceFileSummaryData;
  isLoading: boolean;
  download: () => Promise<unknown>;
  refetch: () => Promise<unknown>;
  regenerate: () => Promise<unknown>;
  isRegenerating: boolean;
};

export const WorkspaceFilePreviewReloadContext = createNamedContext<FetchPreview>(undefined, 'Workspace.File.Preview.Reload.Context');
export const WorkspaceFilePreviewLoadingContext = createNamedContext<boolean>(false, 'Workspace.File.Preview.Loading.Context');
export const WorkspaceFilePreviewContext = createNamedContext<WorkspaceFilePreviewData>(undefined, 'Workspace.File.Preview.Data.Context');
export const WorkspaceFileSummaryContext = createNamedContext<FileSummaryContextValue>(undefined, 'Workspace.File.Summary.Context');

export const WorkspaceFileStateContext = createNamedContext<FileStateContextValue>(undefined, 'Workspace.File.State.Context');

export const WorkbookDataContext = createNamedContext<Workbook>(undefined, 'Workbook.Data.Context');
export const WorkbookDataLoadingContext = createNamedContext<boolean>(undefined, 'Workbook.Data.Loading.Context');
