/** @jsxImportSource @emotion/react */
import type { ReactNode, MouseEvent } from 'react';
import { forwardRef, Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import * as $api from '@/api';
import { MessageQueryHint, TextHintIcon, TextHintWrap } from '@/components/presentation';
import { StyledAnchor } from '@/components/presentation/Link';
import { Citation } from '@/components/presentation/Markdown/Citations';
import { BasicMarkdown } from '@/components/presentation/Markdown/Markdown';
import { InitiateQuerySpeechToText, TextToSpeechStateContext, StopTextToSpeechContext, VoiceModeContext } from '@/containers/TextToSpeechAudioPlayer/Context';
import { ChatCitationType, ChatHintType, ChatResponseType } from '@/enums';
import { chatDateFiltersMap } from '@/enums/chat.filters';
import { ReportFormat } from '@/enums/reports';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useHover } from '@/hooks/useHover';
import { useAppSelector } from '@/store';
import { useHasPredictiveScheduling } from '@/store/selectors';
import type { Chat } from '@/types';
import type { KolProfile } from '@/types/kol';
import { formatPlurality } from '@/utils';
import { possessive, pluralize } from '@/utils/format';
import { formatPhoneNumber } from '@/utils/phone';
import { MessageCitations } from '../Chat.Message.Citations';
import { getCitationsDisplayCount } from '../Chat.Message.Citations/utils';
import { ChatStateDispatchContext } from '../Chat.State/context';
import { ActiveChatSessionContext, CitationsContext, KolFollowupQuestionsContext, KolProfileContext, KolSearchIdContext, MessageCitationsToggleContext } from './context';
import { useDownloadKolProfile } from './hooks/useDownloadKolProfile';
import { ProfileAccordionContainer } from './KolSearch.Profile.Accordion';
import { AITooltip } from './KolSearch.Profile.AITooltip';
import { AvailabilityInsights } from './KolSearch.Profile.Availability';
import { CopyButton } from './Message.CopyToClipboard';
import { DownloadButton } from './Message.Download';
import { ReadAloudButton } from './Message.ReadAloud';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { SystemMessageContainer } from './Message.System.Container';
import { RelatedQuestionsPane } from './QueryHints';
import { ChatResponsePane } from './ResponsePane';

const ProfileItem = styled.div({
  marginBottom: 12,
});

type Props = {
  followupQuestions: string[];
  kolSearchId?: number;
  queryId?: number;
  profile: KolProfile;
};

const KolProfileMessage = ({ followupQuestions, kolSearchId, profile, queryId }: Props) => {
  const [profileRef, setProfileRef] = useState<HTMLDivElement>(null);
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);
  const citations = useContext(CitationsContext);
  const chat = useContext(ActiveChatSessionContext);

  const { download } = useDownloadKolProfile({
    kol: {
      id: profile.id,
      name: profile.name,
    },
  });

  const copyToClipboard = useCopyToClipboard();

  const copy = `Sure, here is the HCP profile for "${profile.name}"`;

  const renderProfileLink = useCallback(() => {
    if (!profile.profileLink) return null;

    return (
      <>
        (<StyledAnchor href={profile.profileLink} target="_blank" rel="noreferrer">
          Link
        </StyledAnchor>)
      </>
    );
  }, [profile.profileLink]);

  const handleCopy = useCallback(() => {
    if (queryId) {
      return $api.getQueryResponseText({
        chatInstanceId: chat.id,
        queryId,
        tts: false,
      }).then(copyToClipboard);
    } else if (kolSearchId) {
      return $api.getKolSearchProfileText({
        kolSearchId,
        tts: false,
      }).then(copyToClipboard);
    }
  }, [chat.id, copyToClipboard, kolSearchId, queryId]);

  const handleDownload = useCallback(() => {
    download({
      title: `Generating Export`,
      name: `${profile.name} Profile`,
      extension: ReportFormat.Word,
    });
  }, [download, profile.name]);

  const relatedQuestions = useMemo(() => followupQuestions.map((f) => ({
    displayValue: f,
    type: ChatHintType.Text,
  })), [followupQuestions]);

  useAutoPlayReadAloud({
    kolSearchId,
    hasContent: !!profileRef?.innerHTML,
    queryId,
  });

  return (
    <MessageContainer>
      <BasicSystemUserMessage>
        <MessageBody>
          <Copy>
            {copy} {renderProfileLink()}
          </Copy>

          <KolProfileDetails ref={setProfileRef} citations={citations} profile={profile} />

          <Footer>
            <DownloadButton onClick={handleDownload} />
            <CopyButton onClick={handleCopy} />
            <ReadAloudButton
              queryId={queryId}
              kolSearchId={kolSearchId} />
          </Footer>

          <StyledCitationsPane
            title={`Sources (${getCitationsDisplayCount(citations)})`}
            open={citationsOpen}
            toggleOpen={toggleCitationsOpen}
          >
            <StyledMessageCitations items={citations} />
          </StyledCitationsPane>

          <StyledRelatedQuestions
            hints={relatedQuestions}
          />
        </MessageBody>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

type Params = {
  queryId?: number | null;
  hasContent?: boolean;
  kolSearchId?: number | null;
};

const useAutoPlayReadAloud = ({ hasContent, kolSearchId, queryId }: Params) => {
  const [initialized, setInitialized] = useState(false);

  const initiateReadAloud = useContext(InitiateQuerySpeechToText);
  const stopTextToSpeech = useContext(StopTextToSpeechContext);
  const audioState = useContext(TextToSpeechStateContext);
  const voicemode = useContext(VoiceModeContext);

  const isPlaying =
    audioState &&
    audioState.isPlaying &&
    (audioState.queryId === queryId || audioState.kolSearchId === kolSearchId);

  const handleClick = useCallback(async () => {
    if (isPlaying) {
      stopTextToSpeech?.();
      return;
    }

    return initiateReadAloud({
      kolSearchId,
      queryId,
      isPlaying: true,
    });
  }, [isPlaying, stopTextToSpeech, initiateReadAloud, kolSearchId, queryId]);

  const autoPlayReadAloud = useCallback(() => {
    if (voicemode.enabled && voicemode.queryId === queryId && voicemode.type === ChatResponseType.HcpSummary) {
      if (audioState.isPlaying) {
        stopTextToSpeech?.();
      }
      handleClick();
      voicemode.dequeue();
      setInitialized(true);
    }
  }, [
    audioState,
    handleClick,
    queryId,
    stopTextToSpeech,
    voicemode,
    setInitialized,
  ]);

  useEffect(() => {
    if (hasContent && !initialized) {
      autoPlayReadAloud();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    hasContent,
    initialized,
  ]);
};

const KolProfileMessageContainer = () => {

  const citations = useContext(CitationsContext);
  const profile = useContext(KolProfileContext);
  const kolSearchId = useContext(KolSearchIdContext);
  const followupQuestions = useContext(KolFollowupQuestionsContext);

  return (
    <SystemMessageContainer citations={citations}>
      <KolProfileMessage
        followupQuestions={followupQuestions}
        profile={profile}
        kolSearchId={kolSearchId} />
    </SystemMessageContainer>
  );
};

export { KolProfileMessageContainer, KolProfileMessage };

type DetailsProps = {
  citations: Chat.Citation[];
  profile: KolProfile;
};

const KolProfileDetails = memo(forwardRef<HTMLDivElement, DetailsProps>(({ citations, profile }, ref) => {

  const [hoveringSection, setHoveringSection] = useState<string>(null);

  const handleMouseEnterSection = useCallback((label: string) => () => {
    setHoveringSection(label);
  }, []);

  const handleMouseLeaveSection = useCallback(() => {
    setHoveringSection(null);
  }, []);

  const renderTextField = useCallback(({ citations = [], label, value }: RenderTextProps) => {
    if (!value) return null;

    return (
      <ProfileItem>
        <Label>
          {label}
          {!!citations.length && citations.map(c => <Citation key={c} value={c} />)}
        </Label>
        {value}
      </ProfileItem>
    );
  }, []);

  const renderTextListField = useCallback(({ citations = [], label, items, learnMore, aiGenerated }: RenderTextListProps) => {

    if (!items.length) return null;

    const isHoveringOver = hoveringSection === label;

    return (
      <ProfileItem
        onMouseEnter={handleMouseEnterSection(label)}
        onMouseLeave={handleMouseLeaveSection}>
        <ProfileAccordionContainer
          profileKey={label}
          renderChildren={() => (
            <StyledList>
              {items.map((item, index) => (
                <TextListItem
                  key={index}
                  citations={item.citations}
                  value={item.value}
                  learnMore={item.learnMore} />
              ))}
            </StyledList>
          )}>
          <Label>
            {label}
            {!!citations.length && citations.map(c => <Citation key={c} value={c} />)}
            {learnMore && <LearnMore open={isHoveringOver} question={learnMore} />}
            {aiGenerated && <AITooltip helpText={aiGenerated} />}
          </Label>
        </ProfileAccordionContainer>
      </ProfileItem>
    );
  }, [hoveringSection, handleMouseEnterSection, handleMouseLeaveSection]);

  const renderLeadershipField = useCallback(() => {

    const label = 'Clinical & Research Activity';

    const congressAbstractionCutoffs = [50, 100, 150, 250, 300].reverse();

    const estAbstractions = congressAbstractionCutoffs.find(cutoff => profile.numCongressAbstracts >= cutoff);

    const clinicalTrialsCitation = citations.find(f => f.type === ChatCitationType.ClinicalTrial);
    const publicationCitation = citations.find(f => f.type === ChatCitationType.Publication);

    const items = [
      profile.numClinicalTrials && {
        value: `${profile.numClinicalTrials} ${formatPlurality('Clinical Trial', profile.numClinicalTrials)}`,
        learnMore: `I'd like to learn more about the clinical trials ${profile.name} participated in.`,
      },
      profile.numPublications && profile.numCongressAbstracts && {
        value: `${profile.numPublications} Publications plus ${estAbstractions ? `> ${estAbstractions}` : profile.numCongressAbstracts} congress abstracts`,
        learnMore: `I'd like to learn more about the publications ${profile.name} has appeared in.`,
      },
      profile.numPublications && !profile.numCongressAbstracts && {
        value: `${profile.numPublications} ${formatPlurality('Publication', profile.numPublications)}`,
        learnMore: `I'd like to learn more about the publications ${profile.name} has appeared in.`,
      },
      profile.numLeadAuthorPublications && {
        value: `${profile.numLeadAuthorPublications} ${formatPlurality('Lead Author Publication', profile.numLeadAuthorPublications)}`,
        learnMore: `I'd like to learn more about publications where ${profile.name} was the lead author.`,
      },
    ].filter(Boolean);

    return renderTextListField({
      citations: [publicationCitation?.ordinal?.toString(), clinicalTrialsCitation?.ordinal?.toString()].filter(Boolean),
      label,
      items,
    });
  }, [citations, profile, renderTextListField]);

  const renderInteractionsSummary = useCallback(() => {
    if (!profile.interactions) return null;

    const label = `Interactions Summary (${profile.interactions.allTime?.total} total)`;
    const displayName = 'HCP';

    let fbmsInteractions: number, contactCenterInteractions: number, medicalInsights: number, documentedInterests: number, withMe: number;
    let lookbackFbmsInteractions: number, lookbackContactCenterInteractions: number, lookbackMedicalInsights: number, lookbackDocumentedInterests: number, lookbackWithMe: number;
    let lookbackPeriod: string;

    if (profile.interactions.allTime) {
      fbmsInteractions = profile.interactions.allTime.numFbmsInteractions;
      contactCenterInteractions = profile.interactions.allTime.numContactCenterInteractions;
      medicalInsights = profile.interactions.allTime.numMedicalInsights;
      documentedInterests = profile.interactions.allTime.numDocumentedInterests;
      withMe = profile.interactions.allTime.withMe;

      lookbackFbmsInteractions = profile.interactions.lookback.numFbmsInteractions;
      lookbackContactCenterInteractions = profile.interactions.lookback.numContactCenterInteractions;
      lookbackMedicalInsights = profile.interactions.lookback.numMedicalInsights;
      lookbackDocumentedInterests = profile.interactions.lookback.numDocumentedInterests;
      lookbackWithMe = profile.interactions.lookback.withMe;

      lookbackPeriod = ` in the ${chatDateFiltersMap[profile.interactions.lookbackPeriod].toLowerCase()}`;
    } else {
      fbmsInteractions = profile.interactions.numFbmsInteractions;
      contactCenterInteractions = profile.interactions.numContactCenterInteractions;
      medicalInsights = profile.interactions.numMedicalInsights;
      documentedInterests = profile.interactions.numDocumentedInterests;
      withMe = profile.interactions.withMe;

      lookbackFbmsInteractions = 0;
      lookbackContactCenterInteractions = 0;
      lookbackMedicalInsights = 0;
      lookbackDocumentedInterests = 0;
      lookbackWithMe = 0;

      lookbackPeriod = '';
    }

    const hasLookbackFbmsInteractions = lookbackFbmsInteractions > 0;
    const hasLookbackContactCenterInteractions = lookbackContactCenterInteractions > 0;
    const hasLookbackMedicalInsights = lookbackMedicalInsights > 0;
    const hasLookbackDocumentedInterests = lookbackDocumentedInterests > 0;
    const hasLookbackWithMe = lookbackWithMe > 0;

    const hasAnyFbmsInteractions = fbmsInteractions > 0;
    const hasAnyContactCenterInteractions = contactCenterInteractions > 0;
    const hasAnyMedicalInsights = medicalInsights > 0;
    const hasAnyDocumentedInterests = documentedInterests > 0;
    const hasAnyWithMe = withMe > 0;

    const fbmsCitations = citations.filter(f => f.type === ChatCitationType.FbmsInteractions);
    const medicalInsightsCitations = citations.filter(f => f.type === ChatCitationType.MedicalInsights);
    const contactCenterCitations = citations.filter(f => f.type === ChatCitationType.CallCenterInteractions);
    const documentedInterestsCitations = citations.filter(f => f.type === ChatCitationType.DocumentedInterests);

    const items = [
      {
        citations: fbmsCitations.map(c => c.ordinal.toString()),
        value: hasLookbackFbmsInteractions
          ? `${lookbackFbmsInteractions} ${formatPlurality('interaction', lookbackFbmsInteractions)} with MSLs${lookbackPeriod}. ${buildTotalsText(fbmsInteractions)}`
          : `No MSL interactions${lookbackPeriod}. ${buildTotalsText(fbmsInteractions)}`,
        learnMore: hasAnyFbmsInteractions && `I would like to learn more about the interactions ${profile.name} has had with MSLs.`,
      },
      {
        citations: contactCenterCitations.map(c => c.ordinal.toString()),
        value: hasLookbackContactCenterInteractions
          ? `${lookbackContactCenterInteractions} contact center ${formatPlurality('interaction', lookbackContactCenterInteractions)}${lookbackPeriod}. ${buildTotalsText(contactCenterInteractions)}`
          : `No contact center interactions${lookbackPeriod}. ${buildTotalsText(contactCenterInteractions)}`,
        learnMore: hasAnyContactCenterInteractions && `I would like to learn more about the interactions ${profile.name} has had with the contact center.`,
      },
      {
        citations: medicalInsightsCitations.map(c => c.ordinal.toString()),
        value: hasLookbackMedicalInsights
          ? `${lookbackMedicalInsights} ${formatPlurality('medical insight', lookbackMedicalInsights)} expressed${lookbackPeriod}. ${buildTotalsText(medicalInsights)}`
          : `No medical insights expressed${lookbackPeriod}. ${buildTotalsText(medicalInsights)}`,
        learnMore: hasAnyMedicalInsights && `I would like to learn more about the medical insights ${profile.name} has expressed.`,
      },
      {
        citations: documentedInterestsCitations.map(c => c.ordinal.toString()),
        value: hasLookbackDocumentedInterests
          ? `${lookbackDocumentedInterests} ${formatPlurality('documented interest', lookbackDocumentedInterests)}${lookbackPeriod}. ${buildTotalsText(documentedInterests)}`
          : `No documented interests ${lookbackPeriod}. ${buildTotalsText(documentedInterests)}`,
        learnMore: hasAnyDocumentedInterests && `I would like to learn more about the documented interests ${profile.name} has expressed.`,
      },
      {
        value: hasLookbackWithMe
          ? `You have interacted with ${displayName} ${lookbackWithMe} ${pluralize('time', lookbackWithMe)}${lookbackPeriod}. ${buildTotalsText(withMe)}`
          : `You have not interacted with ${displayName}${lookbackPeriod}. ${buildTotalsText(withMe)}`,
        learnMore: hasAnyWithMe && `I would like to learn more about any recent interactions I've had with ${profile.name}.`,
      },
      // {
      //   value: profile.interactions.total
      //     ? `${displayName} has a total of ${profile.interactions.total} ${pluralize('interaction', profile.interactions.total)} across all channels.`
      //     : `${displayName} has no recorded interactions.`,
      //   learnMore: !!profile.interactions.total && `I would like to learn more about any recorded interactions with ${profile.name}.`,
      // },
    ];

    return renderTextListField({ label, items });

    function buildTotalsText(value: number) {
      return value ? `(${value} total)` : '';
    }
  }, [citations, profile.interactions, profile.name, renderTextListField]);

  const renderTopics = useCallback(() => {

    if (!profile.numScientificFocusAreas && !profile.numClinicalFocusAreas && !profile.numDigitalFocusAreas) return null;

    const label = 'Leader Topics';

    const items = [
      profile.numScientificFocusAreas && {
        value: `${profile.numScientificFocusAreas} Scientific Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} scientific leader topics.`,
      },
      profile.numClinicalFocusAreas && {
        value: `${profile.numClinicalFocusAreas} Clinical Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} clinical leader topics.`,
      },
      profile.numDigitalFocusAreas && {
        value: `${profile.numDigitalFocusAreas} Digital Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} digital leader topics.`,
      },
    ].filter(Boolean);

    return renderTextListField({ label, items });
  }, [profile.numScientificFocusAreas, profile.numClinicalFocusAreas, profile.numDigitalFocusAreas, profile.name, renderTextListField]);

  const displayName = useMemo(() => {
    if (!profile.qualifications.length) return profile.name;

    const sortedQualifications = profile.qualifications.sort((a, b) => a.localeCompare(b));
    return `${profile.name}, ${sortedQualifications.join(', ')}`;
  }, [profile.name, profile.qualifications]);

  const renderConferencePresence = useCallback(() => {
    const label = 'Conference Presence';
    const learnMore = `I'd like to learn more about ${possessive(profile.name)} conference presence.`;

    const conferenceCitation = citations.find(f => f.type === ChatCitationType.Conference);

    if (profile.upcomingEvents?.length) {
      return renderTextListField({
        citations: [conferenceCitation?.ordinal?.toString()].filter(Boolean),
        label,
        items: profile.upcomingEvents.map(e => ({ value: e.name })),
        learnMore,
      });
    }

    if (profile.events?.length) {
      return renderTextListField({
        citations: [conferenceCitation?.ordinal?.toString()].filter(Boolean),
        label,
        items: profile.events.map(e => {
          const roles = e.roles.map(r => r.position).map((i, v, a) => a.indexOf(i) === v ? i : null).filter(Boolean).join(', ');
          return {
            value: `${e.name} (${roles})`,
          };
        }),
        learnMore,
      });
    }

    return null;
  }, [
    citations,
    profile.events,
    profile.name,
    profile.upcomingEvents,
    renderTextListField,
  ]);

  const renderGuidelines = useCallback(() => {
    const copy = `Metrics are based on available data sources, which may not fully represent HCP activity. Please leverage this information directionally to supplement your local market knowledge.`;

    return <Guidelines>{copy}</Guidelines>;
  }, []);

  const renderRecommendationReason = useCallback(() => {
    if (!profile.recommendations?.length) return null;

    const label = `Recommended because patients have the following unmet medical needs:`;

    return (
      <ProfileItem>
        <Label>{label}</Label>
        <Recommendations>
          <StyledList>
            {profile.recommendations.map((r, i) => (
              <Fragment key={i}>
                <StyledListItem><strong>{r.reason}</strong></StyledListItem>
                <StyledList>
                  {r.items.map((item, index) => (
                    <StyledListItem key={index}>
                      <BasicMarkdown>{item}</BasicMarkdown>
                    </StyledListItem>
                  ))}
                </StyledList>
              </Fragment>
            ))}
          </StyledList>
        </Recommendations>
      </ProfileItem>
    );
  }, [profile.recommendations]);

  const renderContactDetails = useCallback(() => {
    if (!profile.contact?.email && !profile.contact?.phone && !profile.contact?.address) return null;

    const citation = citations.find(f => f.type === ChatCitationType.ContactDetail);

    const label = 'Contact Information';

    const items = [
      profile.contact.email && {
        value: <><BulletedLabel>Email</BulletedLabel>: {profile.contact.email}</>,
      },
      profile.contact.phone && {
        value: <><BulletedLabel>Phone</BulletedLabel>: {formatPhoneNumber(profile.contact.phone)}</>,
      },
      profile.contact.address && {
        value: <><BulletedLabel>Address</BulletedLabel>: {profile.contact.address}</>,
      },
    ].filter(Boolean);

    return renderTextListField({
      citations: [citation?.ordinal?.toString()].filter(Boolean),
      label,
      items,
    });
  }, [citations, profile.contact, renderTextListField]);

  const renderPrimaryAffiliation = useCallback(() => {
    const citation = citations.find(f => f.type === ChatCitationType.Affiliation);

    return renderTextField({
      citations: [citation?.ordinal?.toString()].filter(Boolean),
      label: 'Primary Affiliation',
      value: profile.affiliations.map(m => m.name).join('\n'),
    });
  }, [citations, profile.affiliations, renderTextField]);

  const groupFeatures = useAppSelector(state => state.group.features);
  const dispatch = useContext(ChatStateDispatchContext);

  const renderQuickActions = useCallback(() => {
    const actions: {
      displayValue: string;
      value: string;
    }[] = [];
    if (groupFeatures.hcpSchedulingAssistant) {
      actions.push({
        displayValue: 'Schedule a site visit',
        value: 'I would like to schedule a site vist',
      });
    }
    if (groupFeatures.smartRouting) {
      actions.push({
        displayValue: 'Find nearby HCPs',
        value: 'I would like to find nearby HCPs',
      });
    }
    if (!actions.length) return null;

    return (
      <ProfileItem>
        <ProfileAccordionContainer
          profileKey="quick-actions"
          renderChildren={() => (
            <QuickActionsList>
              {actions.map((action, i) => (
                <MessageQueryHint
                  key={`${i}`}
                  hint={{
                    type: ChatHintType.Text,
                    displayValue: action.displayValue,
                  }}
                  onClick={() => {
                    dispatch({
                      type: 'input-changed',
                      payload: { value: action.value },
                    });
                  }} />
              ))}
            </QuickActionsList>
          )}>
          <Label>Quick Actions</Label>
        </ProfileAccordionContainer>
      </ProfileItem>
    );
  }, [dispatch, groupFeatures.hcpSchedulingAssistant, groupFeatures.smartRouting]);

  const hasPredictiveScheduling = useHasPredictiveScheduling();

  return (
    <div ref={ref}>
      {renderTextField({
        label: 'Name',
        value: displayName,
      })}
      {renderTextField({
        label: 'Designation',
        value: profile.title,
      })}
      {renderPrimaryAffiliation()}
      {renderRecommendationReason()}
      {renderContactDetails()}
      {hasPredictiveScheduling &&
        <AvailabilityInsights
          citations={citations}
          profile={profile} />}
      {renderInteractionsSummary()}
      {renderTopics()}
      {renderLeadershipField()}

      {renderTextListField({
        label: 'Key Insights',
        items: profile.keyInsights.map(m => ({ value: <BasicMarkdown>{m}</BasicMarkdown> })),
        aiGenerated: `
Key insights are AI generated based on the following information:

* HCP's profile and activity
* HCP's interactions with MSLs
* HCP patients' unmet needs
* Strategic imperatives
        `,
      })}
      {renderConferencePresence()}
      {renderTextListField({
        label: 'Key Discussion Questions',
        items: profile.keyDiscussionQuestions.map(m => ({ value: <BasicMarkdown>{m}</BasicMarkdown> })),
        aiGenerated: `
Key discussion questions are generated using  based on the following information:

* HCP's profile and activity
* HCP's interactions with MSLs
* HCP patients' unmet needs
* Strategic imperatives
        `,
      })}
      {renderQuickActions()}
      {renderGuidelines()}
    </div>
  );
}));

type RenderTextListProps = {
  citations?: string[];
  label: string;
  items: ListItemProps[];
  learnMore?: string;
  aiGenerated?: string | null;
};

type RenderTextProps = {
  citations?: string[];
  label: string;
  value: ReactNode;
};

type LearnMoreProps = {
  open: boolean;
  question: string;
};

const LearnMore = memo(({ open, question }: LearnMoreProps) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const textRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: 'input-changed',
      payload: { value: question },
    });
  }, [dispatch, question]);

  useEffect(() => {
    const textEl = textRef.current;

    const handleTransitionEnd = () => {
      textEl.style.opacity = '1';
    };

    const handleTransitionStart = () => {
      textRef.current.style.opacity = '0';
    };

    if (textEl) {
      textEl.addEventListener('transitionstart', handleTransitionStart);
      textEl.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (textEl) {
        textEl.removeEventListener('transitionstart', handleTransitionStart);
        textEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, []);

  return (
    <LearnMoreRoot onClick={handleClick}>
      <StyledLearnMoreWrap>
        <StyledTextHintIcon /> <LearnMoreText open={open} ref={textRef}>{open ? 'Learn More' : ''}</LearnMoreText>
      </StyledLearnMoreWrap>
    </LearnMoreRoot>
  );
});

type ListItemProps = {
  citations?: string[];
  learnMore?: string;
  value: React.ReactNode;
};

const TextListItem = memo(({ citations = [], learnMore, value }: ListItemProps) => {

  const [ref, isHovering] = useHover<HTMLLIElement>(!!learnMore);

  return (
    <StyledListItem ref={ref}>
      {value}
      {!!citations.length && citations.map(c => <Citation key={c} value={c} />)}
      {learnMore && <LearnMore open={isHovering} question={learnMore} />}
    </StyledListItem>
  );
});

const BulletedLabel = styled.span(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  color: theme.palette.black.light1,
}));

const Guidelines = styled.em({
  marginTop: 15,
});

const StyledRelatedQuestions = styled(RelatedQuestionsPane)({
  marginTop: 15,
});

const MessageBody = styled.div({
  maxWidth: 600,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

const Copy = styled.div({
  marginBottom: 15,
});

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

const StyledList = styled.ul({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 30,
});

const QuickActionsList = styled.div({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 30,
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 5,

  '& *': {
    justifyContent: 'flex-start',
  },
});

const StyledListItem = styled.li({
  marginBottom: 5,
});

const Footer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  gap: 10,
});

const LearnMoreRoot = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: 5,
  width: 95,
});

const StyledLearnMoreWrap = styled(TextHintWrap)({
  fontSize: 13,
  padding: `0 1px 0 3px`,
  alignItems: 'center',
  height: 22,
  borderRadius: 5,
});

const LearnMoreText = styled.span<{ open: boolean }>(({ open }) => ({
  marginLeft: 3,
  opacity: 0,
  width: open ? 70 : 0,
  transition: 'width .1s ease-in-out',
  overflowX: 'hidden',
}));

const StyledTextHintIcon = styled(TextHintIcon)({
  marginRight: 0,
});

const StyledCitationsPane = styled(ChatResponsePane)({
  marginTop: 15,
});

const StyledMessageCitations = styled(MessageCitations)({
  marginTop: 10,
});

const Recommendations = styled.div({
  marginTop: 10,
});