import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Radio } from '@mui/material';
import { SearchQueryHint } from '@/components/presentation';
import { SelectLocation } from '@/components/Search/Location';
import type { Chat } from '@/types';
import { ChatActionType } from '@/types/chat.actions';
import type { LocationItem } from '@/types/location';
import { useBrowserLocation } from '@/utils/hooks/useBrowserLocation';
import { BrowserLocationError } from './BrowserLocationError';
import { SubmitActionContext } from './context';

type Props = {
  queryId: number;
  queryIdentifier: string;
  value: Chat.QueryState.LocationSelection;
  disabled: boolean;
};

export const LocationForm = (props: Props) => {
  const submitAction = useContext(SubmitActionContext);
  const [radioState, setRadioState] = useState<'browser' | 'search' | null>(props.value?.location?.type);
  const [selectorState, setSelectorState] = useState<LocationItem | null>(props.value?.location?.type === 'search' ? props.value.location : null);
  const [browserLocation, setBrowserLocation] = useState<ICoords | null>(props.value?.location?.type === 'browser' ? props.value.location : null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const { isSupported, getLocation, state: browserLocationState } = useBrowserLocation({ onSuccess: setBrowserLocation, onError: setLocationError });

  useEffect(() => {
    if (radioState === 'browser' && !browserLocation) {
      getLocation();
    }
  }, [browserLocation, getLocation, radioState]);

  const onSubmit = useCallback(() => {
    if (radioState === 'browser' && browserLocation) {
      submitAction({
        type: ChatActionType.SelectLocation,
        payload: {
          location: {
            type: 'browser',
            latitude: browserLocation.latitude,
            longitude: browserLocation.longitude,
          },
          queryId: props.queryId,
        },
      }, 'Use current location');
    } else if (radioState === 'search' && selectorState) {
      submitAction({
        type: ChatActionType.SelectLocation,
        payload: {
          location: {
            type: 'search',
            name: selectorState.name,
            latitude: selectorState.latitude,
            longitude: selectorState.longitude,
            id: selectorState.id,
          },
          queryId: props.queryId,
        },
      }, `Use the following location: ${selectorState.name}`);
    }
  }, [browserLocation, props.queryId, radioState, selectorState, submitAction]);

  const canSubmit = useMemo(() => {
    if (props.disabled) return false;
    if (radioState === 'browser' && browserLocation) return true;

    if (radioState === 'search' && selectorState) return true;

    return false;
  }, [browserLocation, props.disabled, radioState, selectorState]);

  return (
    <div>
      <div>Welcome to the Smart Routing Assistant! I can help you plan your HCP site visits efficiently. Would you like to start by enter</div>
      <div>First let's plan our where you'll be starting from.</div>
      <div>
        <RadioRow>
          <Radio size='small' disabled={!isSupported || props.disabled} checked={radioState === 'browser'} onClick={() => setRadioState('browser')} />
          <div>Use my current location</div>
          {browserLocationState === 'loading' && <div>Waiting for permission...</div>}
          {browserLocationState === 'success' && <div>{browserLocation.longitude}, {browserLocation.latitude}</div>}
          {browserLocationState === 'error' && <BrowserLocationError errorMessage={locationError} />}
        </RadioRow>
        <RadioRow>
          <Radio size='small' checked={radioState === 'search'} disabled={props.disabled} onClick={() => setRadioState('search')} />
          <div>I'll be near this location</div>
          <SelectLocation
            styles={{
              container: (provided) => ({
                ...provided,
                width: 350,
              }),
            }}
            placeholder="Start typing an address"
            isDisabled={radioState !== 'search' || props.disabled}
            value={selectorState}
            onChange={setSelectorState} />
        </RadioRow>
      </div>
      <SearchQueryHint disabled={!canSubmit} onClick={onSubmit} value="Search HCPs" />
    </div>
  );
};

const RadioRow = styled.div(() => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
}));