export enum LocationProviderType {
  AWS = 'aws',
  Azure = 'azure',
};

export type GeoPoint = {
  latitude: number;
  longitude: number;
};

export type ScoredGeoPoint = {
  score: number;
} & GeoPoint;

export type AutocompleteParams = {
  query: string;
  biasPoint?: GeoPoint;
};

export type AutocompleteResult = {
  id: string;
  name: string;
} & GeoPoint;

export type IdentifiedPoint = {
  id: string;
} & GeoPoint;

export type IdentifiedDistance = {
  id: string;
  distance: number;
};

export type BuildRouteParams = {
  origin: IdentifiedPoint;
  destination?: IdentifiedPoint;
  waypoints: IdentifiedPoint[];
};

export type RouteLeg<T extends LocationProviderType = LocationProviderType> = {
  start: IdentifiedPoint & { address: string | null };
  end: IdentifiedPoint & { address: string | null };
  distanceMiles: number;
  durationSeconds: number;
  meta: LegMeta<T>;
};

export type BuildRouteResult<T extends LocationProviderType = LocationProviderType> = {
  legs: RouteLeg<T>[];
};

type AWSLegMeta = {
  steps: unknown[];
};

type LegMetaMap = {
  [LocationProviderType.AWS]: AWSLegMeta;
  [LocationProviderType.Azure]: { points: GeoPoint[] };
};

type LegMeta<T extends LocationProviderType> = LegMetaMap[T];

export type LocationItem = {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
};