import type { MouseEvent } from 'react';
import { memo, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { ClickCitationContext } from '@/components/Chat/context';
import { useHover } from '@/hooks/useHover';
import { StyledSup } from './MarkdownElements';


type CitationProps = {
  value: string;
};

export const Citation = memo(({ value }: CitationProps) => {
  const onCitationClick = useContext(ClickCitationContext);
  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onCitationClick(value);
  }, [onCitationClick, value]);

  return (
    <StyledSup onClick={onClick}>
      {value}
    </StyledSup>
  );
});

type CitationsProps = {
  values: string[];
};

export const Citations = memo(({ values }: CitationsProps) => {

  const [ref, hovering] = useHover();

  return (
    <CitationsRoot ref={ref}>
      {hovering && (
        <div>
          {values.map((value, index) => (
            <Citation key={index} value={value} />
          ))}
        </div>
      )}
      {!hovering && (
        <div>
          <StyledSup>
            {values.length} Citations
          </StyledSup>
        </div>
      )}
    </CitationsRoot>
  );
});

const CitationsRoot = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
});