import { useEffect, useMemo } from 'react';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as api from '@/api';
import { useRegisterChatPageContext } from '@/components/Chat';
import { path } from '@/consts';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { ChatContextType } from '@/enums/chat';
import type { BrandInsightsPageContext } from '@/types';
import { GroupTagsContext } from '../GroupTags/context';
import {
  WorkspaceFileStateContext,
  WorkspaceFilePreviewContext,
  WorkspaceFilePreviewReloadContext,
  WorkspaceFilePreviewLoadingContext,
} from './Context';
import SummaryContainer from './SummaryContainer';

type Props =
  ChildrenProps;

type RouteParams = {
  fileId: string;
};

const usePageParams = () => {
  const params = useParams<RouteParams>();
  return useMemo(() => ({
    fileId: +params.fileId,
  }), [params.fileId]);
};

export const WorkspaceFilePreviewContainer = (props: Props) => {
  const params = usePageParams();
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: [
      `get:workspaces/files`,
      params.fileId,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFile({
        fileId: params.fileId,
      });
    },
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        navigate(path.Workspaces.FileNotFound, { replace: true });
      }
    },
  });

  const documentContext: BrandInsightsPageContext.Document = useMemo(() => {
    return {
      type: ChatContextType.Document,
      data: {
        documentId: params.fileId,
      },
      metadata: {
        name: query.data?.file?.name,
      },
    };
  }, [query.data?.file?.name, params.fileId]);

  const register = useRegisterChatPageContext();

  useEffect(() => {
    if (!documentContext) return;

    if (documentContext.data.documentId && documentContext.metadata.name) {
      register(documentContext);
    }
  }, [register, documentContext]);

  if (query.isFetched && !query.data?.file) {
    const e = query.error;
    const objectData = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = query.data !== null && typeof query.data === 'object' && !!objectData?.objectId;

    return forbidden && hasObjectId
      ? <Navigate to={generatePath(path.Access.Request, query.data)} />
      : <Navigate to={path.Workspaces.FileNotFound} />;
  }

  const state = useMemo(() => ({
    capabilities: query.data?.capabilities,
    file: query.data?.file,
    meta: query.data?.meta,
    object: query.data?.object,
  }), [query.data]);

  return (
    <WorkspaceFilePreviewReloadContext.Provider value={query.refetch}>
      <WorkspaceFileStateContext.Provider value={state}>
        <WorkspaceFilePreviewContext.Provider value={query.data?.preview}>
          <WorkspaceFilePreviewLoadingContext.Provider value={query.isInitialLoading}>
            <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs || []}>
              <GroupTagsContext.Provider value={query.data?.tags || []}>
                <SummaryContainer fileId={params.fileId}>
                  {props.children}
                </SummaryContainer>
              </GroupTagsContext.Provider>
            </WorkspaceBreadcrumbsContext.Provider>
          </WorkspaceFilePreviewLoadingContext.Provider>
        </WorkspaceFilePreviewContext.Provider>
      </WorkspaceFileStateContext.Provider>
    </WorkspaceFilePreviewReloadContext.Provider>
  );
};