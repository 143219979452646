import { qs } from '@/utils';
import { getAuthenticatedClient } from './client';
import type * as SearchApi from './interfaces/search';

export const searchKols = async (params: SearchApi.SearchKols.Request) => {
  return getAuthenticatedClient().get<SearchApi.SearchKols.Response>(`/search/kol?name=${params.name}`).then(r => r.data);
};

export const searchLocation = async (params: SearchApi.SearchLocation.Request) => {
  return getAuthenticatedClient().get<SearchApi.SearchLocation.Response>(`/search/location?${qs.stringify(params)}`).then(r => r.data);
};