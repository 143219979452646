import { useState } from 'react';
import styled from '@emotion/styled';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import { AlertCircle } from 'react-feather';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';

export const PatientVolumeTooltip = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  const title = `Patient Volume`;
  const copy = `The number of eligible patients within the specified population who meet the criteria for this unmet medical need. This value reflects the count of patients seen by the HCP at their primary affiliation within the last 3 years.`;

  return (
    <>
      <Anchor ref={refs.setReference} {...getReferenceProps()}>
        <Icon />
      </Anchor>
      {isOpen && (
        <PopperMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <Title>{title}</Title>
          {copy}
        </PopperMenu>
      )}
    </>
  );
};

const Anchor = styled.div({
  display: 'inline-flex',
  verticalAlign: 'middle',
});

const Icon = styled(AlertCircle)(({ theme }) => {
  const { TextIcon } = Presentation.Colors(theme);
  return {
    display: 'inline-flex',
    color: TextIcon.enabled.color,
  };
});

const PopperMenu = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 15,
  borderRadius: 4,
  boxShadow: `0 2px 6px 2px rgba(60, 64, 67, .15)`,
  zIndex: 4,
  width: 400,
  fontSize: 15,

  textAlign: 'left',
}));

const Title = styled.div(({ theme }) => ({
  textDecoration: 'underline',
  fontFamily: theme.fonts.semiBold,
  marginBottom: 10,
  fontSize: 16,
}));