import { useCallback, useEffect, useRef } from 'react';

type TimerFn = () => void;

type UseTimeout = (fn: () => unknown, ms: number) => [
  () => boolean | null,
  TimerFn,
  TimerFn,
];

const useTimeout: UseTimeout = (fn, ms) => {
  const ready = useRef<boolean | null>(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const isReady = useCallback(() => {
    return ready.current;
  }, []);

  const setTimer = useCallback(() => {
    ready.current = false;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      ready.current = true;

      callback.current();
    }, ms);
  }, [ms]);

  const clearTimer = useCallback(() => {
    ready.current = null;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    setTimer();

    return clearTimer;
  }, [
    ms,
    clearTimer,
    setTimer,
  ]);

  return [isReady, setTimer, clearTimer];
};

export { useTimeout };
export default useTimeout;