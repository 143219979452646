import { useCallback, useMemo, useState } from 'react';
import { useSelectedArray } from '@/utils/hooks';
import {
  KolSearchResultsSelectionContext,
  KolSearchResultsFilterContext,
  KolSearchResultsSortContext,
  KolSearchResultsPageSizeContext,
  KolSearchResultsPageIndexContext,
  KolSearchResultsSetPageIndexContext,
  KolSearchResultsSetPageSizeContext,
} from './context';
import { useTableFilterState } from './hooks';
import type { KolSearchResultSortKey } from './interfaces.types';

type Props = {
  pageSize?: number;
} & ChildrenProps;

export const KolTableContainer = ({ children, pageSize }: Props) => {
  const kolSelection = useSelectedArray<number>();
  const [filterState, dispatchFilterState] = useTableFilterState({
    pageSize,
  });
  const [filtersOpen, setFiltersOpen] = useState(false);

  const filterContext = useMemo(() => ({
    open: filtersOpen,
    setOpen: setFiltersOpen,
    state: filterState.filters,
    dispatch: dispatchFilterState,
  }), [filtersOpen, setFiltersOpen, filterState, dispatchFilterState]);

  const sortContext = useMemo(() => ({
    sortBy: filterState.sort.by,
    sortDir: filterState.sort.direction,
    setSort: (key: KolSearchResultSortKey, dir: 'asc' | 'desc') => {
      dispatchFilterState({
        type: 'sort-set',
        payload: { by: key, direction: dir },
      });
    },
    toggleSortKey: (key: KolSearchResultSortKey) => {
      dispatchFilterState({
        type: 'sort-key-toggled',
        payload: key,
      });
    },
  }), [filterState.sort, dispatchFilterState]);

  const setPageSize = useCallback((pageSize: number) => {
    dispatchFilterState({
      type: 'page-size-set',
      pageSize,
    });
  }, [dispatchFilterState]);
  const setPageIndex = useCallback((pageIndex: number) => {
    dispatchFilterState({
      type: 'page-index-set',
      pageIndex,
    });
  }, [dispatchFilterState]);

  return (
    <KolSearchResultsFilterContext.Provider value={filterContext}>
      <KolSearchResultsSortContext.Provider value={sortContext}>
        <KolSearchResultsSelectionContext.Provider value={kolSelection}>
          <KolSearchResultsPageSizeContext.Provider value={filterState.pageSize}>
            <KolSearchResultsPageIndexContext.Provider value={filterState.pageIndex}>
              <KolSearchResultsSetPageIndexContext.Provider value={setPageIndex}>
                <KolSearchResultsSetPageSizeContext.Provider value={setPageSize}>
                  {children}
                </KolSearchResultsSetPageSizeContext.Provider>
              </KolSearchResultsSetPageIndexContext.Provider>
            </KolSearchResultsPageIndexContext.Provider>
          </KolSearchResultsPageSizeContext.Provider>
        </KolSearchResultsSelectionContext.Provider>
      </KolSearchResultsSortContext.Provider>
    </KolSearchResultsFilterContext.Provider >
  );
};