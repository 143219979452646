import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
// import AutoComplete from '@/components/AutoComplete';
import { useGroupUsersContext } from '@/containers/ObjectAccess/hooks';
import type { GroupUser } from '@/types/group';

type Props = {
  className?: string;
  onSelect: (item: GroupUser) => unknown;
};

function getDisplayText(data: GroupUser) {
  return !data.firstName || !data.lastName
    ? data.email
    : [data.firstName, data.lastName].join(' ');
}

export const GroupUsersAutocomplete = ({ className, onSelect }: Props) => {
  const { fetch, items } = useGroupUsersContext();

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    fetch(e.target.value);
  }, [fetch]);

  return (
    <div className={className}>
      {/* <AutoComplete
        getItemValue={getDisplayText}
        items={items}
        name="group-users"
        onChange={onChange}
        onSelect={onSelect}
        placeholder="Enter name" /> */}
    </div>
  );

};

export default GroupUsersAutocomplete;