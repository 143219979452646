import { memo, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import { getDay, isWeekend } from 'date-fns';
import { ClickCitationContext, MessageCitationsToggleContext } from '@/components/Chat/context';
import { ChatResponsePane } from '@/components/Chat/ResponsePane';
import { MessageCitations } from '@/components/Chat.Message.Citations';
import { StyledSup } from '@/components/presentation/Markdown/MarkdownElements';
import { colors } from '@/components/Theme/theme';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { ChatCitationType } from '@/enums/chat';
import type { Chat } from '@/types';
import type { KolProfile } from '@/types/kol';
import { ProfileAccordionContainer } from './KolSearch.Profile.Accordion';
import { AITooltip } from './KolSearch.Profile.AITooltip';

const Day = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

type Props = {
  citations: Chat.Citation[];
  profile: KolProfile;
};

export const AvailabilityInsights = memo((props: Props) => {
  const openCitation = useContext(ClickCitationContext);
  const interactions = props.citations.find(x => x.id === String(ChatCitationType.FbmsInteractions));

  if (!interactions?.snippets?.length) return null;

  return (
    <ProfileItem>
      <ProfileAccordionContainer
        profileKey={`HCP Availability Insights`}
        renderChildren={() => (
          <AvailabilityRoot>
            <AvailabilityInsightsTable
              citations={props.citations}
              profile={props.profile} />
          </AvailabilityRoot>
        )}>

        <Label>
          {`HCP Availability Insights`}
          {!!interactions.snippets.length &&
            <StyledSup onClick={() => openCitation(interactions.ordinal.toString())}>{`${interactions.snippets.length} citations`}</StyledSup>}
          <InfoTooltip />
        </Label>

      </ProfileAccordionContainer>
    </ProfileItem>
  );
});

export const AvailabilityInsightsMessage = memo((props: Props) => {
  const interactions = props.citations.find(x => x.id === String(ChatCitationType.FbmsInteractions));
  const count = interactions?.snippets?.length || 0;
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);

  return (
    <>
      <AvailabilityParagraph>{`Sure! I can assist you with scheduling a time with ${props.profile.name}.`}</AvailabilityParagraph>
      <Label>
        {`HCP Availability Insights`}
        {!!count &&
          <StyledSup onClick={toggleCitationsOpen}>{`${count} citations`}</StyledSup>}
        <InfoTooltip />
      </Label>
      <div>
        <AvailabilityInsightsTable
          citations={props.citations}
          profile={props.profile} />
        <StyledCitationsPane
          title={`Sources (${count})`}
          open={citationsOpen}
          toggleOpen={toggleCitationsOpen}>
          <StyledMessageCitations items={[interactions]} />
        </StyledCitationsPane>
      </div>
    </>
  );
});

export const AvailabilityInsightsTable = memo((props: Props) => {
  const color = useColors();
  const interactions = props.citations.find(x => x.id === String(ChatCitationType.FbmsInteractions)) as Chat.Citation<ChatCitationType.FbmsInteractions>;

  const occurrences = (interactions?.snippets || []).reduce((acc, x) => {
    const date = new Date(x.metadata.createdOn);

    if (isWeekend(date)) return acc;

    const index = getDay(date);

    acc[index - 1] += 1;

    return acc;

  }, [0, 0, 0, 0, 0]);

  const sum = occurrences.reduce((acc, x) => acc + x, 0);
  const frequencies = sum > 0 ? occurrences.map(item => item * 100 / sum) : occurrences;
  const rankings = getRankedArray(frequencies);

  function getRankColor(i: number) {
    const item = rankings.find(x => x.index === i);

    if (item.rank === Rank.Zero) return color.Lighter;

    return item.rank === Rank.High ? color.Dark : item.rank === Rank.Medium ? color.Medium : color.Light;
  }
  const items = frequencies.map((x, i) => ({
    frequency: x,
    label: Day[i],
    color: getRankColor(i),
  }));

  return (
    <>
      <AvailabilityParagraph>{`These predictions are based on ${interactions?.snippets?.length || 0} interactions.`}</AvailabilityParagraph>
      <AvailabilityTextNotes>
        <AvailabilityTextNote>{`Availability data is based on past interactions and historical engagement patterns. These insights serve as a guide and may not be precise.`}</AvailabilityTextNote>
      </AvailabilityTextNotes>
      <WeekdayRow>
        <WeekdayRowTitle>Day</WeekdayRowTitle>
        <WeekdayCells>
          {items.map(x =>
            <AvailabilityWeekday
              frequency={x.frequency}
              key={x.label}
              label={x.label}
              color={x.color} />)}
        </WeekdayCells>
      </WeekdayRow>
    </>
  );
});

const InfoTooltip = memo(() => {
  const aiGenerated = `
**Physical Access Guide**

This section provides insights on the best days and time slots for meeting the HCP based on past interaction data.

* **% Values:** How often meetings happened on each day and time slot.
* **Color Guide:** Darker = more past engagements.
* **Plan Smart:** Pick the highest % values for better scheduling.
`;

  return (
    <AITooltip helpText={aiGenerated}>
      {`i`}
    </AITooltip>
  );
});

function getRankedArray(values: number[]) {
  const arr = values.map((x, i) => ({ value: x, index: i }));
  const max = Math.max(...values);
  const min = Math.min(...values.filter(x => x > 0));
  const ranked = arr.map(x => {
    const rank = x.value === max
      ? Rank.High
      : x.value > min
        ? Rank.Medium
        : x.value === min
          ? Rank.Low
          : Rank.Zero;
    return {
      value: x.value,
      rank,
      index: x.index,
    };
  });

  return ranked;
}

type AvailabilityWeekdayProps = {
  frequency?: number;
  label: string;
  color?: string;
};

const AvailabilityWeekday = ({ frequency = 0, color, label }: AvailabilityWeekdayProps) => {
  return (
    <WeekdayCell style={{ backgroundColor: color }}>
      <WeekdayLabel>{label}</WeekdayLabel>
      <WeekdayTextSub>{`(${Math.trunc(frequency)} %)`}</WeekdayTextSub>
    </WeekdayCell>
  );
};

const AvailabilityRoot = styled.div({
  paddingLeft: 30,
});

const WeekdayRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 5,
});

const WeekdayCells = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
});

const WeekdayRowTitle = styled.div({
  fontFamily: 'var(--font-bold)',
});

const WeekdayCell = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 95,
  height: 40,
  backgroundColor: '#f3f3f3',
  borderRadius: 10,
  fontSize: 15,
});

const WeekdayTextSub = styled.div({
  fontSize: 10,
});

const WeekdayLabel = styled.div({
  fontSize: 13,
});

const AvailabilityParagraph = styled.div({
  marginBottom: 15,
});

const AvailabilityTextNote = styled.div({
  fontStyle: 'italic',
});

const AvailabilityTextNotes = styled.div({
  marginBottom: 15,
});

const ProfileItem = styled.div({
  marginBottom: 12,
});

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

const useColors = () => {
  const { palette, query } = useContext(AppThemingContext);

  return useMemo(() => {
    if (!query.data?.enabled) {
      return {
        Lighter: '#f3f3f3',
        Light: Color(colors.blue.main).alpha(0.25).rgb().string(),
        Medium: Color(colors.blue.main).alpha(0.4).rgb().string(),
        Dark: Color(colors.blue.main).alpha(0.5).rgb().string(),
      };
    }

    return {
      Lighter: '#f3f3f3',
      Light: Color(palette.primary.main).alpha(0.25).rgb().string(),
      Medium: Color(palette.primary.main).alpha(0.4).rgb().string(),
      Dark: Color(palette.primary.main).alpha(0.5).rgb().string(),
    };
  }, [
    query.data?.enabled,
    palette,
  ]);
};

const Rank = {
  High: 1,
  Medium: 2,
  Low: 3,
  Zero: 0,
};

const StyledCitationsPane = styled(ChatResponsePane)`
  margin-top: 15px;
`;

const StyledMessageCitations = styled(MessageCitations)`
  margin-top: 10px;
`;