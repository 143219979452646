import { memo, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { config } from '@/config';
import { AppThemingContext } from '@/containers/AppTheming/Context';

export const DocumentHelmet = memo(() => {
  const { query } = useContext(AppThemingContext);
  const description = `.`;
  const title = '';

  const ready = useMemo(() => {
    return !query.isInitialLoading && query.isFetchedAfterMount;
  }, [
    query.isInitialLoading,
    query.isFetchedAfterMount,
  ]);

  const head = useMemo(() => {
    if (!ready) {
      return {
        ogImage: '',
        shortcutIcon: '',
        title: '',
      };
    };

    const enabled = query.data?.enabled;
    const square = query.data?.logo?.square;
    const title = query.data?.app?.title;

    return {
      ogImage: enabled && square ? square : `${window.location.origin}/media/favicon-192x192.png`,
      shortcutIcon: enabled && square ? square : '/favicon.ico',
      title: enabled && title ? title : 'MSL Copilot',
    };
  }, [
    query.data?.app?.title,
    query.data?.enabled,
    query.data?.logo?.square,
    ready,
  ]);

  console.log({
    ready,
    ogImage: head.ogImage,
    shortcutIcon: head.shortcutIcon,
  });

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={`%s`}>
      <meta name="robots" content="noindex" />
      <title>{head.title}</title>
      <link
        rel="shortcut icon"
        href={head.shortcutIcon}
        type="image/x-icon" />
      <link
        rel="icon"
        href={head.shortcutIcon} />
      <link
        rel="canonical"
        href={`${config().FRONT_BASE_URL}/`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
      <meta
        name="og:image"
        content={head.ogImage} />
      <meta
        name="og:site name"
        content={head.title} />
    </Helmet>
  );
});