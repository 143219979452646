import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const ShareChat = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 9 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Share Path</title>
      <g
        id="MSL"
        stroke="none"
        fill="none"
        fillRule="evenodd">
        <g
          id="Share-Path"
          fill="currentColor"
          fillRule="nonzero">
          <path d="M5.0625,4.17878823 L3.375,4.17878823 C2.21132812,4.17878823 1.23046875,5.07673892 0.93515625,6.29811257 C0.877148437,6.04901663 0.84375,5.77179695 0.84375,5.4644447 C0.84375,4.04419607 1.85097656,2.89313176 3.09375,2.89313176 L5.0625,2.89313176 L5.484375,2.89313176 C5.71816406,2.89313176 5.90625,2.67818607 5.90625,2.41101058 L5.90625,1.9288894 L5.90625,1.36440586 L8.01738281,3.53595999 L5.90625,5.70751413 L5.90625,5.14303058 L5.90625,4.66090941 C5.90625,4.39373392 5.71816406,4.17878823 5.484375,4.17878823 L5.0625,4.17878823 Z M5.0625,5.14303058 L5.0625,6.10727294 L5.0625,6.42868706 C5.0625,6.68180067 5.19257812,6.91281707 5.39648438,7.01526782 C5.60039063,7.11771857 5.8359375,7.07553297 6.00117187,6.90679056 L8.81367187,4.0140635 C8.93144531,3.89152436 9,3.71876427 9,3.53595999 C9,3.35315571 8.93320313,3.18039563 8.81367187,3.05785649 L6.00117187,0.165129432 C5.8359375,-0.00562181785 5.59863281,-0.0478074208 5.39648438,0.0566521675 C5.19433594,0.161111756 5.0625,0.390119315 5.0625,0.643232933 L5.0625,0.964647051 L5.0625,1.9288894 L4.21875,1.9288894 L3.09375,1.9288894 C1.38515625,1.9288894 0,3.51185394 0,5.4644447 C0,7.03133853 0.678515625,7.99959856 1.20761719,8.51988766 C1.2796875,8.590197 1.35,8.65247098 1.41328125,8.70670962 C1.46953125,8.75492174 1.52226562,8.7950985 1.56972656,8.83125759 C1.64882812,8.89152274 1.715625,8.93370834 1.75957031,8.96183207 C1.80351562,8.98995581 1.85273438,9 1.90195313,9 C2.09355469,9 2.24824219,8.8212134 2.24824219,8.60425887 C2.24824219,8.46765787 2.18496094,8.33909222 2.10234375,8.24065915 C2.09355469,8.23061496 2.08652344,8.2225796 2.07773437,8.21253541 C2.03554688,8.16633213 1.98808594,8.11008466 1.94238281,8.03977532 C1.9125,7.99357204 1.88261719,7.93933341 1.85449219,7.8810771 C1.76132813,7.68621979 1.6875,7.42105315 1.6875,7.07352413 C1.6875,6.00883986 2.44335938,5.14503942 3.375,5.14503942 L4.21875,5.14503942 L5.0625,5.14503942 L5.0625,5.14303058 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  );
});

