import { useCallback, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { AnalysisQueryHint } from '@/components/presentation';
import { Tooltip } from '@/components/presentation/Tooltip';
import { SelectLocation } from '@/components/Search/Location';
import { ChatContextType } from '@/enums';
import { ChatActionType } from '@/types/chat.actions';
import type { LocationItem } from '@/types/location';
import { useBrowserLocation } from '@/utils/hooks/useBrowserLocation';
import { Divider } from '../presentation/Main';
import { BrowserLocationError } from './BrowserLocationError';
import { ActiveChatSessionContext, KolSearchResultsContext, KolSearchResultsSelectionContext, SubmitActionContext } from './context';

export const GenerateRouteButton = () => {
  const chat = useContext(ActiveChatSessionContext);
  const submitAction = useContext(SubmitActionContext);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { selected } = useContext(KolSearchResultsSelectionContext);
  const results = useContext(KolSearchResultsContext);

  const activeKol = useMemo(() => {
    if (chat.context.type === ChatContextType.KolProfile && results) {
      const kolId = chat.context.data.id;

      return results.find(k => k.id === kolId);
    }
  }, [chat.context.data, chat.context.type, results]);
  const canUseActiveKol = useMemo(() => activeKol && activeKol.locationCoords, [activeKol]);

  const [location, setLocation] = useState<LocationItem | null>(canUseActiveKol ? { latitude: activeKol.locationCoords.latitude, longitude: activeKol.locationCoords.longitude, name: activeKol.locationName, id: 'active-kol' } : null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const { isSupported, getLocation, state } = useBrowserLocation({ onError: setLocationError, onSuccess: (result) => setLocation({ latitude: result.latitude, longitude: result.longitude, name: `(${result.longitude}, ${result.latitude})`, id: 'browser' }) });

  const onClick = useCallback(() => {
    if (chat.filterOverrides.location) {
      submitAction({
        type: ChatActionType.BuildRoute,
        payload: {
          origin: chat.filterOverrides.location,
          kols: selected.map(kolId => ({ kolId })),
        },
      }, 'Generate a route with my selected HCPs');
    } else if (errorMessage && location) {
      let kolIds = selected;
      if (location.id === 'active-kol') {
        kolIds = selected.filter(x => x !== activeKol.id);
      }
      submitAction({
        type: ChatActionType.BuildRoute,
        payload: {
          origin: { latitude: location.latitude, longitude: location.longitude, address: location.name },
          kols: kolIds.map(kolId => ({ kolId })),
        },
      }, 'Generate a route with my selected HCPs');
    } else {
      setErrorMessage(`No location found`);
    }
  }, [chat.filterOverrides.location, errorMessage, location, submitAction, selected, activeKol?.id]);

  const disabled = useMemo(() => selected.length < 2 || (errorMessage && !location), [errorMessage, location, selected.length]);

  return (
    <>
      {errorMessage && (
        <StartPointSection>
          <Divider />
          <ErrorContainer>Please select a starting location for your route before generating a route.</ErrorContainer>
          <LocationInputRow>
            <SelectLocation styles={
              {
                container: (baseStyles) => ({ ...baseStyles, width: '400px' }),
              }
            } value={location} onChange={setLocation} />
          </LocationInputRow>
          {isSupported &&
            <GetLocationSection>
              <GetLocationButton onClick={getLocation}>Share my current location</GetLocationButton>
              {state === 'error' && <BrowserLocationError errorMessage={locationError} />}
            </GetLocationSection>}
        </StartPointSection>
      )}
      <Tooltip
        title={disabled ? 'Please select at least 2 HCPs to generate a route.' : ''}
        placement="top">
        <HintWrapper>
          <AnalysisQueryHint
            disabled={disabled}
            value={'Generate Route'} onClick={onClick} />
        </HintWrapper>
      </Tooltip>
    </>
  );
};

const StartPointSection = styled.div({
  '> *': {
    marginTop: 10,
  },
});

const LocationInputRow = styled.div({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const HintWrapper = styled.div({
  marginTop: 15,
  display: 'inline-flex',
});

const ErrorContainer = styled.div({
  marginTop: 10,
  fontSize: 14,
});

const GetLocationSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  marginTop: 5,
});

const GetLocationButton = styled.div(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.sentiment.primary.main,
  textDecoration: 'underline',
  fontSize: 14,
}));