import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Info } from 'react-feather';
import { LinkContext } from '@/components/BrandInsights/context';
import AudioPlayerController from '@/components/TextToSpeechAudioPlayer/AudioPlayerController';
import { Constants } from '@/components/Theme';
import { isVeevaPlatform } from '@/config/utils';
import { BrandingContainer } from '@/containers/Branding';
import AudioControls from '@/containers/TextToSpeechAudioPlayer/AudioControls';
import { TextToSpeechStateContext } from '@/containers/TextToSpeechAudioPlayer/Context';
import { ChatContextType } from '@/enums';
import { useModal } from '@/hooks/useModal';
import { useAppSelector } from '@/store';
import {
  MessagesListRefContext,
  ChatHasAccessContext,
  ActiveChatSessionContext,
  ChatSessionItemsContext,
} from './context';
import { ChatGuidelinesModal } from './GuidelinesModal';
import { useChatPermissions, useChatGuidelinesCookie } from './hooks';
import { UnsubmittedMessage } from './Input';
import { KolContextGetStarted } from './Kol.GetStarted';
import { messageLaneWidth } from './Message.styles';
import { InitialSystemMessage } from './Message.System.Initial';
import { MessageList } from './MessageList';
import { NoAccessBanner } from './NoAccessBanner';

export const ChatInstance = () => {

  const hasAccess = useContext(ChatHasAccessContext);
  const link = useContext(LinkContext);
  const chat = useContext(ActiveChatSessionContext);
  const chatPermissions = useChatPermissions();

  const [_, setMessagesRef] = useContext(MessagesListRefContext);
  const isFirstSession = useAppSelector(state => state.user.isFirstSession);
  const { isDismissed: chatGuidelinesDismissed } = useChatGuidelinesCookie();
  const [toggleGuidelinesModal, GuidelinesModal] = useModal(ChatGuidelinesModal, {
    defaultOpen: isFirstSession && !chatGuidelinesDismissed,
  });
  const chatGuidelines = useAppSelector(state => state.group.settings.chatGuidelines);
  const showChatGuidelines = !!chatGuidelines;

  const items = useContext(ChatSessionItemsContext);

  const renderMessageList = useCallback(() => {
    if (!link.initialized) return null;

    if (
      chat.context.type === ChatContextType.KolProfile &&
      !chat.context.data.id &&
      !items.length
    ) {
      return <KolContextGetStarted />;
    }

    if (!items.length) {
      return <InitialSystemMessage />;
    }

    return <MessageList items={items} />;
  }, [chat.context, items, link.initialized]);

  const showAudioPlayer = useShowAudioPlayer();

  const renderAudioPlayer = useCallback(() => {
    if (!showAudioPlayer) return null;

    return (
      <AudioPlayerRow>
        <AudioPlayerBox>
          <AudioPlayerController>
            {({
              duration,
              position,
              onScan,
              onSeek,
              onStop,
              onPreviousTrack,
              onNextTrack,
              onSkipBackward,
              onSkipAhead,
              playing,
              onTogglePlay,
              canSkipBackward,
              canSkipForward,
            }) => (
              <AudioControls
                isPlaying={playing}
                onTogglePlay={onTogglePlay}
                onPreviousTrack={onPreviousTrack}
                onNextTrack={onNextTrack}
                onSkipBackward={onSkipBackward}
                onSkipAhead={onSkipAhead}
                disabledPreviousTrack={!canSkipBackward}
                disabledNextTrack={!canSkipForward}
                onStop={onStop}
                currentTime={position}
                duration={duration}
                onScan={onScan}
                onSeek={onSeek}
              />
            )}
          </AudioPlayerController>
        </AudioPlayerBox>
      </AudioPlayerRow>
    );
  }, [showAudioPlayer]);

  return (
    <BrandingContainer>
      <Root>
        {!hasAccess && <NoAccessBanner />}

        <Messages>
          <MessagesWrap ref={setMessagesRef}>
            {renderMessageList()}
          </MessagesWrap>
        </Messages>

        <Footer>
          {renderAudioPlayer()}
          {chatPermissions.canModify && <UnsubmittedMessage />}
          {showChatGuidelines && (
            <DisclaimerText>
              Shared content is meant for internal use only and not meant to be shared externally.
              <OpenDisclaimer onClick={toggleGuidelinesModal}>
                View Usage Guidelines <Info size={16} />
              </OpenDisclaimer>
            </DisclaimerText>
          )}
        </Footer>

        {showChatGuidelines && <GuidelinesModal guidelines={chatGuidelines} />}
      </Root>
    </BrandingContainer>
  );
};

function useShowAudioPlayer() {
  const audioPlayerEnabled = useAppSelector(state => state.group.features?.chatReadAloud && state.group.features?.chatVoiceSource !== 'browser');
  const audioPlayerState = useContext(TextToSpeechStateContext);

  return useMemo(() => audioPlayerEnabled && (!!audioPlayerState.queryId || !!audioPlayerState.kolSearchId), [audioPlayerEnabled, audioPlayerState]);
}

const MaxContainerWidth = 1100;

const DisclaimerText = styled.div({
  width: messageLaneWidth,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const OpenDisclaimer = styled.div(({ theme }) => ({
  display: 'inline-flex',
  color: theme.palette.sentiment.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: 10,
  alignItems: 'center',
  gap: 4,
}));

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${Constants.Modal.HeaderHeight}px)`,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
});

const Messages = styled.div({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
});

const MessagesWrap = styled.div({
  height: '100%',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  paddingTop: 15,
  paddingRight: 10,
  paddingLeft: 10,
});

const Footer = styled.div(({
  display: 'flex',
  flexDirection: 'column',
  padding: `20px 10px ${isVeevaPlatform() ? 15 + Constants.Veeva.ChatFooterBottomPadding : 15}px 10px`,
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',
}));

const AudioPlayerRow = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginBottom: 10,
});

const AudioPlayerBox = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  border: `2px solid ${theme.palette.gray.light2}`,
  padding: 10,
  width: 'auto',
  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default ChatInstance;
