import { memo, useContext } from 'react';
import { KolSearchStatus } from '@/enums/kol';
import type { KolSearchForm } from '@/types/kol';
import AudioStatusListener from './AudioStatusListener';
import {
  KolSearchStatusContext,
  KolSearchFormContext,
  KolSearchResultsContext,
  KolSearchRecommendationsContext,
  KolSearchIdContext,
} from './context';
import { ProfileSelectionErrorMessage, SearchErrorMessage } from './KolSearch.Error';
import { KolSearchFormMessage } from './KolSearch.Form';
import { KolProfileMessageContainer } from './KolSearch.Profile';
import { QuestionsBank } from './KolSearch.QuestionsBank';
import { KolRecommendations } from './KolSearch.Recommendations';
import { KolResultsMessage } from './KolSearch.Results';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { SystemUserPlaceholderMessage } from './Message.System.Placeholder';
import { MessageWrapper } from './Message.System.Wrapper';
import { UserMessage } from './Message.User';

export const KolSearchItem = () => {
  const searchStatus = useContext(KolSearchStatusContext);
  const results = useContext(KolSearchResultsContext);
  const recommendations = useContext(KolSearchRecommendationsContext);
  const kolSearchId = useContext(KolSearchIdContext);

  const showSearchForm = searchStatus == KolSearchStatus.FormEntry;
  const showUserMessage = ![KolSearchStatus.ProfileRecommendations, KolSearchStatus.FormEntry, KolSearchStatus.QuestionsBank].includes(searchStatus);
  const showSearchResultsLoadingMessage = searchStatus === KolSearchStatus.FormSubmitted;
  const showResultsMessage = [
    KolSearchStatus.MultipleResultsReturned,
    KolSearchStatus.SearchCancelled,
    KolSearchStatus.ProfileSelected,
    KolSearchStatus.ProfileSelectionSubmitted,
  ].includes(searchStatus) && results && results.length > 1;
  const showNoResultsMessage = searchStatus === KolSearchStatus.NoResultsReturned;
  const showProfile = searchStatus === KolSearchStatus.ProfileSelected;
  const showProfileLoadingMessage = searchStatus === KolSearchStatus.ProfileSelectionSubmitted;
  const showSearchErrorMessage = searchStatus === KolSearchStatus.FormSubmissionErrored;
  const showProfileErrorMessage = searchStatus === KolSearchStatus.ProfileSelectionErrored;
  const showRecommendations = recommendations.status !== 'skipped';
  const showQuestionsBank = searchStatus === KolSearchStatus.QuestionsBank;

  return (
    <>
      {showQuestionsBank && <QuestionsBank />}
      {showRecommendations && <KolRecommendations />}
      {showSearchForm && <KolSearchFormMessage />}
      {showUserMessage && <UserSearchMessage />}
      {showSearchResultsLoadingMessage && <SystemUserPlaceholderMessage />}
      {showResultsMessage && <KolResultsMessage />}
      {showNoResultsMessage && <NoResultsMessage />}
      {showProfileLoadingMessage && <SystemUserPlaceholderMessage />}
      {showProfile && (
        <AudioStatusListener
          queryIdentifier={`kol-search:${kolSearchId}`}
          kolSearchId={kolSearchId}>
          <KolProfileMessageContainer />
        </AudioStatusListener>
      )}
      {showSearchErrorMessage && <SearchErrorMessage />}
      {showProfileErrorMessage && <ProfileSelectionErrorMessage />}
    </>
  );
};

const NoResultsMessage = memo(() => {
  const copy = `I'm sorry, I couldn't find any HCPs using the filters you provided. Please try again.`;
  return (
    <MessageWrapper>
      <BasicSystemUserMessage>
        {copy}
      </BasicSystemUserMessage>
    </MessageWrapper>
  );
});

const UserSearchMessage = memo(() => {
  const formState = useContext(KolSearchFormContext);
  const copy = formState.kolId
    ? `Show me the profile for "${formState.name}".`
    : buildSearchMessage(formState);
  return (
    <UserMessage value={copy} />
  );
});

function buildSearchMessage(formState: Partial<KolSearchForm>) {
  const filters: string[] = [];

  if (formState.name) {
    filters.push(`with the name "${formState.name}"`);
  }

  if (formState.location) {
    if (formState.location.name) {
      filters.push(`near the location "${formState.location.name}"`);
    } else {
      filters.push(`near the location (${formState.location.latitude}, ${formState.location.longitude})`);
    }
  }

  return `Find any matching HCPs ${filters.join(' and ')}.`;
}