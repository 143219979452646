import { useCallback } from 'react';
import { ActiveSliderTrack, InactiveSliderTrack, SliderThumb } from '@/components/presentation/Slider';
import { useSlider } from '@/components/Slider/hooks/useSlider';
import { useAudioPlayerState } from '@/containers/AudioPlayer';
import styles from './style/Progress.Track.module.css';

type Props = {
  scan: (time: number) => void;
  seek: (time: number) => void;
  position: number;
  className?: string;
};

export const AudioPlayerTrackProgress = ({ position, scan, seek, className }: Props) => {
  const [{ duration }] = useAudioPlayerState();

  const handlePositionChange = useCallback((p: number) => {
    scan(p);
  }, [scan]);

  const handleDragEnd = useCallback(() => {
    seek(position);
  }, [seek, position]);

  const {
    activeTrackStyles,
    handleMouseDown,
    handleTouchStart,
    thumbStyles,
    sliderRef,
    thumbRef,
  } = useSlider({
    incrementBy: .05,
    minValue: 0,
    maxValue: duration,
    onDragEnd: handleDragEnd,
    onPositionChange: handlePositionChange,
    value: position,
  });

  return (
    <div
      className={`${styles.track} ${className || ''}`}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}>
      <ActiveSliderTrack
        className={styles.active}
        style={activeTrackStyles} />
      <InactiveSliderTrack
        className={styles.inactive} />
      <SliderThumb
        ref={thumbRef}
        className={styles.thumb}
        style={thumbStyles} />
    </div>
  );
};

export default AudioPlayerTrackProgress;