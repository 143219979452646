import { createId } from '@paralleldrive/cuid2';
import { isDemoEnv } from '@/config/utils';
import { ChatContextType } from '@/enums/chat';
import { ChatDataSource, ChatDataSourcesMap, ChatSource, ChatSourcesMap } from '@/enums/chat.filters';
import { KolSearchStatus } from '@/enums/kol';
import type { GroupFeatures } from '@/store/interfaces';
import type { BrandInsightsPageContext, Chat } from '@/types';
import type { ChatSessionState, ChatState, SettingsState } from './interfaces';
import type { ChatSessionManagerState } from './interfaces.chat-session-manager';

type GenerateEmptyChatSessionStateData = {
  context?: BrandInsightsPageContext.Item;
  features: Pick<GroupFeatures, 'hcpProfiles' | 'workspaces'>;
};

export function generateIntialKolProfileContext(): BrandInsightsPageContext.KolProfile {
  return { type: ChatContextType.KolProfile, data: { id: null }, metadata: { name: '' } };
}

export function buildDefaultChatContext(contextType: ChatContextType): BrandInsightsPageContext.Item {
  switch (contextType) {
    case ChatContextType.KolProfile:
      return generateIntialKolProfileContext();

    case ChatContextType.Global:
      return { type: ChatContextType.Global };

    default:
      return null;
  }
}

export function generateEmptyChatSessionState({ context, features }: GenerateEmptyChatSessionStateData): ChatSessionState.State {

  return {
    chatInstance: {
      createdOn: new Date(),
      id: null,
      identifier: createId(),
      name: '',
      currentQueryIdentifier: null,
      context,
      shared: null,
      bookmarked: false,
      filterOverrides: {},
    },
    input: '',
    selectedQueryIdentifier: null,
    queries: {},
    filters: generateEmptyChatSessionFiltersState({ context, features }),
    fileUploads: [],
    pendingFindQuotes: false,
    kolSearches: [],
  };
}

export function generateEmptyKolSearch(): Chat.KolSearch {
  return {
    id: null,
    createdOn: new Date(),
    status: KolSearchStatus.ProfileRecommendations,
    identifier: createId(),
    followupQuestions: [],
    form: {
      name: '',
    },
    results: null,
    profile: null,
    recommendations: {
      status: 'uninitialized',
      items: [],
      total: 0,
    },
    citations: [],
  };
}

type GenerateEmptyChatSessionFiltersState = {
  context?: Pick<BrandInsightsPageContext.Item, 'type'>;
  features: Pick<GroupFeatures, 'hcpProfiles' | 'workspaces'>;
};

export function generateEmptyChatSessionFiltersState({ context, features }: GenerateEmptyChatSessionFiltersState): ChatSessionState.State['filters'] {

  const kolDataSources = (isDemoEnv() ? [ChatDataSource.VeevaLink] : [
    ChatDataSource.IConnect,
    ChatDataSource.MEAI,
    ChatDataSource.VeevaLink,
  ]).map(m => ({
    id: m,
    name: ChatDataSourcesMap.get(m),
  }));

  const kolSources = [
    ChatSource.Profiles,
    ChatSource.MslInteractions,
  ].map(m => ({
    id: m,
    name: ChatSourcesMap.get(m),
  }));

  const workspaceSources = [
    ChatSource.Presentations,
    ChatSource.Text,
  ].map(m => ({
    id: m,
    name: ChatSourcesMap.get(m),
  }));

  switch (context.type) {
    case ChatContextType.KolProfile:
      return {
        custom: [],
        dataSources: kolDataSources,
        sources: kolSources,
        minDocumentDate: null,
        tags: [],
      };

    case ChatContextType.Global:
      return {
        custom: [],
        dataSources: [
          ...(features.hcpProfiles ? kolDataSources : []),
        ].filter(Boolean),
        sources: [
          ...(features.workspaces ? workspaceSources : []),
          ...(features.hcpProfiles ? kolSources : []),
        ].filter(Boolean),
        minDocumentDate: null,
        tags: [],
      };

    case ChatContextType.Document:
      return {
        custom: [],
        dataSources: [],
        sources: [],
        minDocumentDate: null,
        tags: [],
      };

    case ChatContextType.Folder:
    default:
      return {
        custom: [],
        dataSources: [],
        sources: [
          features.workspaces && {
            id: ChatSource.Presentations,
            name: 'Presentations',
          },
          features.workspaces && {
            id: ChatSource.Text,
            name: 'Text',
          },
        ].filter(Boolean),
        minDocumentDate: null,
        tags: [],
      };
  }
}

export function generateEmptyChatSessionManagerState(): ChatSessionManagerState.State {
  return {
    currentChatInstanceIdentifier: null,
    nextChatInstanceIdentifier: null,
    status: 'uninitialized',
  };
}

type GenerateEmptyChatStateData = {
  settings: SettingsState.State;
} & GenerateEmptyChatSessionStateData;

export function generateEmptyChatState(data: GenerateEmptyChatStateData): ChatState.State {
  const context = data.context || buildDefaultChatContext(data.settings.defaultContextType);

  return {
    settings: data.settings,
    history: {
      manager: {
        mode: 'page',
        cursor: null,
        loading: false,
        hasMore: false,
        initialized: false,
      },
      items: [],
      shared: [],
    },
    session: generateEmptyChatSessionState({ context, features: data.features }),
    sessionManager: generateEmptyChatSessionManagerState(),
  };
}