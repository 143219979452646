import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import SelectAsync from 'react-select';
import { searchKols } from '@/api/search';
import { SearchQueryHint } from '@/components/presentation';
import { SelectLocation } from '@/components/Search/Location';
import { KolSearchStatus } from '@/enums/kol';
import { useHasSmartRouting } from '@/store/selectors';
import type { KolSearchForm, KolSearchResult } from '@/types/kol';
import { useBrowserLocation } from '@/utils/hooks/useBrowserLocation';
import { BrowserLocationError } from './BrowserLocationError';
import {
  DispatchKolSearchFormContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
  KolSearchFormContext,
} from './context';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolSearchFormMessage = () => {
  const searchStatus = useContext(KolSearchStatusContext);
  const handleSubmit = useContext(KolSearchSubmitContext);
  const formState = useContext(KolSearchFormContext);
  const setFormState = useContext(DispatchKolSearchFormContext);
  const inputFocused = useRef(false);

  const copy = `Let's get started. First lets find the HCP you'd like to profile!`;
  const instructions = `Enter details to find the HCP you would like to profile.`;

  const submitDisabled = useMemo(() => {
    return (!formState.name && !formState.location) || searchStatus !== KolSearchStatus.FormEntry;
  }, [formState.location, formState.name, searchStatus]);

  const inputDisabled = useMemo(() => {
    return searchStatus !== KolSearchStatus.FormEntry;
  }, [searchStatus]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {

    if (!inputFocused.current) return;

    if (submitDisabled) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }, [submitDisabled, handleSubmit]);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  const onKolSelect = useCallback((kol: KolSearchResult) => {
    setFormState({ kolId: kol.id, name: kol.name });
    handleSubmit();
  }, [handleSubmit, setFormState]);

  return (
    <MessageContainer>
      <BasicSystemUserMessage>
        <Body>
          {copy}
          <Divider />
          <Instructions>{instructions}</Instructions>

          <KolSearchInputForm
            formState={formState}
            inputDisabled={inputDisabled}
            onKeyDown={handleKeyDown}
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            onKolSelect={onKolSelect}
            setFormState={setFormState} />
          <Divider />
          <SearchQueryHint
            disabled={submitDisabled}
            onClick={handleSubmit}
            value="Search for profiles." />
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

type FormProps = {
  className?: string;
  inputDisabled: boolean;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onKolSelect: (kol: KolSearchResult) => void;
  formState: Partial<KolSearchForm>;
  setFormState: (form: Partial<KolSearchForm>) => void;
};

export const KolSearchInputForm = ({
  className,
  formState,
  inputDisabled,
  onInputBlur,
  onInputFocus,
  onKeyDown,
  onKolSelect,
  setFormState,
}: FormProps) => {

  const searchQuery = useQuery({
    queryKey: ['get:kol-search', formState.name],
    queryFn: () => searchKols({ name: formState.name }),
    enabled: formState.name?.length > 2,
    placeholderData: { results: [] },
  });

  const [locationError, setLocationError] = useState<string | null>(null);
  const { getLocation, isSupported, state } = useBrowserLocation({
    onSuccess: result => {
      setFormState({ location: { id: 'browser-location', latitude: result.latitude, longitude: result.longitude, name: `(${result.latitude}, ${result.longitude})` } });
    }, onError: setLocationError,
  });

  const hasSmartRouting = useHasSmartRouting();

  return (
    <div className={className}>
      <FormItem>
        <Label>Search by Name</Label>
        <SelectAsync
          menuPortalTarget={document.body}
          isDisabled={inputDisabled}
          onKeyDown={searchQuery.data.results.length ? undefined : onKeyDown}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          styles={{
            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
          }}
          onChange={kol => onKolSelect(kol)}
          inputValue={formState.name}
          isLoading={searchQuery.isFetching}
          options={searchQuery.data.results}
          getOptionLabel={o => o.name}
          formatOptionLabel={formatKolOptionLabel}
          getOptionValue={o => o.id.toString()}
          noOptionsMessage={() => null}
          filterOption={() => true}
          onInputChange={(e, meta) => meta.action === 'input-change' ? setFormState({ ...formState, name: e }) : null}
          placeholder="Name" />
      </FormItem>
      {hasSmartRouting && (
        <>
          <Label>OR</Label>
          <FormItem>
            <Label>Search by address or current location</Label>
            <SelectLocation
              styles={{
                container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
              }}
              isDisabled={inputDisabled}
              value={formState.location}
              onChange={location => setFormState({ ...formState, location })} />
            {isSupported &&
              <GetLocationSection>
                <GetLocationButton onClick={getLocation}>Share my current location</GetLocationButton>
                {state === 'error' && <BrowserLocationError errorMessage={locationError} />}
              </GetLocationSection>}
          </FormItem>
        </>
      )}
    </div >
  );
};

function formatKolOptionLabel(result: KolSearchResult) {
  const specialty = result.specialties?.[0];
  const primaryAffiliation = result.affiliations?.[0]?.name;
  return (
    <div>
      {result.name}{specialty ? <KolOptionSubText>({specialty})</KolOptionSubText> : null}
      {primaryAffiliation ? <KolResultSecondaryText>{primaryAffiliation}</KolResultSecondaryText> : null}
    </div>
  );
}

const KolResultSecondaryText = styled.div(({ theme }) => ({
  color: theme.palette.gray.main,
  marginTop: 5,
  fontSize: 15,
}));

const Body = styled.div({
  width: 500,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const Instructions = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  marginBottom: 20,
}));

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  marginBottom: 10,
}));

const FormItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginBottom: 10,
});

const KolOptionSubText = styled.span(({ theme }) => ({
  fontSize: 14,
  marginLeft: 5,
  color: theme.palette.gray.main,
}));

const GetLocationSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  marginTop: 5,
});

const GetLocationButton = styled.div(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.sentiment.primary.main,
  textDecoration: 'underline',
  fontSize: 14,
}));