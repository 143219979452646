import { useCallback, useContext, useEffect } from 'react';
import { config } from '@/config';
import { useAppSelector } from '@/store';
import { usePrevious } from '@/utils/hooks';
import { useDeleteChatInstanceMutation } from '../Chat.History/hooks';
import { ActiveChatSessionContext } from './context';

type Props = {
  children: React.ReactNode;
};

export const ChatSessionEndGuard = (props: Props) => {

  const deleteChatsOnSessionEnd = useAppSelector(state => state.group.features.deleteChatsOnSessionEnd);

  if (deleteChatsOnSessionEnd) {
    return <Guard {...props} />;
  }

  return (
    <>
      {props.children}
    </>
  );
};

const Guard = (props: Props) => {

  useDeleteChatOnSessionEnd();
  useDeleteChatSessionOnUnload();

  return (
    <>
      {props.children}
    </>
  );
};

function useDeleteChatSessionOnUnload() {
  const chatSession = useContext(ActiveChatSessionContext);
  const chatInstanceId = chatSession.id;

  const deleteChat = useCallback(async () => {
    console.log('ChatSessionEndGuard: deleting chat instance');
    const deleteUrl = `${config().BRAND_INSIGHTS_API_URL}/chat/${chatInstanceId}/expire`;
    navigator.sendBeacon(deleteUrl);
  }, [chatInstanceId]);

  useEffect(() => {
    window.addEventListener('beforeunload', deleteChat);

    return () => {
      window.removeEventListener('beforeunload', deleteChat);
    };
  }, [chatInstanceId, deleteChat]);
}

function useDeleteChatOnSessionEnd() {
  const chatSession = useContext(ActiveChatSessionContext);

  const chatInstanceId = chatSession.id;
  const previousChatInstanceId = usePrevious(chatInstanceId);

  const deleteChatMutation = useDeleteChatInstanceMutation();

  useEffect(() => {
    if (previousChatInstanceId && chatInstanceId !== previousChatInstanceId) {
      console.log('ChatSessionEndGuard: ', previousChatInstanceId, chatInstanceId);
      console.log('ChatSessionEndGuard: deleting chat instance');
      deleteChatMutation.mutateAsync(previousChatInstanceId);
    }
  }, [chatInstanceId, previousChatInstanceId, deleteChatMutation]);
}