import { useCallback, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import cuid2 from '@paralleldrive/cuid2';
import * as atlas from 'azure-maps-control';
import { Check, Edit2, X } from 'react-feather';
import { SubmitActionContext } from '@/components/Chat/context';
import { SelectLocation } from '@/components/Search/Location';
import type { ChatResponseType } from '@/enums';
import type { BuildMapLinkParams, LocationDescriptor } from '@/lib/interfaces';
import type { Chat } from '@/types/chat';
import { ChatActionType } from '@/types/chat.actions';
import type { LocationItem } from '@/types/location';
import { LocationProviderType } from '@/types/location';
import { useBrowserLocation } from '@/utils/hooks/useBrowserLocation';
import { AzureMap } from '../Maps/AzureMap';
import { MessageResponseMarkdown } from './Message.Response.Markdown';
import { OpenMapsButton } from './OpenMapsButton';

type Props = {
  queryIdentifier: string;
  item: Chat.QueryResponse<ChatResponseType.LocationRouting>;
  state: Chat.QueryState.Value<ChatResponseType.LocationRouting>;
};

export const RoutingResult = ({ item, state }: Props) => {
  const mapParams = useMemo(() => {
    const points = [state.routingResult.legs[0].start, ...state.routingResult.legs.map(leg => leg.end)].map<LocationDescriptor>(point => {
      if (point.address) {
        return {
          type: 'address',
          address: point.address,
        };
      } else {
        return {
          type: 'coords',
          latitude: point.latitude,
          longitude: point.longitude,
        };
      }
    });

    const mapsParams: BuildMapLinkParams = {
      origin: points[0],
      destination: points[points.length - 1],
      waypoints: points.slice(1, -1),
    };

    return mapsParams;
  }, [state.routingResult.legs]);

  return (
    <div>
      {item.data?.items &&
        <EditableRouteLegs item={item} />}
      {!item.data?.items &&
        <MessageResponseMarkdown value={item.value} />}
      <RouteResultMap state={state} item={item} />
      <OpenMapsButton mapParams={mapParams}>
        <AutoAwesomeIcon style={{ fontSize: 18 }} /> Get Directions
      </OpenMapsButton>
    </div>
  );
};

type RouteLegProps = {
  ordinal: number;
  name: string;
  location: string;
  distance: string;
};

const RouteLeg = (props: RouteLegProps) => {
  return (
    <Root>
      <Em>{`${props.ordinal}. ${props.name}`}</Em>
      <div>{`-`}</div>
      <div>{props.location}</div>
      <div>{`-`}</div>
      <div>{props.distance}</div>
    </Root>
  );
};

type EditableRouteLegProps = {
  latitude: number;
  longitude: number;
  kolId: number;
  setForm: (data: Omit<LocationItem, 'name'> & { location: string }) => void;
} & RouteLegProps;

const EditableRouteLeg = ({ ordinal, setForm, ...props }: EditableRouteLegProps) => {
  const handleChange = useCallback((data: LocationItem) => {
    setForm({
      latitude: data.latitude,
      longitude: data.longitude,
      location: data.name,
      id: data.id ?? ordinal.toString(),
    });
  }, [
    ordinal,
    setForm,
  ]);

  return (
    <Root>
      <Em>{`${ordinal}. ${props.name}`}</Em>
      <div>
        <SelectLocation
          onChange={handleChange}
          placeholder={props.location}
          value={{
            latitude: props.latitude,
            longitude: props.longitude,
            id: ordinal.toString(),
            name: props.location,
          }} />
      </div>
    </Root>
  );
};

type FormItem = Omit<LocationItem, 'id' | 'name'> & { id?: string; location: string };

const EditableRouteLegs = ({ item }: Pick<Props, 'item'>) => {
  const submitAction = useContext(SubmitActionContext);
  const { getLocation } = useBrowserLocation({ onError: () => { }, onSuccess: () => { } });
  const [editing, setEditing] = useState(false);

  const buildLeg = useCallback((leg: Chat.QueryResponse<ChatResponseType.LocationRouting>['data']['legs'][0], ordinal: number) => {
    const kolId = +leg.end.id;
    const kol = item.data.items.find(k => Number(k.kolId) === Number(kolId));
    const props = {
      ordinal: ordinal + 1,
      name: kol?.name ?? JSON.stringify(leg.end),
      location: kol?.locationText,
      distance: `${leg.distanceMiles.toFixed(1)} miles`,
      latitude: kol.geoPoint?.latitude,
      longitude: kol.geoPoint?.longitude,
      kolId,
    };

    return props;
  }, [item.data?.items]);

  const legs = useMemo(() => {
    return item.data.legs.map((x, i) => buildLeg(x, i));
  }, [
    buildLeg,
    item.data?.legs,
  ]);

  const getInitialState = useCallback(() => {
    return legs.reduce((acc, x) => ({
      ...acc,
      [x.ordinal]: {
        latitude: x.latitude,
        longitude: x.longitude,
        location: x.location,
        id: null,
      },
    }), {} as Record<number, FormItem>);
  }, [legs]);

  const [startLocation, setStartLocation] = useState<FormItem>({ latitude: item.data.legs[0].start.latitude, longitude: item.data.legs[0].start.longitude, location: item.data.legs[0].start.address ?? `(${item.data.legs[0].start.longitude}, ${item.data.legs[0].start.latitude})` });
  const [form, setForm] = useState(getInitialState);

  const resetForm = useCallback(() => {
    setEditing(false);
    setForm(getInitialState());
  }, [
    getInitialState,
    setEditing,
  ]);

  const canSubmit = useMemo(() => {
    return Object.values(form).some(x => x.id) || !!startLocation.id;
  }, [form, startLocation.id]);

  const getKolsPayload = useCallback(() => {
    return legs.map(leg => {
      const value = form[leg.ordinal];

      if (!value.id) {
        return {
          kolId: leg.kolId,
        };
      }

      return {
        kolId: leg.kolId,
        location: {
          address: value.location,
          latitude: value.latitude,
          longitude: value.longitude,
        },
      };
    });
  }, [
    form,
    legs,
  ]);

  const handleSubmit = useCallback(() => {
    const kols = getKolsPayload();

    if (startLocation) {
      submitAction({
        type: ChatActionType.BuildRoute,
        payload: {
          origin: { latitude: startLocation.latitude, longitude: startLocation.longitude, address: startLocation.location },
          kols,
        },
      }, 'Generate an updated route with my selected HCPs');
      resetForm();
    } else {
      getLocation().then((location) => {
        submitAction({
          type: ChatActionType.BuildRoute,
          payload: {
            origin: { latitude: location.latitude, longitude: location.longitude },
            kols,
          },
        }, 'Generate an updated route with my selected HCPs');
        resetForm();
      });
    }

  }, [getKolsPayload, startLocation, submitAction, resetForm, getLocation]);

  return (
    <Routes>
      <Header>{copy.header}</Header>
      <Actions>
        {!editing &&
          <EditAction onClick={() => setEditing(true)}>
            <EditIcon size={18} />
          </EditAction>}
        {editing &&
          <CancelAction onClick={resetForm}>
            <CancelIcon size={18} />
          </CancelAction>}
        {(editing && canSubmit) &&
          <SubmitAction onClick={handleSubmit}>
            <CheckIcon size={18} />
          </SubmitAction>}
      </Actions>
      <RouteLegs>
        <EditableStartLocation editing={editing} startLocation={startLocation} setStartLocation={setStartLocation} />
        {legs.map((x, i) =>
          <div key={i}>
            {!editing
              ? <RouteLeg {...x} />
              : <EditableRouteLeg {...x} {...form[x.ordinal]} setForm={data => setForm(form => ({ ...form, [x.ordinal]: data }))} />}
          </div>)}
      </RouteLegs>
      <Total>
        <Em>{`Total Distance:`}</Em>
        <div>{`${item.data.legs.reduce((acc, x) => acc + x.distanceMiles, 0).toFixed(1)} miles`}</div>
      </Total>
      <Header>{copy.footer}</Header>
    </Routes>
  );
};

type EditableStartLocationProps = {
  editing: boolean;
  startLocation: FormItem;
  setStartLocation: (data: FormItem) => void;
};

const EditableStartLocation = (props: EditableStartLocationProps) => {
  const currentName = props.startLocation.location ?? `(${props.startLocation.longitude}, ${props.startLocation.latitude})`;
  if (props.editing) {
    return (
      <Root>
        <Em>Start Location</Em>
        <div>
          <SelectLocation
            onChange={val => props.setStartLocation({ ...val, location: val.name, id: 'changed-val' })}
            placeholder={currentName}
            value={{
              latitude: props.startLocation.latitude,
              longitude: props.startLocation.longitude,
              id: props.startLocation.id,
              name: props.startLocation.location,
            }} />
        </div>
      </Root>
    );
  } else {
    return (<Root><Em>Start Location -</Em>{currentName}</Root>);
  }
};

const Routes = styled.div({
  position: 'relative',
});

const Actions = styled.div({
  display: 'flex',
  gap: 5,
  position: 'absolute',
  top: 30,
  right: 0,
});

const EditIcon = styled(Edit2)(({ theme }) => `
  color: ${theme.palette.primary.main};
  cursor: pointer;
`);

const EditAction = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  justify-Content: center;
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`);

const CancelIcon = styled(X)(({ theme }) => `
  color: ${theme.palette.black.main};
  cursor: pointer;
`);

const CancelAction = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  justify-Content: center;
  border: 2px solid ${theme.palette.black.main};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`);

const CheckIcon = styled(Check)(({ theme }) => `
  color: ${theme.palette.green.main};
  cursor: pointer;
`);

const SubmitAction = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  justify-Content: center;
  border: 2px solid ${theme.palette.green.main};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`);

const RouteLegs = styled.div({
  margin: '15px 0',
});

const Root = styled.div({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  marginBottom: 10,
});

const Total = styled.div({
  display: 'flex',
  gap: 5,
});

const Em = styled.div({
  fontFamily: 'var(--font-semibold)',
});

const copy = {
  header: `Based on your selected HCPs we've generated an efficient route to maximize your visits while minimizing travel time.`,
  footer: `This route is optimized to ensure efficient visits while minimizing travel time. You can now proceed with your day seamlessly!`,
};

const Header = styled.div({
  marginBottom: 10,
});

type MapsProps = Pick<Props, 'item' | 'state'>;

const RouteResultMap = (props: MapsProps) => {
  if (props.state.routingResult.provider === LocationProviderType.Azure) {
    return (
      <MapContainer>
        <AzureRouteMap {...props} />
      </MapContainer>
    );
  }
};

const AzureRouteMap = (props: MapsProps) => {
  const routingResult = props.state.routingResult as Chat.QueryState.LocationRouting<LocationProviderType.Azure>['routingResult'];
  const dataSourceId = useMemo(() => `route-${cuid2.createId()}`, []);

  const getPointName = useCallback((point: Chat.QueryState.LocationInfo) => {
    if (point.kolId) {
      const kol = props.item.data.items.find(k => Number(k.kolId) === Number(point.kolId));
      return kol?.name;
    } else {
      return point.address;
    }
  }, [props.item.data.items]);
  const features = useMemo(() => {
    return [
      new atlas.data.MultiLineString(routingResult.legs.map(l => l.meta.points.map(p => [p.longitude, p.latitude]))),
      new atlas.data.Feature(new atlas.data.Point([routingResult.legs[0].start.longitude, routingResult.legs[0].start.latitude]), { title: getPointName(routingResult.legs[0].start) ?? 'Start', icon: 'pin-round-red' }),
      ...routingResult.legs.map(leg => new atlas.data.Feature(new atlas.data.Point([leg.end.longitude, leg.end.latitude]), { title: getPointName(leg.end), icon: 'pin-round-blue' }),
      ),
    ];
  }, [getPointName, routingResult.legs]);

  const layers = useMemo(() => {
    return [
      new atlas.layer.LineLayer(dataSourceId, 'lines', {
        strokeColor: '#2272B9',
        strokeWidth: 5,
        lineJoin: 'round',
        lineCap: 'round',
      }),
      new atlas.layer.SymbolLayer(dataSourceId, 'icon-symbols', {
        iconOptions: {
          image: ['get', 'icon'],
          allowOverlap: true,
          ignorePlacement: true,
          offset: [0, 0],
        },
        textOptions: {
          textField: ['get', 'title'],
          offset: [0, 1.2],
          haloColor: 'white',
          haloWidth: 1.5,
        },
        filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']], //Only render Point or MultiPoints in this layer.
      }),
    ];
  }, [dataSourceId]);

  return (
    <AzureMap features={features} layers={layers} dataSourceId={dataSourceId} height='100%' width='100%' />
  );
};

const MapContainer = styled.div({
  height: 300,
  width: '100%',
  marginTop: 15,
});