import { useCallback, useState } from 'react';

type Props = {
  onSuccess: (result: GeolocationCoordinates) => void;
  onError: (message: string) => void;
};

export const useBrowserLocation = ({ onSuccess, onError }: Props) => {
  const [state, setState] = useState<'loading' | 'error' | 'success' | 'idle'>('idle');
  const getLocation = useCallback(() => {
    return new Promise<GeolocationCoordinates>((resolve, reject) => {
      if (!navigator.geolocation) {
        setState('error');
        reject(new Error(`Browser location not supported`));
        onError(`Browser location not supported`);
      } else {
        setState('loading');
        navigator.geolocation.getCurrentPosition(result => {
          setState('success');
          resolve(result.coords);
          onSuccess(result.coords);
        }, err => {
          setState('error');
          reject(err);
          onError(err.message);
        });
      }
    });
  }, [onError, onSuccess]);

  return {
    isSupported: !!navigator.geolocation,
    getLocation,
    state,
  };
};