import { memo } from 'react';
import styled from '@emotion/styled';

type Props = {
  name: string | number;
  count: string | number;
};

export const ChartHoverText = memo((props: Props) => {
  return (
    <Root>
      <Header>{props.name}</Header>
      <div>Count: {props.count}</div>
    </Root>
  );
});

const Root = styled.div`
  background: white;
  padding: 9px 12px;
  border: 1px solid #ccc;
`;

const Header = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));