import { useCallback, useContext, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { addItems } from '@/api/projects.entities';
import { Button } from '@/components/presentation/Button';
import type { ContextMenuItem } from '@/components/presentation/ContextMenu';
import { ContextMenu as BaseContextMenu } from '@/components/presentation/ContextMenu';
import { TranscriptReplacementsContext, ProjectTermsContext, TermFilterContext, SelectedTermsContext } from '@/containers/Project.Terms';
import { useAddEntityModal } from './AddEntity.Modal';
import { EntityTypesContainer } from './EntityTypes.Container';
import { useSelectedTerms } from './hooks';
import { useMergeTermsModal } from './MergeTermsModal';

export const ContextMenu = () => {
  const { projectId, mode, changeMode, addConfirmedTerms } = useContext(TranscriptReplacementsContext);
  const { setHighlightTerm } = useContext(TermFilterContext);
  const { query: termsQuery } = useContext(ProjectTermsContext);
  const selectedTerms = useSelectedTerms();

  const addEntityMutation = useMutation({
    mutationKey: ['post:projects/entities', projectId],
    mutationFn: (items: Parameters<typeof addItems>[0]['items']) => {
      return addItems({
        projectId,
        items,
      });
    }, onSuccess: () => {
      termsQuery.refetch().then(toggleAddEntityModal);
    },
  });

  const [toggleAddEntityModal, AddEntityModal] = useAddEntityModal();
  const [toggleMergeTermsModal, MergeTermsModal] = useMergeTermsModal();

  const toggleMode = useCallback(() => {
    if (mode === 'typo-cleanup') {
      setHighlightTerm('');
      changeMode('normal');
    } else {
      changeMode('typo-cleanup');
    }
  }, [mode, changeMode, setHighlightTerm]);

  const ignoreSelectedWords = useCallback(() => {
    addConfirmedTerms(selectedTerms.map(t => ({
      term: t.termValue,
      transcriptIds: t.transcriptIds,
    })));
  }, [addConfirmedTerms, selectedTerms]);

  const items = useMemo<ContextMenuItem[]>(() => ([
    { label: 'Merge Terms', onClick: toggleMergeTermsModal, disabled: true /* !selectedTerms.length */ },
    { label: 'Add Boosted Terms', onClick: toggleAddEntityModal },
    { label: `${mode === 'typo-cleanup' ? 'Exit ' : ''}Typo Cleanup Mode`, disabled: true, onClick: toggleMode },
    { label: `Ignore Terms`, onClick: ignoreSelectedWords, disabled: true /* selectedTerms.length */ },
  ]), [ignoreSelectedWords, mode, selectedTerms.length, toggleAddEntityModal, toggleMergeTermsModal, toggleMode]);
  return (
    <>
      <BaseContextMenu
        options={{ placement: 'bottom' }}
        items={items}
        AnchorElement={ActionsButton} />
      <MergeTermsModal />
      <EntityTypesContainer>
        <AddEntityModal
          onSubmit={addEntityMutation.mutateAsync}
          loading={addEntityMutation.isLoading} />
      </EntityTypesContainer>
    </>
  );
};

const ActionsButton = () => {
  return (
    <Button variant='brick' color='destructive'>Actions</Button>
  );
};