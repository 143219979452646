import { useCallback, useMemo } from 'react';
import { WorkspaceRoleMap } from '@/enums';
import type { ObjectAccessUser } from '@/types/workspace.access';
import styles from './style/ObjectAccess.module.css';
import { sortObjectAccess } from './utils';

type Props = {
  items: ObjectAccessUser[];
};

export const ObjectAccess = ({ items }: Props) => {

  const sorted = useMemo(() => items.sort(sortObjectAccess), [items]);

  const renderRow = useCallback((item: ObjectAccessUser) => {
    const name = [item.user.firstName, item.user.lastName].join(' ');

    return (
      <div key={item.user.id} className={styles.row}>
        <div className={styles.col}>{name}</div>
        <div className={styles.col}>
          <div className={styles.owner}>
            {WorkspaceRoleMap[item.roleId]}
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.col}>Who has access</div>
        <div className={styles.col} />
      </div>
      <div className={styles.body}>
        {sorted.map(renderRow)}
      </div>
    </div>
  );
};

export default ObjectAccess;