import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';

type Props = {
  onSuccess?: () => void;
};

export const useDeleteChatInstanceMutation = ({ onSuccess }: Props = {}) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation(['chat:delete'], (chatInstanceId: number) => {
    return $api.deleteChatInstance({
      chatInstanceId,
    });
  }, {
    onSuccess: (_, chatInstanceId) => {
      onSuccess?.();
      dispatch({
        type: 'chat-instance-removed',
        payload: {
          chatInstance: {
            id: chatInstanceId,
          },
        },
      });
    },
  });

  return mutation;
};