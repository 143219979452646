/** @jsxImportSource @emotion/react */
import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as $api from '@/api/kol';
import { SearchQueryHint } from '@/components/presentation/Message.QueryHint';
import { Spinner } from '@/components/presentation/Spinner';
import { KolSearchStatus } from '@/enums/kol';
import { useDebounceCallback } from '@/hooks/useDebounce';
import { useIsDemoGroup } from '@/store/selectors';
import type { KolSearchResult } from '@/types/kol';
import { ChatStateDispatchContext } from '../Chat.State/context';
import {
  DispatchKolSearchFormContext,
  FetchMoreResultsContext,
  KolSearchFormContext,
  KolSearchIdentifierContext,
  KolSearchRecommendationProfileSelectedContext,
  KolSearchRecommendationsContext,
  KolSearchResultsFilterContext,
  KolSearchResultsPageSizeContext,
  KolSearchResultsSortContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
  KolSearchTablePageContext,
} from './context';
import { KolSearchInputForm } from './KolSearch.Form';
import { KolResultsTable } from './KolSearch.Table';
import { KolTableContainer } from './KolTableContainer';
import { messageLaneRightPadding, messageLaneWidth, messageLaneLeftPadding } from './Message.styles';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { isFilterValid } from './utils';

export const KolRecommendations = () => {
  const status = useContext(KolSearchStatusContext);
  const selectProfile = useContext(KolSearchRecommendationProfileSelectedContext);
  const isDemoGroup = useIsDemoGroup();

  const recommendations = useContext(KolSearchRecommendationsContext);

  const formState = useContext(KolSearchFormContext);
  const setFormState = useContext(DispatchKolSearchFormContext);
  const inputFocused = useRef(false);
  const handleSubmit = useContext(KolSearchSubmitContext);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  const copy = getCopy(isDemoGroup);

  const disabled = useMemo(() => ({
    profileSelection: status !== KolSearchStatus.ProfileRecommendations,
    search: status !== KolSearchStatus.ProfileRecommendations,
    input: status !== KolSearchStatus.ProfileRecommendations,
    submit: status !== KolSearchStatus.ProfileRecommendations || (!formState.name && !formState.location),
  }), [status, formState.name, formState.location]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {

    if (!inputFocused.current) return;

    if (disabled.submit) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }, [disabled, handleSubmit]);

  const onKolSelect = useCallback((kol: KolSearchResult) => {
    setFormState({ kolId: kol.id, name: kol.name });
    handleSubmit();
  }, [handleSubmit, setFormState]);

  return (
    <MessageContainer>
      <KolTableContainer pageSize={25}>
        <BasicSystemUserMessage bodyStyles={css({
          minWidth: messageLaneWidth,
          maxWidth: messageLaneWidth,
        })}>
          <Body>
            {copy.header}
            {!recommendations || ['uninitialized', 'loading'].includes(recommendations.status)
              ? <StyledSpinner />
              : (
                <RecommendationsTableContainer>
                  <StyledRecommendationsTable
                    infiniteScroll={true}
                    isInteractive={!disabled.profileSelection}
                    smartRoutingDisabled={true}
                    onSelectProfile={selectProfile} />
                </RecommendationsTableContainer>
              )}

            <Divider />
            {copy.alt}

            <StyledForm
              formState={formState}
              inputDisabled={disabled.input}
              onKeyDown={handleKeyDown}
              onInputFocus={handleInputFocus}
              onInputBlur={handleInputBlur}
              onKolSelect={onKolSelect}
              setFormState={setFormState} />
            <StyledSearchQueryHint
              disabled={disabled.submit}
              onClick={handleSubmit}
              value="Search for profiles." />
          </Body>
        </BasicSystemUserMessage>
      </KolTableContainer>
    </MessageContainer>
  );
};

const RecommendationsTableContainer = (props: ChildrenProps) => {
  const dispatch = useContext(ChatStateDispatchContext);
  const searchIdentifier = useContext(KolSearchIdentifierContext);
  const recommendations = useContext(KolSearchRecommendationsContext);

  const pageSize = useContext(KolSearchResultsPageSizeContext);
  const { state: filters } = useContext(KolSearchResultsFilterContext);
  const sortState = useContext(KolSearchResultsSortContext);

  const total = useMemo(() => recommendations.total ?? recommendations.items.length, [recommendations.items, recommendations.total]);

  const fetchMoreRecommendations = useCallback(() => {
    return $api.getKolSearchRecommendations({
      afterKolId: recommendations.items[recommendations.items.length - 1]?.id,
      pageSize,
      filters,
      sortBy: sortState.sortBy,
      sortDir: sortState.sortDir,
    }).then(res => {
      dispatch({
        type: 'kol-search/recommendations-added',
        payload: {
          identifier: searchIdentifier,
          items: res.items,
        },
      });
    });
  }, [dispatch, filters, pageSize, searchIdentifier, sortState.sortBy, sortState.sortDir, recommendations.items]);

  const validFilters = useMemo(() => filters.filter(isFilterValid), [filters]);

  const refetchRecommendations = useCallback(() => {
    // dispatch({
    //   type: 'kol-search/recommendations-loading',
    //   payload: {
    //     identifier: searchIdentifier,
    //   },
    // });
    return $api.getKolSearchRecommendations({
      afterKolId: null,
      pageSize,
      filters: validFilters,
      sortBy: sortState.sortBy,
      sortDir: sortState.sortDir,
    }).then(res => {
      dispatch({
        type: 'kol-search/recommendations-loaded',
        payload: {
          identifier: searchIdentifier,
          items: res.items,
          total: res.total,
        },
      });
    });
    // Note: do not add validFilters to the dependencies array (it causes erroneous requests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(validFilters), pageSize, searchIdentifier, sortState.sortBy, sortState.sortDir]);

  const debouncedFetchRecommendations = useDebounceCallback(refetchRecommendations, 300);

  useEffect(() => {
    debouncedFetchRecommendations();
  }, [debouncedFetchRecommendations]);

  const totalsCtx = useMemo(() => ({
    records: recommendations.items,
    total,
  }), [recommendations.items, total]);

  return (
    <KolSearchTablePageContext.Provider value={totalsCtx}>
      <FetchMoreResultsContext.Provider value={fetchMoreRecommendations}>
        {props.children}
      </FetchMoreResultsContext.Provider>
    </KolSearchTablePageContext.Provider>
  );
};

function getCopy(isDemoGroup: boolean) {
  return {
    header: isDemoGroup
      ? `We recommend following up with the following HCPs, given it has been a while since you last interacted with them.`
      : `These are the potential HCPs that you can engage with in your territory.`,
    alt: `Or if you're looking for a different HCP, get started with an HCP search.`,
  };
}

const MessageContainer = styled.div({
  paddingLeft: messageLaneLeftPadding,
  paddingRight: messageLaneRightPadding,
  // padding: `15px ${messageLaneRightPadding}px 15px ${messageLaneLeftPadding}px`,
});

const Body = styled.div({
  // width: 725,
});

const StyledRecommendationsTable = styled(KolResultsTable)({
  marginTop: 15,
  marginBottom: 15,
});

const StyledSearchQueryHint = styled(SearchQueryHint)({
  marginTop: 15,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const StyledSpinner = styled(Spinner)({
  margin: '50px 0',
});

const StyledForm = styled(KolSearchInputForm)({
  marginTop: 15,
  width: 500,
});