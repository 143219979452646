import { getAuthenticatedClient } from './client';
import type { Projects } from './interfaces';

export * as entities from './projects.entities';

export const fetchProject = (data: Projects.FetchProject.Request) => {
  return getAuthenticatedClient().get<Projects.FetchProject.Response>(`/projects/${data.projectId}`)
    .then(res => ({
      object: res.data.object,
      project: res.data.project,
    }));
};

export const fetchProjectFiles = (data: Projects.FetchProjectFiles.Request) => {
  return getAuthenticatedClient().get<Projects.FetchProjectFiles.Response>(`/projects/${data.projectId}/files`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs,
      items: res.data.items.map(item => ({
        ...item,
        file: item.file ? {
          ...item.file,
          createdOn: new Date(item.file.createdOn),
        } : null,
        folder: item.folder ? {
          ...item.folder,
          createdOn: new Date(item.folder.createdOn),
        } : null,
        object: {
          ...item.object,
          createdOn: new Date(item.object.createdOn),
        },
      })),
    }));
};

export const fetchProjectTags = (data: Projects.FetchProjectTags.Request) => {
  return getAuthenticatedClient().get<Projects.FetchProjectTags.Response>(`/projects/${data.projectId}/tags`)
    .then(res => ({
      tags: res.data.tags,
    }));
};

export const fetchProjectTerms = (data: Projects.FetchProjectTerms.Request) => {
  return getAuthenticatedClient().get<Projects.FetchProjectTerms.Response>(`/projects/${data.projectId}/terms`)
    .then(res => ({
      terms: res.data.terms,
    }));
};

export const fetchProjectTermHighlights = (data: Projects.FetchProjectTermHighlights.Request) => {
  return getAuthenticatedClient().get<Projects.FetchProjectTermHighlights.Response>(`/projects/${data.projectId}/terms/highlights`, {
    params: {
      term: data.term,
      searchAfter: data.searchAfter,
    },
  })
    .then(res => ({
      highlights: res.data.highlights,
      searchAfter: res.data.searchAfter,
      total: res.data.total,
    }));
};