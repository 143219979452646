import { memo } from 'react';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@/utils';
import styles from './style/Button.module.css';

type Props = {
  className?: string;
  count?: number;
  onClick: () => void;
};

export const ObjectAccessButton = memo(({ className, count, onClick }: Props) => {

  return (
    <div className={cx(styles.access, className)}>
      <div className={styles.bg}>
        <button
          className={styles.btn}
          onClick={onClick}>
          <GroupsOutlinedIcon className={styles.icon} />
          {count > 0 &&
            <div className={styles.counter}>{count}</div>}
        </button>
      </div>
    </div>
  );
});

export const SkeletonAccess = memo(() => {
  return (
    <Skeleton
      height={30}
      width={50}
      variant="rounded" />
  );
});