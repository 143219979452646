import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@/components/presentation/Tooltip';

type Props = {
  errorMessage: string;
};

export const BrowserLocationError = ({ errorMessage }: Props) => {
  return (
    <GetLocationError>
      Location sharing failed: {errorMessage} <Tooltip title="Try reaching out to IT for more assistance."><span><StyledIconInfo fontSize={'small'} /></span></Tooltip>
    </GetLocationError>
  );
};

const GetLocationError = styled.div(({ theme }) => ({
  color: theme.palette.red.main,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
}));

const StyledIconInfo = styled(InfoIcon)({
  height: 16,
  width: 16,
});