import type { FetchProjectTerms } from '@/api/interfaces/projects';
import { createNamedContext } from '@/utils';

type EntityTypesContextValue = {
  items: {
    id: string;
    name: string;
  }[];
};

type TransformedProjectTermsContextValue = {
  terms: FetchProjectTerms.Response['terms'];
};

type BoostOverridesContextValue = {
  boostOverrides: Record<string, boolean>;
  setTermBoost: (term: string, boosted: boolean) => void;
};

type WebSocketContextValue = {
  eventKey: string;
  setEventKey: (val: string) => void;
};

type RouteGuardContextValue = {
  shouldSkipGuard: boolean;
  setSkipGuard: () => void;
};

export const TransformedProjectTermsContext = createNamedContext<TransformedProjectTermsContextValue>(undefined, 'Project.Terms.Transformed');
export const BoostOverridesContext = createNamedContext<BoostOverridesContextValue>(undefined, 'Project.Terms.BoostOverrides');
export const EntityTypesContext = createNamedContext<EntityTypesContextValue>(undefined, 'Entity.Types.Context');
export const WebSocketContext = createNamedContext<WebSocketContextValue>(undefined, 'Project.Terms.Websocket');
export const RouteGuardContext = createNamedContext<RouteGuardContextValue>(undefined, 'Project.Terms.RouteGuard');