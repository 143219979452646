import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { getAuthProvider } from '@/auth/auth-provider';
import type { AuthProviderEvent } from '@/auth/auth-provider.base';
import Button from '@/components/presentation/Button';
import { AppInitializing } from '@/screens/Initializing';
import { actions } from '@/store/actions';

export function VeevaLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string>(null);

  const handleRetry = useCallback(() => {
    const $provider = getAuthProvider();
    $provider.clearAuthAttempts();
    window.location.reload();
  }, []);

  const handleAuthEvent = useCallback((e: AuthProviderEvent) => {
    if (e.name === 'auth/login') {
      dispatch(actions.authChange());
      navigate('/');
    }
    else if (e.name === 'auth/error') {
      setError(`We're sorry, we couldn't authenticate!`);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const $provider = getAuthProvider();
    if ($provider.authAttempts > 1) {
      setError(`We're sorry, we couldn't authenticate!`);
    } else {
      const $provider = getAuthProvider();
      $provider.incrementAuthAttempts();
      $provider.addListener(handleAuthEvent);
      $provider.authenticate({
        authSource: 'jwt',
      });
      return () => {
        $provider.removeListener(handleAuthEvent);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return error
    ? (
      <ErrorContainer>
        <ErrorMessage>{error}</ErrorMessage>
        <RetryButton onClick={handleRetry}>Try Again!</RetryButton>
      </ErrorContainer>
    )
    : <AppInitializing />;
}

const ErrorContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - var(--navbar-height))`,
});

const ErrorMessage = styled.div(props => ({
  fontSize: 18,
  fontFamily: props.theme.fonts.bold,
}));

const RetryButton = styled(Button.Secondary)({
  marginTop: '20px',
});