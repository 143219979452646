import { useCallback, useContext, useReducer } from 'react';
import styled from '@emotion/styled';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useMutation } from '@tanstack/react-query';
import { setDate, setMilliseconds, setMinutes, setSeconds, startOfHour } from 'date-fns';
import { sendHcpSchedulingEmail } from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { MessageQueryHint } from '@/components/presentation';
import { Textarea } from '@/components/presentation/Textarea';
import { SelectLocation } from '@/components/Search/Location';
import Toast from '@/components/Toast';
import { ChatHintType } from '@/enums';
import type { Chat } from '@/types/chat';
import type { LocationItem } from '@/types/location';
import { HcpEmailMultiselect } from './Hcp.Multiselect';

type Props = {
  queryId: number;
  state: Chat.QueryState.SchedulingAssistant;
};

export const SchedulingAssistantResponse = ({ queryId, state }: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);

  const submitMutation = useMutation({
    mutationFn: (event: Chat.QueryState.SchedulingAssistant['event']) => {
      return sendHcpSchedulingEmail({
        queryId,
        event,
      });
    },
    onSuccess: res => {
      dispatch({
        type: 'query-updated',
        payload: {
          queryIdentifier: res.query.identifier,
          query: {
            state: res.query.state,
          },
        },
      });
      Toast.success({
        title: `Invite sent to Hcp!`,
      });
    },
    onError: () => {
      Toast.error({
        title: `Sorry, we ran into an error trying to send your email!`,
      });
    },
  });

  switch (state.status) {
    case 'form-entry':
      return (
        <SchedulingAssistantForm
          onSubmit={submitMutation.mutateAsync}
          event={state.event} />
      );
    case 'form-submitted':
      return null;
    case 'form-review':
      return null;
  }
};

type FormProps = {
  event: Chat.QueryState.SchedulingAssistant['event'];
  onSubmit: (state: Chat.QueryState.SchedulingAssistant['event']) => void;
};

/*
  TODO:

  Review Screen
  Submitted Screen
  Submit Buttons + action
  Implement Email Templates
  Validations

*/

const SchedulingAssistantForm = ({ event, onSubmit }: FormProps) => {

  const [state, dispatch] = useReducer(schedulingReducer, buildInitialState(event));

  const copy = 'Sure, input the following details to send a site visit calendar invitation.';

  const handleSubmit = useCallback(() => {
    onSubmit(state);
  }, [state, onSubmit]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Root>
        <div>{copy}</div>
        <Divider />
        <DateRow>
          <div>
            <Label>Date</Label>
            <StyledDatePicker
              disablePast
              onChange={(newValue: Date) => {
                dispatch({ type: 'event-date-updated', value: newValue });
              }}
              value={state.date}
            />
          </div>
          <TimesRow>
            <div>
              <Label>Start Time</Label>
              <StyledTimePicker
                // disablePast
                onChange={(newValue: Date) => {
                  dispatch({ type: 'event-start-time-updated', value: newValue });
                }}
                value={state.startTime}
              />
            </div>
            <div>
              <Label>End Time</Label>
              <StyledTimePicker
                // disablePast
                onChange={(newValue: Date) => {
                  dispatch({ type: 'event-end-time-updated', value: newValue });
                }}
                value={state.endTime}
              />
            </div>
          </TimesRow>
        </DateRow>
        <Row>
          <Label>Send To</Label>
          <HcpEmailMultiselect
            value={state.to}
            onSelect={value => {
              dispatch({
                type: 'event-send-to-updated', value,
              });
            }} />
        </Row>
        <Row>
          <Label>Location</Label>
          <SelectLocation
            styles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: 16,
              }),
              control: (provided, _state) => ({
                ...provided,
                borderWidth: 2,
                borderRadius: 7,
              }),
            }}
            value={state.location}
            onChange={value => {
              dispatch({ type: 'event-location-updated', value });
            }} />
        </Row>
        <Row>
          <Label>Event Title</Label>
          <Textarea
            placeholder="No Title"
            value={state.title}
            onChange={e => {
              dispatch({ type: 'event-title-updated', value: e.target.value });
            }} />
        </Row>
        <Row>
          <Label>Event Description</Label>
          <Textarea
            minRows={5}
            placeholder="Add description"
            value={state.description}
            onChange={e => {
              dispatch({ type: 'event-description-updated', value: e.target.value });
            }} />
        </Row>
        <Row>
          <MessageQueryHint
            hint={{
              type: ChatHintType.Text,
              displayValue: 'Send Email',
            }}
            onClick={handleSubmit} />
        </Row>
      </Root>
    </LocalizationProvider>
  );
};

function buildInitialState(event: Chat.QueryState.SchedulingAssistant['event']): State {
  const dt = getNextHalfHour();

  return {
    ...event,
    startTime: dt,
    endTime: getNextHalfHour(dt),
    date: dt,
  };
}

const getNextHalfHour = (now = new Date()) => {
  const roundedToNextHalfHour = startOfHour(now);

  const currentMinutes = now.getMinutes();

  const nextHalfHourMinutes = currentMinutes < 30 ? 30 : 60;

  const nextHalfHour = setMinutes(roundedToNextHalfHour, nextHalfHourMinutes);

  const nextHalfHourStart = setMilliseconds(setSeconds(nextHalfHour, 0), 0);

  return nextHalfHourStart;
};

function schedulingReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'event-description-updated':
      return {
        ...state,
        description: action.value,
      };
    case 'event-date-updated':
      return {
        ...state,
        date: action.value,
        startTime: setDate(state.startTime, action.value.getDate()),
        endTime: setDate(state.endTime, action.value.getDate()),
      };
    case 'event-start-time-updated':
      return {
        ...state,
        startTime: action.value,
      };
    case 'event-end-time-updated':
      return {
        ...state,
        endTime: action.value,
      };
    case 'event-title-updated':
      return {
        ...state,
        title: action.value,
      };
    case 'event-send-to-updated':
      return {
        ...state,
        to: action.value,
      };
    case 'event-location-updated':
      return {
        ...state,
        location: action.value,
      };
    default:
      return state;
  }
}

type State = Chat.QueryState.SchedulingAssistant['event'];
type Action =
  EventDescriptionUpdatedAction |
  EventDateUpdatedAction |
  EventStartTimeUpdatedAction |
  EventEndTimeUpdatedAction |
  EventTitleUpdatedAction |
  EventSendToUpdatedAction |
  EventLocationUpdatedAction
  ;

type EventDescriptionUpdatedAction = {
  type: 'event-description-updated';
  value: string;
};
type EventDateUpdatedAction = {
  type: 'event-date-updated';
  value: Date;
};
type EventStartTimeUpdatedAction = {
  type: 'event-start-time-updated';
  value: Date;
};
type EventEndTimeUpdatedAction = {
  type: 'event-end-time-updated';
  value: Date;
};
type EventTitleUpdatedAction = {
  type: 'event-title-updated';
  value: string;
};
type EventSendToUpdatedAction = {
  type: 'event-send-to-updated';
  value: State['to'];
};
type EventLocationUpdatedAction = {
  type: 'event-location-updated';
  value: LocationItem | null;
};

const Root = styled.div({
  width: 550,
  maxWidth: 550,
});

const StyledDatePicker = styled(DatePicker)({
  width: 200,
  [`& .MuiInputBase-input`]: {
    padding: `8px`,
    fontFamily: 'inherit',
    fontSize: 16,
    appearance: 'none',
  },
  [`& fieldset`]: {
    borderWidth: 2,
    borderRadius: 7,
  },
});
// TODO: change border styles on hover
const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  width: 150,
  borderWidth: '2px',
  [`& .MuiInputBase-input`]: {
    padding: `8px`,
    fontFamily: 'inherit',
    fontSize: 16,
    appearance: 'none',
  },

  [`& fieldset`]: {
    borderWidth: 2,
    borderRadius: 7,
  },
}));

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const DateRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TimesRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 15,
  marginLeft: 20,
});

const Label = styled.div(({ theme }) => ({
  marginBottom: 10,
  fontFamily: theme.fonts.semiBold,
}));

const Row = styled.div({
  marginTop: 15,
});