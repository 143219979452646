import { useContext, useMemo } from 'react';
import { SelectedTermsContext } from '@/containers/Project.Terms';
import { TransformedProjectTermsContext } from '../Context';

export const useSelectedTerms = () => {
  const { selectedTermValues } = useContext(SelectedTermsContext);
  const { terms } = useContext(TransformedProjectTermsContext);

  return useMemo(() => {
    return selectedTermValues.map(t => terms.find(t2 => t2.termValue === t));
  }, [selectedTermValues, terms]);
};