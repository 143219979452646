import { useCallback, useContext, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import * as $api from '@/api/kol';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { KolSearchStatus } from '@/enums/kol';
import type { Chat } from '@/types';
import type { KolSearchRecommendation } from '@/types/kol';
import {
  DispatchKolSearchFormContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
  KolSearchFormContext,
  KolSearchResultsContext,
  KolSearchCancelContext,
  ActiveChatSessionContext,
  KolProfileContext,
  UpdateKolSearchProfileContext,
  KolSearchErrorTraceIdContext,
  KolFollowupQuestionsContext,
  KolSearchFormBeginContext,
  KolSearchRecommendationsContext,
  KolSearchRecommendationProfileSelectedContext,
  KolResetSearchContext,
  CitationsContext,
  KolSearchIdentifierContext,
  KolSearchIdContext,
} from './context';
import { useScrollToBottomOfMessages } from './hooks';
import type { Context } from './interfaces';

type Props = {
  item: Chat.KolSearchItem;
} & ChildrenProps;

type UpdateProfileParams = {
  kolId: number;
  kolSearchId: number;
};

export const KolSearchContainer = (props: Props) => {

  const chat = useContext(ActiveChatSessionContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();

  const updateProfileMutation = useMutation({
    mutationFn: ({ kolId, kolSearchId }: UpdateProfileParams) => {
      return $api.updateKolSearchProfile({
        chatIdentifier: chat.identifier,
        kolId,
        kolSearchId,
      });
    },
    onMutate: () => {
      dispatch({
        type: 'kol-search-profile-selected',
        payload: {
          identifier: props.item.identifier,
        },
      });
      scrollToBottom({ nthFromBottom: 1 });
    },
    onError: err => {
      const error = err as AxiosError;
      const traceId = error.response?.headers?.['X-Sentry-Trace-Id'] as string;
      dispatch({
        type: 'kol-search-profile-selection-errored',
        payload: {
          identifier: props.item.identifier,
          traceId,
        },
      });
    },
    onSuccess: res => {
      setTimeout(() => {
        dispatch({
          type: 'kol-search-profile-available',
          payload: {
            chatInstance: res.chatInstance,
            result: res.result,
            identifier: props.item.identifier,
          },
        });
        scrollToBottom({ nthFromBottom: 1 });
      }, 1000);
    },
  });

  const submitFormMutation = useMutation({
    mutationFn: () => {
      return $api.submitKolSearch({
        chatIdentifier: chat.identifier,
        identifier: props.item.identifier,
        form: props.item.form,
        recommendations: props.item.recommendations,
      });
    },
    onMutate: () => {
      dispatch({
        type: 'kol-search-submitted',
        payload: {
          chatIdentifier: chat.identifier,
          identifier: props.item.identifier,
        },
      });
      scrollToBottom({ nthFromBottom: 1 });
    },
    onError: err => {
      const error = err as AxiosError;
      const traceId = error.response?.headers?.['X-Sentry-Trace-Id'] as string;
      dispatch({
        type: 'kol-search-form-submission-errored',
        payload: {
          identifier: props.item.identifier,
          traceId,
        },
      });
    },
    onSuccess: res => {
      dispatch({
        type: 'kol-search-results-available',
        payload: {
          chatInstance: res.chatInstance,
          result: res.result,
          identifier: props.item.identifier,
        },
      });

      if (res.result.status === KolSearchStatus.SingleResultReturned) {
        return updateProfileMutation.mutateAsync({
          kolSearchId: res.result.id,
          kolId: res.result.results[0].id,
        });
      }

      scrollToBottom({ nthFromBottom: 1 });
    },
  });

  const handleFormSubmit = useCallback(() => {
    submitFormMutation.mutateAsync();
  }, [submitFormMutation]);

  const cancelMutation = useMutation({
    mutationFn: () => $api.cancelKolSearch({
      chatIdentifier: chat.identifier,
      kolSearchId: props.item.id,
    }),
    onSuccess: () => {
      dispatch({
        type: 'kol-search-cancelled',
        payload: {
          identifier: props.item.identifier,
        },
      });
      scrollToBottom({ nthFromBottom: 1 });
    },
  });

  const handleSearchCancelled = useCallback(() => {
    cancelMutation.mutateAsync();
  }, [cancelMutation]);

  const handleProfileSelected = useCallback((data: { id: number; name: string }) => {
    updateProfileMutation.mutateAsync({
      kolId: data.id,
      kolSearchId: props.item.id,
    });
  }, [props.item.id, updateProfileMutation]);

  const handleFormUpdated = useCallback((form: Partial<Context.KolSearchFormState>) => {
    dispatch({
      type: 'kol-search-form-updated',
      payload: {
        form,
        identifier: props.item.identifier,
      },
    });
  }, [dispatch, props.item.identifier]);

  const handleFormBegin = useCallback(() => {
    dispatch({
      type: 'kol-search-form-begin',
      payload: {
        identifier: props.item.identifier,
      },
    });
    scrollToBottom({ nthFromBottom: 1 });
  }, [dispatch, scrollToBottom, props.item.identifier]);

  const handleRecommendationProfileSelected = useCallback(async (kol: KolSearchRecommendation) => {
    const search = await $api.submitKolSearch({
      chatIdentifier: chat.identifier,
      identifier: props.item.identifier,
      form: {
        kolId: kol.id,
        name: kol.name,
      },
      recommendations: {
        status: 'loaded',
        items: props.item.recommendations.items,
        total: props.item.recommendations.total,
      },
    });

    dispatch({
      type: 'kol-search-profile-available',
      payload: {
        chatInstance: search.chatInstance,
        result: search.result,
        identifier: props.item.identifier,
      },
    });

    await updateProfileMutation.mutateAsync({
      kolSearchId: search.result.id,
      kolId: search.result.results[0].id,
    });
  }, [chat.identifier, props.item.identifier, props.item.recommendations.items, props.item.recommendations.total, dispatch, updateProfileMutation]);

  const handleNewSearch = useCallback(() => {
    dispatch({
      type: 'kol-search/new-search-initiated',
      payload: {
        oldIdentifier: props.item.identifier,
      },
    });
    scrollToBottom({ nthFromBottom: 1 });
  }, [dispatch, props.item.identifier, scrollToBottom]);

  const getRecommendationsMutation = useMutation({
    mutationFn: () => $api.getKolSearchRecommendations({
      afterKolId: null,
      pageSize: 25,
      filters: [],
      sortBy: null,
      sortDir: null,
    }),
    onMutate: () => {
      dispatch({
        type: 'kol-search/recommendations-loading',
        payload: {
          identifier: props.item.identifier,
        },
      });
    },
    onSettled: (res, _error, _vars) => {
      dispatch({
        type: 'kol-search/recommendations-loaded',
        payload: {
          identifier: props.item.identifier,
          items: res.items,
          total: res.total,
        },
      });
      scrollToBottom({ nthFromBottom: 1 });
    },
  });

  useEffect(() => {
    if (!getRecommendationsMutation.isLoading && (!props.item.recommendations || props.item.recommendations.status === 'uninitialized')) {
      getRecommendationsMutation.mutateAsync();
    }
  }, [props.item.recommendations, getRecommendationsMutation]);

  return (
    <UpdateKolSearchProfileContext.Provider value={handleProfileSelected}>
      <KolProfileContext.Provider value={props.item.profile}>
        <KolSearchCancelContext.Provider value={handleSearchCancelled}>
          <KolSearchFormContext.Provider value={props.item.form}>
            <DispatchKolSearchFormContext.Provider value={handleFormUpdated}>
              <KolSearchFormBeginContext.Provider value={handleFormBegin}>
                <KolSearchStatusContext.Provider value={props.item.status}>
                  <KolSearchSubmitContext.Provider value={handleFormSubmit}>
                    <KolSearchResultsContext.Provider value={props.item.results}>
                      <KolSearchErrorTraceIdContext.Provider value={props.item.errorTraceId}>
                        <KolFollowupQuestionsContext.Provider value={props.item.followupQuestions}>
                          <KolSearchRecommendationProfileSelectedContext.Provider value={handleRecommendationProfileSelected}>
                            <KolSearchRecommendationsContext.Provider value={props.item.recommendations}>
                              <KolResetSearchContext.Provider value={handleNewSearch}>
                                <CitationsContext.Provider value={props.item.citations}>
                                  <KolSearchIdentifierContext.Provider value={props.item.identifier}>
                                    <KolSearchIdContext.Provider value={props.item.id}>
                                      {props.children}
                                    </KolSearchIdContext.Provider>
                                  </KolSearchIdentifierContext.Provider>
                                </CitationsContext.Provider>
                              </KolResetSearchContext.Provider>
                            </KolSearchRecommendationsContext.Provider>
                          </KolSearchRecommendationProfileSelectedContext.Provider>
                        </KolFollowupQuestionsContext.Provider>
                      </KolSearchErrorTraceIdContext.Provider>
                    </KolSearchResultsContext.Provider>
                  </KolSearchSubmitContext.Provider>
                </KolSearchStatusContext.Provider>
              </KolSearchFormBeginContext.Provider>
            </DispatchKolSearchFormContext.Provider>
          </KolSearchFormContext.Provider>
        </KolSearchCancelContext.Provider>
      </KolProfileContext.Provider>
    </UpdateKolSearchProfileContext.Provider>
  );
};
