import { memo, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { createId } from '@paralleldrive/cuid2';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { AnalysisQueryHint } from '@/components/presentation';
import { KolSearchStatus } from '@/enums/kol';
import { KolSearchErrorTraceIdContext, KolSearchStatusContext } from './context';
import { useIsLastKolSearch } from './hooks/useIsLastKolSearch';
import { ErrorTrace } from './Message.ErrorTrace';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { MessageWrapper } from './Message.System.Wrapper';

export const SearchErrorMessage = memo(() => {
  const traceId = useContext(KolSearchErrorTraceIdContext);
  const copy = `I'm sorry, there was an error while searching for HCPs. Please try again.`;
  return (
    <MessageWrapper>
      <BasicSystemUserMessage>
        {copy}
        {traceId && <ErrorTrace traceId={traceId} />}
        <StartNewSearch />
      </BasicSystemUserMessage>
    </MessageWrapper>
  );
});

export const ProfileSelectionErrorMessage = memo(() => {
  const traceId = useContext(KolSearchErrorTraceIdContext);
  const copy = `I'm sorry, there was an error while selecting an HCP. Please try again.`;

  return (
    <MessageWrapper>
      <BasicSystemUserMessage>
        {copy}
        {traceId && <ErrorTrace traceId={traceId} />}
        <StartNewSearch />
      </BasicSystemUserMessage>
    </MessageWrapper>
  );
});

const StartNewSearch = memo(() => {
  const status = useContext(KolSearchStatusContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const isLastSearch = useIsLastKolSearch();

  const disabled = useMemo(() => {
    return status !== KolSearchStatus.ProfileSelectionErrored || !isLastSearch;
  }, [isLastSearch, status]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'kol-search-added',
      payload: {
        item: {
          id: null,
          createdOn: new Date(),
          status: KolSearchStatus.FormEntry,
          identifier: createId(),
          followupQuestions: [],
          form: {
            name: '',
          },
          results: null,
          profile: null,
          recommendations: {
            status: 'skipped',
            items: [],
            total: 0,
          },
          citations: [],
        },
      },
    });
  }, [dispatch]);

  return (
    <StyledAnalysisQueryHint
      disabled={disabled}
      onClick={handleSubmit}
      value="Start a new search." />
  );
});

const StyledAnalysisQueryHint = styled(AnalysisQueryHint)({
  marginTop: 15,
});