import type { Chat } from '@/types';
import { safeJsonDate, transformDates } from './date';

export function transformChatQuery(data: Chat.Query<string>): Chat.Query {
  return {
    ...data,
    state: transformDates(data.state),
    citations: data.citations.map<Chat.Citation>(citation => transformDates(citation) as Chat.Citation),
    createdOn: safeJsonDate(data.createdOn),
  };
}