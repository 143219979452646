import { memo, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { WorkspaceFilePreviewLoadingContext, WorkspaceFileStateContext } from '@/containers/Workspace.File.Preview/Context';
import { Access } from './Header.Access';
import styles from './style/Header.Actions.module.css';

type Props = unknown;

export const Actions = (props: Props) => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  return isLoading
    ? <SkeletonActions />
    : <InteractiveActions />;
};

Actions.displayName = 'File.Actions';

const InteractiveActions = (props: Props) => {

  return (
    <div className={styles.root}>
      <Access />
    </div>
  );
};

InteractiveActions.displayName = 'File.Actions.Interactive';

const SkeletonActions = memo((props: Props) => {
  return (
    <div className={styles.actions}>
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
    </div>
  );
});

SkeletonActions.displayName = 'File.Actions.Skeleton';