/** @jsxImportSource @emotion/react */
import type { MouseEvent } from 'react';
import { useMemo, useContext, useCallback, memo } from 'react';
import styled from '@emotion/styled';
import { useFloating, useInteractions, useDismiss, autoUpdate } from '@floating-ui/react';
import { createId } from '@paralleldrive/cuid2';
import { ArrowDown, ArrowUp, ExternalLink, Filter } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TextQueryHint } from '@/components/presentation/Message.QueryHint';
import { Tooltip } from '@/components/presentation/Tooltip';
import { isDemoEnv } from '@/config/utils';
import { useTablePaginationHelpers } from '@/hooks/useTablePagination';
import { useHasSmartRouting } from '@/store/selectors';
import type { KolSearchResult, KolSearchRecommendation } from '@/types/kol';
import { buildKolMailTo } from '@/utils';
import { createOneTapLink, formatPhoneNumber } from '@/utils/phone';
import { Checkbox, TablePagination } from '../presentation';
import {
  KolSearchResultsSortContext,
  KolSearchResultsFilterContext,
  KolSearchResultsSelectionContext,
  KolSearchResultsPageSizeContext,
  KolSearchResultsPageIndexContext,
  KolSearchResultsSetPageIndexContext,
  KolSearchTablePageContext,
  FetchMoreResultsContext,
} from './context';
import type { SortFnMap } from './hooks';
import { useFilteredItems, useSortedItems, useIsSmartRouting } from './hooks';
import type { KolSearchResultSortKey, StringAccessorFnMap } from './interfaces';
import { KolAddress } from './KolSearch.Address';
import { KolBadgeTooltip } from './KolSearch.KolBadge';
import { KolRecommendationTooltip } from './KolSearch.Recommended';
import { FilterPanel } from './KolSearch.Table.Filters';
import { areFiltersSimilar, isFilterValid } from './utils';

type Props = {
  className?: string;
  onSelectProfile: (kol: Pick<KolSearchRecommendation, 'id' | 'name'>) => void;
  isInteractive?: boolean;
  infiniteScroll?: boolean;
  smartRoutingDisabled?: boolean;
};

type PaginationContainerProps = {
  records: KolSearchRecommendation[];
} & ChildrenProps;

export const KolResultsTablePaginationContainer = (props: PaginationContainerProps) => {

  const {
    state: filterState,
  } = useContext(KolSearchResultsFilterContext);
  const filteredRecords = useFilteredItems({
    items: props.records,
    accessorFnMap,
    filters: filterState,
  });
  const sortState = useContext(KolSearchResultsSortContext);
  const records = useSortedItems({
    sortBy: sortState.sortBy,
    direction: sortState.sortDir,
    sortFns,
    items: filteredRecords,
  });
  const pageSize = useContext(KolSearchResultsPageSizeContext);
  const pageIndex = useContext(KolSearchResultsPageIndexContext);

  const page = useMemo(() => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;

    const items = (records || []);
    return items.slice(start, end);
  }, [
    records,
    pageIndex,
    pageSize,
  ]);

  const ctx = useMemo(() => ({
    records: page,
    total: records.length,
  }), [page, records]);

  return (
    <KolSearchTablePageContext.Provider value={ctx}>
      {props.children}
    </KolSearchTablePageContext.Provider>
  );
};

export const KolResultsTable = ({
  className,
  onSelectProfile,
  isInteractive,
  infiniteScroll = false,
  smartRoutingDisabled = false,
}: Props) => {
  const pageSize = useContext(KolSearchResultsPageSizeContext);
  const pageIndex = useContext(KolSearchResultsPageIndexContext);
  const setPageIndex = useContext(KolSearchResultsSetPageIndexContext);

  const isSmartRouting = useIsSmartRouting();

  const allowSelection = useMemo(() => isSmartRouting && !smartRoutingDisabled, [isSmartRouting, smartRoutingDisabled]);

  const columnWidths = useMemo(() => [allowSelection ? 60 : null, 150, 85, 190, 190, 175, 150].filter(Boolean), [allowSelection]);

  const {
    records,
    total,
  } = useContext(KolSearchTablePageContext);

  const {
    state: filterState,
    open: filtersOpen,
    setOpen: setFiltersOpen,
  } = useContext(KolSearchResultsFilterContext);

  const {
    selected: selectedKols,
    toggle: toggleKolSelection,
  } = useContext(KolSearchResultsSelectionContext);
  const { refs, floatingStyles, context } = useFloating({
    open: filtersOpen,
    onOpenChange: setFiltersOpen,
    placement: 'top-start',
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const hasActiveFilters = useMemo(() => filterState.some(f => isFilterValid(f)), [filterState]);
  const hasSmartRouting = useHasSmartRouting();
  const showDistanceCol = useMemo(() => hasSmartRouting && records.some(r => !!r.distance), [hasSmartRouting, records]);

  const rows = useMemo(() => {
    return records.map((result, index) => {
      const primaryAfilliation = result.affiliations[0];
      return (
        <FlexibleItemContainer key={index} columns={columnWidths}>
          {allowSelection &&
            <CheckboxAttributeContainer>
              <SelectAttribute>
                <Checkbox
                  checked={selectedKols.includes(result.id)}
                  onChange={() => toggleKolSelection(result.id)}
                />
              </SelectAttribute>
            </CheckboxAttributeContainer>
          }
          <NameAttributeContainer>
            <NameAttribute>
              {result.name}
              <Badges>
                {result.isKol && <KolBadgeTooltip />}
                {!!result.recommendations?.length && !isDemoEnv() && <KolRecommendationTooltip values={result.recommendations} />}
              </Badges>
            </NameAttribute>
          </NameAttributeContainer>
          <ViewProfileAttributeContainer>
            <ViewProfileAttribute>
              <StyledViewProfileButton
                disabled={!isInteractive}
                onClick={() => onSelectProfile(result)}
                value="View" />
            </ViewProfileAttribute>
          </ViewProfileAttributeContainer>
          <AffiliationAttributeContainer>
            <AffiliationAttribute>
              {primaryAfilliation ? (
                <Tooltip
                  title={primaryAfilliation.name}
                  enterDelay={1000}
                  enterNextDelay={500}>
                  <div css={{ overflow: 'hidden' }}>{primaryAfilliation.name}</div>
                </Tooltip>
              ) : (
                <PlaceholderText>None</PlaceholderText>
              )}
            </AffiliationAttribute>
          </AffiliationAttributeContainer>
          <AddressAttributeContainer>
            <AddressAttribute>
              <KolAddress
                city={result.city}
                state={result.state}
                zip={result.zipCode}
                country={result.country}
                street={result.streetAddress} />
            </AddressAttribute>
          </AddressAttributeContainer>
          <EmailAttributeContainer>
            <EmailAttribute>
              {result.email ? (
                <Tooltip
                  title={result.email}
                  enterDelay={1000}
                  enterNextDelay={500}>
                  <EmailMailTo
                    href={buildKolMailTo({ email: result.email })}
                    target="_blank"
                    rel="noreferrer">
                    <EmailLinkText>{result.email}</EmailLinkText>
                    <StyledLinkIcon size={14} />
                  </EmailMailTo>
                </Tooltip>
              ) : (
                <PlaceholderText>None</PlaceholderText>
              )}
            </EmailAttribute>
          </EmailAttributeContainer>
          {showDistanceCol ?
            <PhoneAttributeContainer>
              <Attribute>
                {result.distance ? `${result.distance.toFixed(2)} mi` : 'N/A'}
              </Attribute>
            </PhoneAttributeContainer> :
            <PhoneAttributeContainer>
              <Attribute>
                {result.phone ? (
                  <EmailMailTo
                    href={createOneTapLink(result.phone)}
                    target="_blank"
                    rel="noreferrer">
                    {formatPhoneNumber(result.phone)}
                  </EmailMailTo>
                ) : (
                  <PlaceholderText>None</PlaceholderText>
                )}
              </Attribute>
            </PhoneAttributeContainer>
          }
        </FlexibleItemContainer>
      );
    });
  }, [records, columnWidths, allowSelection, selectedKols, isInteractive, showDistanceCol, toggleKolSelection, onSelectProfile]);

  const fetchMoreData = useContext(FetchMoreResultsContext);

  const renderHeaderAttribute = useCallback((props: HeaderAttributeProps) => {
    return (
      <HeaderAttribute {...props} />
    );
  }, []);

  return (
    <>
      <Container className={className}>
        <HorizontalScrollContainer>
          <InnerContainer>
            <HeaderContainer ref={refs.setReference} {...getReferenceProps()} columns={columnWidths}>
              {allowSelection &&
                <CheckboxAttributeContainer>
                  <Attribute>Select</Attribute>
                </CheckboxAttributeContainer>
              }
              <NameAttributeContainer>
                {renderHeaderAttribute({
                  children: 'Name',
                  disabled: !isInteractive,
                  sortKey: 'name',
                })}
              </NameAttributeContainer>
              <ViewProfileAttributeContainer>
                <Attribute>Profile</Attribute>
              </ViewProfileAttributeContainer>
              <AffiliationAttributeContainer>
                {renderHeaderAttribute({
                  children: 'Primary Affiliation',
                  disabled: !isInteractive,
                  sortKey: 'affiliation',
                })}
              </AffiliationAttributeContainer>
              <AddressAttributeContainer>
                {renderHeaderAttribute({
                  children: 'Location',
                  disabled: !isInteractive,
                  sortKey: 'location',
                })}
              </AddressAttributeContainer>
              <EmailAttributeContainer>
                {renderHeaderAttribute({
                  children: 'Email',
                  disabled: !isInteractive,
                  sortKey: 'email',
                })}
              </EmailAttributeContainer>

              {showDistanceCol ?
                <PhoneAttributeContainer>
                  {renderHeaderAttribute({
                    children: 'Distance',
                    disabled: !isInteractive,
                    sortKey: 'distance',
                  })}
                </PhoneAttributeContainer> :
                <PhoneAttributeContainer>
                  {renderHeaderAttribute({
                    children: 'Phone',
                    disabled: !isInteractive,
                    sortKey: 'phone',
                  })}
                </PhoneAttributeContainer>
              }
            </HeaderContainer>
            {!records.length && hasActiveFilters &&
              <NoResultsContainer>
                No results match your filters. Please try adjusting your filters to see more results.
              </NoResultsContainer>}
            <StyledInfiniteScroll
              height={300}
              dataLength={records.length}
              next={fetchMoreData}
              hasMore={isInteractive && infiniteScroll && records.length < total}
              loader={<LoadingMessage>Loading...</LoadingMessage>}>
              {rows}
            </StyledInfiniteScroll>
          </InnerContainer>
        </HorizontalScrollContainer>
        {infiniteScroll
          ? <InfiniteScrollFooter showing={records.length} total={total} />
          : (
            <PaginationFooter
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              total={total} />
          )
        }
      </Container>
      {filtersOpen &&
        <FilterPanelContainer ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <FilterPanel />
        </FilterPanelContainer>}
    </>
  );
};

const StyledInfiniteScroll = styled(InfiniteScroll)({
  backgroundColor: 'white',
  direction: 'rtl',

  '& > div': {
    direction: 'ltr',
  },
});

type PaginationFooterProps = {
  pageIndex: number;
  pageSize: number;
  setPageIndex: (pageIndex: number) => void;
  total: number;
};

const PaginationFooter = memo(({ pageIndex, pageSize, setPageIndex, total }: PaginationFooterProps) => {

  const {
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTablePaginationHelpers({
    pageIndex,
    pageSize,
    setPageIndex,
    total,
  });

  if (total < pageSize) {
    return null;
  }

  return (
    <Footer>
      <TablePagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={total} />
    </Footer>
  );
});

type InfiniteScrollFooterProps = {
  showing: number;
  total: number;
};

const InfiniteScrollFooter = memo(({ showing, total }: InfiniteScrollFooterProps) => {

  return (
    <Footer>
      <FooterText>
        Showing {showing} of {total} results
      </FooterText>
    </Footer>
  );
});

const LoadingMessage = styled.div({
  padding: 8,
});

const FooterText = styled.div(({ theme }) => ({
  color: theme.palette.black.light1,
}));

const Badges = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 5,
});

const Container = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,
}));

const HorizontalScrollContainer = styled.div({
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const InnerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  backgroundColor: theme.palette.gray.light2,
}));

type ColumnsProps = { columns: number[] };
const FlexibleItemContainer = styled.div<ColumnsProps>(({ columns }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: columns.map(c => `${c}px`).join(' '),
  width: '100%',
}));

const AttributeContainer = styled.div({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
});

const HeaderContainer = styled(FlexibleItemContainer)(({ columns, theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: columns.map(c => `${c}px`).join(' '),
  flexShrink: 0,

  [`& > *`]: {
    backgroundColor: theme.palette.gray.light2,
    fontFamily: theme.fonts.semiBold,
    fontSize: 14,
  },
}));

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const StyledLinkIcon = styled(ExternalLink)(({ theme }) => ({
  color: theme.palette.hyperlink,
  transition: 'all 150ms',
  flexShrink: 0,
  marginLeft: 4,
}));

const CheckboxAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 60,
});

const NameAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 150,
});

const AffiliationAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 190,
});

const AddressAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 190,
});

const EmailAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 175,
});

const PhoneAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 150,
});

const ViewProfileAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 75,
});

const ViewProfileAttribute = styled(Attribute)({
  display: 'flex',
  justifyContent: 'center',
});

const SelectAttribute = styled(Attribute)({
  display: 'flex',
  justifyContent: 'center',
});

const StyledViewProfileButton = styled(TextQueryHint)({
  minWidth: 75,
  maxWidth: 75,
});

const NameAttribute = styled(Attribute)({

});

const AffiliationAttribute = styled(Attribute)({
  display: 'flex',
  overflow: 'hidden',
  // height: '3.7em',
});

const AddressAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  // height: '3.7em',
});

const EmailAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  // height: '3.7em',
});

const SortableAttribute = styled(Attribute)({
  cursor: 'pointer',
  display: 'flex',
  gap: 5,
  userSelect: 'none',
});

const EmailMailTo = styled.a(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  overflow: 'hidden',
  height: '100%',

  ':hover': {
    color: theme.palette.hyperlink,
  },

  [`> ${String(StyledLinkIcon)}`]: {
    opacity: 0,
  },

  [`:hover > ${String(StyledLinkIcon)}`]: {
    opacity: 1,
  },
}));

const EmailLinkText = styled.div({
  height: '100%',
  wordBreak: 'break-all',
});

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 10,
  height: 34,
  boxSizing: 'border-box',
});

const PlaceholderText = styled.div(({ theme }) => ({
  color: theme.palette.gray.main,
}));

const FilterPanelContainer = styled.div({
  padding: 10,
  backgroundColor: 'white',
  transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  borderRadius: 4,
  boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  //width: 500,
});

const NoResultsContainer = styled.div({
  width: '100%',
  textAlign: 'center',
  padding: 20,
  fontStyle: 'italic',
});

const SortIcon = (props: { sortDir: 'asc' | 'desc' }) => {
  if (props.sortDir === 'asc') {
    return <ArrowUp size={16} />;
  } else if (props.sortDir === 'desc') {
    return <ArrowDown size={16} />;
  } else {
    return null;
  }
};

const HeaderAttribute = (props: HeaderAttributeProps) => {
  const sortCtx = useContext(KolSearchResultsSortContext);
  const { setOpen, state, dispatch } = useContext(KolSearchResultsFilterContext);

  const hasActiveFilter = state.some(f => isFilterValid(f) && areFiltersSimilar(props.sortKey, f.key));

  const onFilterClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!state.some(f => f.key === props.sortKey)) {
      dispatch({
        type: 'filters-set',
        payload: [{
          key: props.sortKey,
          identifier: createId(),
          type: null,
          value: null,
        }],
      });
    }
    setOpen(old => !old);
  }, [dispatch, props.sortKey, setOpen, state]);

  if (props.disabled) {
    return <Attribute>{props.children}</Attribute>;
  }

  return (
    <SortableAttribute onClick={() => sortCtx.toggleSortKey(props.sortKey)}>
      {props.children}
      <div style={{ flexGrow: 1 }} />
      {sortCtx.sortBy === props.sortKey && <SortIcon sortDir={sortCtx.sortDir} />}
      <Filter size={18} fill={hasActiveFilter ? 'black' : 'none'} onClick={onFilterClick} />
    </SortableAttribute>
  );
};

type SortKey = KolSearchResultSortKey;

type HeaderAttributeProps = {
  sortKey: SortKey;
  disabled: boolean;
} & ChildrenProps;

const sortFns: SortFnMap<KolSearchResult, SortKey> = {
  email: (a, b) => a.email?.localeCompare(b.email),
  name: (a, b) => a.name?.localeCompare(b.name),
  firstName: (a, b) => a.firstName?.localeCompare(b.firstName),
  lastName: (a, b) => a.lastName?.localeCompare(b.lastName),
  phone: (a, b) => a.phone?.localeCompare(b.phone),
  location: (a, b) => a.streetAddress?.localeCompare(b.streetAddress),
  affiliation: (a, b) => a.affiliations[0]?.name?.localeCompare(b.affiliations[0]?.name),
  isMun: (a, b) => a.recommendations?.length - b.recommendations.length,
  distance: (a, b) => a.distance - b.distance,
};

const accessorFnMap: StringAccessorFnMap<KolSearchResult, SortKey> = {
  email: x => x.email,
  name: x => x.name,
  firstName: x => x.firstName,
  lastName: x => x.lastName,
  phone: x => x.phone,
  location: x => [x.streetAddress, x.city, x.state, x.country, x.zipCode].filter(Boolean).join(' '),
  affiliation: x => x.affiliations[0]?.name,
  isMun: x => (!!x.recommendations?.length).toString(),
  distance: x => x.distance?.toString(),
};