import type { Root, Text } from 'mdast';
import type { Plugin } from 'unified';
import { visit } from 'unist-util-visit';

export const remarkCustomComponents: Plugin<[], Root> = () => {
  return (tree) => {
    visit(tree, 'text', (node: Text, index, parent) => {
      if (!parent || typeof index !== 'number') return;

      // Regex to match custom bracketed components
      const regex = /\{\{([a-zA-Z0-9-_]+)\}\}/g;
      const matches = [...node.value.matchAll(regex)];

      if (matches.length === 0) return;

      const newNodes = [];
      let lastIndex = 0;

      for (const match of matches) {
        const [fullMatch, componentName] = match;
        const startIndex = match.index ?? 0;

        // Push preceding text if any
        if (startIndex > lastIndex) {
          newNodes.push({
            type: 'text',
            value: node.value.slice(lastIndex, startIndex),
          });
        }

        // Push custom component node
        newNodes.push({
          type: 'customComponent',
          data: {
            hName: 'customComponent',
            hProperties: {
              value: componentName,
            },
          },
        });

        lastIndex = startIndex + fullMatch.length;
      }

      // Push remaining text if any
      if (lastIndex < node.value.length) {
        newNodes.push({
          type: 'text',
          value: node.value.slice(lastIndex),
        });
      }

      // Replace original node with new nodes
      parent.children.splice(index, 1, ...newNodes);
    });
  };
};

export default remarkCustomComponents;
