import { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTheme } from '@mui/material';
import { X } from 'react-feather';
import Select from 'react-select';
import { buildSelectStyles, buildSelectTheme } from '@/components/Chat.Filters/Presentation';
import { useDebounceCallback } from '@/hooks/useDebounce';
import { camelToSpaceCase } from '@/utils';
import { KolSearchResultsFilterContext } from './context';
import type { KolSearchTableFilters } from './interfaces.state';
import type { FilterType, KolSearchResultSortKey } from './interfaces.types';
import { SORT_KEYS, doesFilterTypeRequireValue, getValidFilterTypesForKey, getDefaultTypeForKey } from './utils';

export const FilterPanel = () => {
  const { state, dispatch, setOpen } = useContext(KolSearchResultsFilterContext);

  useEffect(() => {
    if (!state.length) {
      setOpen(false);
    }
  }, [dispatch, setOpen, state]);

  return (
    <FiltersContainer>
      {state.map(f => <FilterEntry key={f.identifier} filter={f} />)}
    </FiltersContainer>
  );
};

type FilterEntryProps = {
  filter: KolSearchTableFilters.State['filters'][number];
};
const FilterEntry = ({ filter }: FilterEntryProps) => {
  const { dispatch } = useContext(KolSearchResultsFilterContext);
  const theme = useTheme();
  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);
  const filterTypes = useMemo(() => buildSelectOptions(getValidFilterTypesForKey(filter.key)), [filter.key]);

  const onKeyChange = useCallback((key: KolSearchResultSortKey) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        key,
        type: getDefaultTypeForKey(key),
        value: '',
      },
    });
  }, [dispatch, filter.identifier]);

  const onOperatorChange = useCallback((operator: FilterType) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        type: operator,
      },
    });
  }, [dispatch, filter.identifier]);

  const onValueChange = useCallback((value: string) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        value,
      },
    });
  }, [dispatch, filter.identifier]);

  const onRemoveFilter = useCallback(() => {
    dispatch({
      type: 'filter-removed',
      payload: { identifier: filter.identifier },
    });
  }, [dispatch, filter.identifier]);

  const debouncedValueChange = useDebounceCallback(onValueChange, 250);
  return (
    <FilterRow>
      <SelectDiv>
        <Select<Option<KolSearchResultSortKey>>
          options={COLUMN_OPTIONS}
          isSearchable={false}
          value={COLUMN_OPTIONS.find(x => x.value === filter.key)}
          placeholder={'Column'}
          getOptionLabel={o => camelToSpaceCase(o.label)}
          onChange={o => onKeyChange(o.value)}
          styles={selectStyles}
          theme={selectTheme} />
      </SelectDiv>
      <SelectDiv>
        <Select<Option<FilterType>>
          options={filterTypes}
          isSearchable={false}
          value={filterTypes.find(x => x.value === filter.type)}
          placeholder={'Operator'}
          getOptionLabel={o => o.label.replaceAll('-', ' ')}
          onChange={o => onOperatorChange(o.value)}
          styles={selectStyles}
          theme={selectTheme} />
      </SelectDiv>
      {doesFilterTypeRequireValue(filter.type) &&
        filter.key === 'isMun' ?
        <SelectDiv>
          <Select<Option<string>>
            options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
            isSearchable={false}
            value={filter.value ? { label: filter.value === 'true' ? 'Yes' : 'No', value: filter.value } : null}
            placeholder={'Filter Value'}
            getOptionLabel={o => o.label}
            onChange={o => onValueChange(o.value)}
            styles={selectStyles}
            theme={selectTheme} />
        </SelectDiv>
        :
        <Input onChange={e => debouncedValueChange(e.target.value)} defaultValue={filter.value ?? ''} placeholder='Filter Value' />
      }
      <RemoveFilterIcon onClick={onRemoveFilter} />
      <AddFilterIcon onClick={() => dispatch({ type: 'filter-added', payload: { key: 'name' } })} />
    </FilterRow>
  );
};

const FiltersContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const FilterRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const SelectDiv = styled.div({
  width: 175,
});

const Input = styled.input(({ theme }) => ({
  fontSize: 16,
  padding: 8,
  width: 250,
  height: 38,
  borderRadius: 5,
  border: `1px solid ${theme.palette.gray.light1} `,
  fontFamily: theme.fonts.regular,

  '&:focus': {
    borderColor: theme.isTrinityThemed ? theme.palette.blue.main : theme.palette.primary.main,
    borderWidth: 2,
  },

  '&::placeholder': {
    // color: theme.palette.gray.light1,
    fontFamily: theme.fonts.regular,
    fontSize: 14,
  },
}));

const RemoveFilterIcon = styled(X)(({ theme }) => ({
  cursor: 'pointer',
  padding: 6,
  height: 28,
  width: 28,
  borderRadius: 20,
  flexShrink: 0,
  flexGrow: 0,
  '&:hover': {
    backgroundColor: theme.palette.gray.light4,
  },
}));

const AddFilterIcon = styled(AddCircleIcon)(({ theme }) => ({
  cursor: 'pointer',
  padding: 6,
  height: 28,
  width: 28,
  color: theme.palette.primary.main,
  borderRadius: 20,
  flexShrink: 0,
  flexGrow: 0,
  '&:hover': {
    backgroundColor: theme.palette.gray.light4,
  },
}));

function buildSelectOptions<T extends string>(options: T[]): Option<T>[] {
  return options.map(o => ({ label: o, value: o }));
}

const COLUMN_OPTIONS = buildSelectOptions(SORT_KEYS);

type Option<T extends string> = {
  label: T;
  value: T;
};