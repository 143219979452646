import { createContext } from 'react';
import type { Chat } from '@/types';
import { createNamedContext } from '@/utils';
import type { Context, KolSearchResultSortKey } from './interfaces';

export const SubmitMessageContext = createContext<Context.SubmitMessage>(undefined);
export const ChatPageContextDataContext = createContext<Context.ChatPageContextData>(undefined);
export const DispatchPageContextDataContext = createContext<Context.DispatchPageContextData>(undefined);
export const AuthorContext = createContext<Context.Author>(undefined);
export const RegenerateMessageContext = createContext<Context.RegenerateMessage>(undefined);
export const VariantMessageContext = createContext<Context.VariantMessage>(undefined);
export const UploadFilesContext = createContext<Context.UploadFiles>(undefined);
export const IsPreparingUploadContext = createContext<boolean>(undefined);
export const SetPendingScrollContext = createContext<Context.SetPendingScroll>(undefined);
export const SubmitActionContext = createContext<Context.SubmitAction>(undefined);

export const ChatSessionFiltersContext = createContext<Chat.Filters>(undefined);
export const ChatSessionInputContext = createContext<string>(undefined);
export const ChatSessionQueriesContext = createContext<Record<string, Chat.Query>>(undefined);
export const ChatSessionFileUploadsContext = createContext<Chat.FileUpload[]>(undefined);
export const ChatSessionKolSearchesContext = createContext<Chat.KolSearch[]>(undefined);
export const ActiveChatSessionContext = createNamedContext<Context.ActiveChatSession>(undefined, 'ActiveChatSessionContext');
export const ChatSessionSelectedQueryContext = createContext<Chat.Query>(undefined);
export const ChatSettingsContext = createContext<Context.ChatSettings>(undefined);
export const ChatSessionItemsContext = createContext<Chat.InstanceItem[]>(undefined);

export const ChatHintsContext = createContext<Chat.QueryHint.Item[]>([]);
export const ChatHintsLoadingContext = createContext<boolean>(undefined);

export const ChatPermissionsContext = createContext<Context.ChatPermissions>(undefined);

export const MessagesListRefContext = createContext<Context.MessagesListRef>(undefined);

export const ChatSettingsToggleContext = createContext<Context.ChatSettingsToggle>(undefined);
export const MessageCitationsToggleContext = createContext<Context.MessageCitationsToggle>(undefined);
export const OpenSnippetContext = createContext<Context.OpenSnippet>(undefined);
export const ClickCitationContext = createContext<Context.ClickCitation>(undefined);
export const AssignCitationRefContext = createContext<Context.AssignCitationRef>(undefined);
export const CitationsContext = createContext<Chat.Citation[]>(undefined);
export const OpenCrmNoteContext = createContext<string>(undefined);
export const SetOpenCitationContext = createContext<Context.SetOpenCitation>(undefined);

export const ChatHasAccessContext = createContext<boolean>(true);

export const KolSearchIdContext = createContext<number>(undefined);
export const KolSearchIdentifierContext = createContext<string>(undefined);
export const KolSearchFormContext = createContext<Context.KolSearchFormState>(undefined);
export const DispatchKolSearchFormContext = createContext<Context.DispatchKolSearchForm>(undefined);
export const KolSearchStatusContext = createContext<Context.KolSearchStatus>(undefined);
export const KolSearchSubmitContext = createContext<Context.KolSearchSubmit>(undefined);
export const KolSearchResultsContext = createContext<Context.KolSearchResults>(undefined);
export const KolSearchCancelContext = createContext<Context.KolSearchCancel>(undefined);
export const KolResetSearchContext = createContext<Context.KolSearchCancel>(undefined);
export const KolProfileContext = createContext<Context.KolSearchProfile>(undefined);
export const UpdateKolSearchProfileContext = createContext<Context.UpdateKolSearchProfile>(undefined);
export const KolSearchErrorTraceIdContext = createContext<string>(undefined);
export const KolFollowupQuestionsContext = createContext<string[]>([]);
export const KolSearchFormBeginContext = createContext<Context.KolSearchFormBegin>(undefined);
export const KolSearchRecommendationsContext = createContext<Context.KolSearchRecommendations>(undefined);
export const KolSearchRecommendationProfileSelectedContext = createContext<Context.KolSearchRecommendationProfileSelected>(undefined);

export const KolSearchResultsSortContext = createNamedContext<Context.TableSortContextData<KolSearchResultSortKey>>(undefined, 'kol-search-results-sort-context');
export const KolSearchResultsFilterContext = createNamedContext<Context.TableFilterContextData>(undefined, 'kol-search-results-filter-context');
export const KolSearchResultsSelectionContext = createNamedContext<Context.KolTableSelectionContext>(undefined, 'kol-search-results-selection-context');
export const KolSearchResultsPageSizeContext = createNamedContext<Context.PageSize>(undefined, 'kol-search-results-page-size-context');
export const KolSearchResultsPageIndexContext = createNamedContext<Context.PageIndex>(undefined, 'kol-search-results-page-index-context');
export const KolSearchResultsSetPageIndexContext = createNamedContext<Context.SetPageIndex>(undefined, 'kol-search-results-set-page-index-context');
export const KolSearchResultsSetPageSizeContext = createNamedContext<Context.SetPageSize>(undefined, 'kol-search-results-set-page-size-context');
export const KolSearchTablePageContext = createNamedContext<Context.TablePageResults>(undefined, 'kol-search-results-context');
export const FetchMoreResultsContext = createNamedContext<Context.FetchMoreResults>(undefined, 'kol-search-results-fetch-more-context');

export const LegendCollapseToggleContext = createContext<Context.LegendCollapseToggle>(undefined);
export const ChartRefContext = createContext<Context.ChartRef>(undefined);
export const ChartInfoContext = createContext<Context.ChartInfo>(undefined);