import { useCallback, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { StopCircle } from 'react-feather';
import {
  InitiateQuerySpeechToText,
  TextToSpeechStateContext,
  StopTextToSpeechContext,
  VoiceModeContext,
} from '@/containers/TextToSpeechAudioPlayer/Context';
import { ChatResponseType } from '@/enums';
import { useHasBetaPermission, useHasChatReadAloudEnabled } from '@/store/selectors';
import { BaseMessageButton } from './Message.Button';

type Props = {
  kolSearchId?: number | null;
  queryId?: number | null;
};

export const ReadAloudButton = ({ kolSearchId, queryId }: Props) => {
  const hasBetaPermission = useHasBetaPermission();
  const hasChatReadAloudEnabled = useHasChatReadAloudEnabled();

  const initiateReadAloud = useContext(InitiateQuerySpeechToText);
  const stopTextToSpeech = useContext(StopTextToSpeechContext);
  const audioState = useContext(TextToSpeechStateContext);
  const voicemode = useContext(VoiceModeContext);

  const isPlaying =
    audioState &&
    audioState.isPlaying &&
    (audioState.queryId === queryId || audioState.kolSearchId === kolSearchId);

  const handleClick = useCallback(async () => {
    if (isPlaying) {
      stopTextToSpeech?.();
      return;
    }

    return initiateReadAloud({
      kolSearchId,
      queryId,
      isPlaying: true,
    });
  }, [isPlaying, stopTextToSpeech, initiateReadAloud, kolSearchId, queryId]);


  const autoPlayReadAloud = useCallback(() => {
    if (voicemode.enabled && voicemode.type !== ChatResponseType.HcpSummary && voicemode.queryId === queryId) {
      if (audioState.isPlaying) {
        stopTextToSpeech?.();
      }
      handleClick();
      voicemode.dequeue();
    }
  }, [
    audioState,
    handleClick,
    queryId,
    stopTextToSpeech,
    voicemode,
  ]);

  useEffect(() => {
    autoPlayReadAloud();
  }, []);

  if (!hasBetaPermission || !hasChatReadAloudEnabled) return null;

  return (
    <StyledButton onClick={handleClick}>
      {isPlaying ? <FilledStopCircle size={18} /> : <PlayArrowIcon fontSize="small" />}
      {isPlaying ? 'Stop' : 'Read Aloud'}
    </StyledButton>
  );
};

const StyledButton = styled(BaseMessageButton)({
  display: 'flex',
  justifyContent: 'left',
  gap: '4px',
});

const FilledStopCircle = styled(StopCircle)`
  circle {
    fill: none;
  }
  rect {
    fill: currentColor;
  }
`;
