import { getAuthenticatedClient } from './client';

export interface AudioStatusResponse {
  status: 'pending' | 'ready' | 'error';
  audioUrl?: string;
}

export const fetchAudioStatus = async (
  queryIdentifier: number,
): Promise<AudioStatusResponse> => {
  const client = getAuthenticatedClient();
  const response = await client.post('/chat/vocalize-audio', { queryIdentifier });
  return response.data;
};

type AudioSynthesis = {
  kolSearchId: number | null;
  queryId: number | null;
};

export const startAsyncAudioSynthesis = async (data: AudioSynthesis): Promise<AudioStatusResponse> => {
  const client = getAuthenticatedClient();
  const response = await client.post('/chat/vocalize-async', data);
  return response.data;
};

export const startAsyncOpenAITTS = async (data: AudioSynthesis): Promise<AudioStatusResponse> => {
  const client = getAuthenticatedClient();
  const response = await client.post('/chat/vocalize-openai', data);
  return response.data;
};
