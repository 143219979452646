import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { QueryParamConfig } from 'use-query-params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import * as api from '@/api/projects.entities';
import { ProjectEntitiesQueryContext, ProjectEntitiesQueryParamsContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled: boolean;
} & IProjectId;

export const ProjectEntitiesQueryContainer = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 75),
    sortBy: withDefault(StringParam, 'total') as QueryParamConfig<'boosted' | 'name' | 'total' | 'type'>,
    sortDir: withDefault(StringParam, 'desc') as QueryParamConfig<'asc' | 'desc'>,
    nameFilter: StringParam,
  });

  const qk = useMemo(() => {
    return [
      `get:projects/entities`, {
        index: qp.index,
        size: qp.size,
        projectId: props.projectId,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
        nameFilter: qp.nameFilter,
      },
    ];
  }, [
    props.projectId,
    qp.index,
    qp.size,
    qp.sortBy,
    qp.sortDir,
    qp.nameFilter,
  ]);

  const query = useQuery({
    queryKey: qk,
    queryFn: () => {
      return api.fetchItems({
        index: qp.index,
        projectId: props.projectId,
        size: qp.size,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
        nameFilter: qp.nameFilter,
      });
    },
    enabled: props.enabled,
    keepPreviousData: true,
  });

  return (
    <ProjectEntitiesQueryParamsContext.Provider value={[qp, setQuery]}>
      <ProjectEntitiesQueryContext.Provider value={query}>
        {props.children}
      </ProjectEntitiesQueryContext.Provider>
    </ProjectEntitiesQueryParamsContext.Provider>
  );
};

ProjectEntitiesQueryContainer.displayName = 'Project.Entities.Query.Container';