import { memo, useContext } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import type { BarChart } from '@/types/chat.chart';
import { LegendCollapseToggleContext } from '../Chat/context';
import { ChartHoverText } from './Hover';
import { theme } from './theme';

type Props = {
  chart: BarChart;
  colors: string[];
};

export const NivoStackedBarChart = memo((props: Props) => {
  const returnedData = props.chart.data;
  const items = {};
  items[props.chart.indexBy] = props.chart.xAxisTitle;
  const keys = new Set<string>();

  for (let i = 0; i < returnedData.length; i++) {
    keys.add(returnedData[i].x);
    items[returnedData[i].x] = returnedData[i].y;
    items[`${returnedData[i].x}Color`] = props.colors[i];
  }

  const data = [{ ...items }];
  const [open, setOpen] = useContext(LegendCollapseToggleContext);

  return (
    <div style={{ height: 320, width: '100%', position: 'relative' }}>
      <ResponsiveBar
        data={data}
        keys={[...keys]}
        indexBy={props.chart.indexBy}
        margin={{ bottom: 50, left: 110 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={props.colors}
        enableGridY={false}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.chart.yAxisTitle,
          legendPosition: 'middle',
          legendOffset: 32,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: props.chart.yAxisTitle,
          legendPosition: 'middle',
          truncateTickAt: 20,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#ffffff"
        layout="horizontal"
        tooltip={e => {
          return <ChartHoverText name={e.label} count={e.value} />;
        }}
        onClick={data => {
          setOpen(true);
        }}
        animate={false}
        theme={theme}
        role="application" />
    </div>
  );
});