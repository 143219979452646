import { createId } from '@paralleldrive/cuid2';
import { KolSearchStatus } from '@/enums/kol';
import type { Chat } from '@/types';
import type { ChatState, KolSearchState } from './interfaces';
import { generateEmptyKolSearch } from './state.initial-state';

export function kolSearchesReducer(state: KolSearchState.State, action: ChatState.Action): KolSearchState.State {

  switch (action.type) {
    case 'kol-search-added': {
      return state.concat(action.payload.item);
    }

    case 'kol-search/recommendations-loading': {
      return state.map(ks => {
        if (ks.identifier === action.payload.identifier) {
          return {
            ...ks,
            recommendations: {
              ...ks.recommendations,
              status: 'loading',
            },
          };
        }
        return ks;
      });
    }

    case 'kol-search/recommendations-loaded': {
      return state.map(ks => {
        if (ks.identifier === action.payload.identifier) {
          return {
            ...ks,
            recommendations: {
              status: 'loaded',
              items: action.payload.items,
              total: action.payload.total,
            },
          };
        }
        return ks;
      });
    }

    case 'kol-search/recommendations-added': {
      return state.map(ks => {
        if (ks.identifier === action.payload.identifier) {
          return {
            ...ks,
            recommendations: {
              ...ks.recommendations,
              items: ks.recommendations.items.concat(action.payload.items),
            },
          };
        }
        return ks;
      });
    }

    case 'kol-search-form-begin': {
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.FormEntry,
            form: {
              name: '',
            },
          };
        }
        return kolSearch;
      });
    }

    case 'kol-search-form-updated':
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            form: {
              ...kolSearch.form,
              ...action.payload.form,
            },
          };
        }
        return kolSearch;
      });

    case 'kol-search-submitted':
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.FormSubmitted,
          };
        }
        return kolSearch;
      });

    case 'kol-search-results-available': {
      const results = state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return action.payload.result;
        }
        return kolSearch;
      });

      if (action.payload.result.status === KolSearchStatus.NoResultsReturned) {
        return results.concat(generateEmptyKolSearch());
      }
      return results;
    }

    case 'kol-search-cancelled': {
      const existing = state.map<Chat.KolSearch>(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.SearchCancelled,
          };
        }
        return kolSearch;
      });
      return existing.concat(generateEmptyKolSearch());
    }

    case 'kol-search-profile-selected':
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.ProfileSelectionSubmitted,
          };
        }
        return kolSearch;
      });

    case 'kol-search-profile-available': {
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return action.payload.result;
        }
        return kolSearch;
      });
    }

    case 'kol-search-form-submission-errored':
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.FormSubmissionErrored,
            errorTraceId: action.payload.traceId,
          };
        }
        return kolSearch;
      });

    case 'kol-search-profile-selection-errored':
      return state.map(kolSearch => {
        if (kolSearch.identifier === action.payload.identifier) {
          return {
            ...kolSearch,
            status: KolSearchStatus.ProfileSelectionErrored,
            errorTraceId: action.payload.traceId,
          };
        }
        return kolSearch;
      });

    case 'kol-search/question-bank-question-selected':
      return [{
        id: null,
        createdOn: new Date(),
        status: KolSearchStatus.FormEntry,
        identifier: createId(),
        followupQuestions: [],
        form: {
          name: '',
        },
        results: null,
        profile: null,
        recommendations: {
          status: 'skipped',
          items: [],
          total: 0,
        },
        citations: [],
      }];

    case 'kol-search/new-search-initiated':
      return state.map(m => {
        if (m.identifier !== action.payload.oldIdentifier) {
          return m;
        }
        return {
          ...m,
          status: KolSearchStatus.SearchCancelled,
        };
      }).concat({
        id: null,
        createdOn: new Date(),
        status: KolSearchStatus.FormEntry,
        identifier: createId(),
        followupQuestions: [],
        form: {
          name: '',
        },
        results: null,
        profile: null,
        recommendations: {
          status: 'skipped',
          items: [],
          total: 0,
        },
        citations: [],
      });

    default:
      return state;
  }

}