import { getAuthenticatedClient } from './client';
import type * as QueryApi from './interfaces/queries';
import { transformChatInstance, transformChatQuery } from './transformers';
import { download } from './utils';

export const updateQueryRating = ({ chatInstanceId, queryIdentifier, ...data }: QueryApi.UpdateQueryRating.Request) => {
  return getAuthenticatedClient().patch<QueryApi.UpdateQueryRating.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/rating`, data)
    .then(res => ({
      query: transformChatQuery(res.data.query),
    }));
};

export const updateQueryFeedback = ({ chatInstanceId, queryIdentifier, ...data }: QueryApi.UpdateQueryFeedback.Request) => {
  return getAuthenticatedClient().patch<QueryApi.UpdateQueryFeedback.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/feedback`, data)
    .then(res => ({
      query: transformChatQuery(res.data.query),
    }));
};

export const getQueryFeedback = ({ chatInstanceId, queryIdentifier }: QueryApi.GetQueryFeedback.Request) => {
  return getAuthenticatedClient().get<QueryApi.GetQueryFeedback.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/feedback`)
    .then(res => ({
      feedback: res.data.feedback,
    }));
};

export const getQuery = ({ chatInstanceId, queryIdentifier }: QueryApi.GetQuery.Request) => {
  return getAuthenticatedClient().get<QueryApi.GetQuery.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}`)
    .then(res => ({
      chatInstance: transformChatInstance(res.data.chatInstance),
      query: transformChatQuery(res.data.query),
    }));
};

export const downloadQueryTable = async ({ chatInstanceId, queryIdentifier }: QueryApi.DownloadQueryTable.Request) => {
  const res = await getAuthenticatedClient().get<Blob>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/download-table-data`, {
    responseType: 'blob',
  });

  const contentDisposition = res.headers['content-disposition'] as string;

  const fileName = new RegExp(/filename="(.+)"/).exec(contentDisposition)[1];
  return {
    blob: res.data,
    fileName,
  };
};

export const cancelQueryHcpSelection = ({ chatInstanceId, queryIdentifier }: QueryApi.CancelQueryHcpSelection.Request) => {
  return getAuthenticatedClient().patch<QueryApi.CancelQueryHcpSelection.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/cancel-hcp-selection`);
};

export const downloadHcpResults = async ({ chatInstanceId, queryIdentifier }: QueryApi.DownloadHcpResults.Request) => {
  return download(getAuthenticatedClient(), `/chat/${chatInstanceId}/queries/${queryIdentifier}/download-hcp-results`);
};

export const getQueryResponseText = ({ chatInstanceId, queryId, tts }: QueryApi.GetQueryResponseText.Request) => {
  return getAuthenticatedClient().get<QueryApi.GetQueryResponseText.Response>(`/chat/${chatInstanceId}/queries/${queryId}/response-text?tts=${tts}`)
    .then(res => res.data.value);
};

export const downloadQueryResponse = ({ chatId, queryIdentifier }: QueryApi.DownloadQueryResponse.Request) => {
  return download(getAuthenticatedClient(), `/chat/${chatId}/queries/${queryIdentifier}/download`);
};