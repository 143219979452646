import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { AddEntityForm } from './AddEntity.Form';
import styles from './style/AddEntity.Form.module.css';

type Props = {
  loading: boolean;
  onSubmit: (items: { name: string; type: string }[]) => void;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const AddEntityModal = (props: Props) => {

  return (
    <Modal
      classes={{ root: styles.modal }}
      onClose={props.onClose}
      open={props.open}>
      <Header>Add Entity</Header>
      <AddEntityForm
        minEntries={1}
        loading={props.loading}
        onCancel={props.onClose}
        onSubmit={props.onSubmit} />
    </Modal>
  );
};

AddEntityModal.displayName = 'AddEntityModal';

export const useAddEntityModal = () => useModal(AddEntityModal);