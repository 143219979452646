import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';
import { buildAppleMapDirectionsLink } from '@/lib/apple.maps';
import { buildGoogleMapDirectionsLink } from '@/lib/google.maps';
import type { BuildMapLinkParams } from '@/lib/interfaces';
import appleMapsIcon from '@/static/logos/apple-maps.png';
import googleMapsIcon from '@/static/logos/google-maps.png';

type Params = {
  mapParams: BuildMapLinkParams;
};

type AppStore = {
  link: string;
  logo: string;
};
export type MapsLinkResult = {
  mapsLink: string;
  appStoreLink: string;
  icon: string;
  title: string;
};

export const useMapLinks = ({ mapParams }: Params) => {
  return useMemo(() => {
    const uaParser = new UAParser();
    const isIOS = uaParser.getOS().name === 'iOS';
    const isAndroid = uaParser.getOS().name === 'Android';

    const appleMapsLink = buildAppleMapDirectionsLink(mapParams);
    const googleMapsLink = buildGoogleMapDirectionsLink(mapParams);

    const results: MapsLinkResult[] = [
      { mapsLink: googleMapsLink, icon: googleMapsIcon, appStoreLink: isAndroid ? ANDROID_GOOGLE_MAPS_APP_STORE.link : isIOS ? IOS_GOOGLE_MAPS_APP_STORE.link : null, title: 'Google' },
    ];

    if (isIOS) {
      results.push({ mapsLink: appleMapsLink, appStoreLink: IOS_APPLE_MAPS_APP_STORE.link, icon: appleMapsIcon, title: 'Apple' });
    }


    return results;

  }, [mapParams]);
};

const ANDROID_GOOGLE_MAPS_APP_STORE: AppStore = {
  link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.maps',
  logo: googleMapsIcon,
};
const IOS_GOOGLE_MAPS_APP_STORE: AppStore = {
  link: 'https://apps.apple.com/us/app/google-maps/id585027354',
  logo: googleMapsIcon,
};
const IOS_APPLE_MAPS_APP_STORE: AppStore = {
  link: 'https://apps.apple.com/us/app/apple-maps/id915056765',
  logo: appleMapsIcon,
};