export enum WorkspaceObjectType {
  Folder = 1,
  ProjectParent = 2,
  File = 3,
  Workspace = 4,
  CallTranscript = 5,
  Brand = 6,
}

export enum WorkspaceObjectRole {
  Collaborator = 1,
  Viewer = 2,
  Owner = 3,
  Attendee = 4,
}

export enum WorkspaceType {
  Personal = 1,
  Group = 2,
}

export const WorkspaceTypeMap = new Map([
  [WorkspaceObjectType.Folder, { name: 'Folder' }],
  [WorkspaceObjectType.ProjectParent, { name: 'Project' }],
  [WorkspaceObjectType.Workspace, { name: 'Workspace' }],
  [WorkspaceObjectType.File, { name: 'File' }],
  [WorkspaceObjectType.CallTranscript, { name: 'Transcript' }],
  [WorkspaceObjectType.Brand, { name: 'Brand' }],
]);

export const WorkspaceRoleMap: Record<WorkspaceObjectRole, string> = {
  [WorkspaceObjectRole.Collaborator]: 'Collaborator',
  [WorkspaceObjectRole.Viewer]: 'Viewer',
  [WorkspaceObjectRole.Owner]: 'Owner',
  [WorkspaceObjectRole.Attendee]: 'Attendee',
};