import { getAuthenticatedClient } from './client';
import type { Entities } from './interfaces/projects';

export const fetchItems = (data: Entities.FetchItems.Request) => {
  return getAuthenticatedClient().get<Entities.FetchItems.Response>(`/projects/${data.projectId}/entities`, {
    params: {
      index: data.index,
      size: data.size,
      sortBy: data.sortBy,
      sortDir: data.sortDir,
      nameFilter: data.nameFilter,
    },
  })
    .then(res => res.data);
};

export const addItems = ({ projectId, ...data }: Entities.AddItems.Request) => {
  return getAuthenticatedClient().post<Entities.AddItems.Response>(`/projects/${projectId}/entities`, data);
};