import { type ReactNode, useContext } from 'react';
import { memo, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { addMonths } from 'date-fns';
import { ChevronRight, ChevronDown } from 'react-feather';
import { palette } from '@/components/Theme/theme';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { useCookie } from '@/hooks/useCookie';
import { shouldForwardProp } from '@/utils/emotion';

type Props = {
  children: ReactNode;
  open: boolean;
  onToggle: () => void;
  renderChildren: () => ReactNode;
};

export const ProfileAccordion = memo(({ children, open, onToggle, renderChildren }: Props) => {
  const ctx = useContext(AppThemingContext);

  return (
    <div>
      <Header
        enabled={ctx.query.data?.enabled}
        onClick={onToggle}>
        {open ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
        {children}
      </Header>
      {open && (
        <Content>
          {renderChildren()}
        </Content>
      )}
    </div>
  );
});

type ContainerProps = {
  profileKey: string;
} & Pick<Props, 'children' | 'renderChildren'>;

export const ProfileAccordionContainer = (props: ContainerProps) => {

  const [open, toggle] = useProfileKeyCookie(props.profileKey);

  return (
    <ProfileAccordion
      {...props}
      open={open}
      onToggle={toggle}
    />
  );
};

const useProfileKeyCookie = (key: string) => {
  const cookieKey = `hcp-profile-accordion-${key}`;
  const [value, setCookieInternal] = useCookie(cookieKey);

  const open = useMemo(() => {
    return value === 'open' || value === undefined;
  }, [value]);

  const toggle = useCallback(() => {
    setCookieInternal({
      expires: addMonths(new Date(), 36),
      value: open ? 'closed' : 'open',
    });
  }, [open, setCookieInternal]);

  return [open, toggle] as const;
};

const Content = styled.div({
  marginTop: 8,
});

const Header = styled('div', { shouldForwardProp: shouldForwardProp('enabled') })<{ enabled: boolean }>(({ theme, enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 7,
  cursor: 'pointer',
  borderRadius: 5,
  color: theme.palette.black.main,
  '&:hover': {
    backgroundColor: enabled ? palette.white.dark2 : palette.white.dark1,
  },
}));