/* eslint-disable no-process-env */
import type { RuntimeEnvironment } from '@/types/environment';
import { config } from './index';

export function isDevEnv() {
  try {
    return config().APP_ENVIRONMENT === 'development';
  } catch {
    return !process.env.APP_ENVIRONMENT || process.env.APP_ENVIRONMENT === 'development';
  }
}

export function isSandboxEnv() {
  try {
    return config().APP_ENVIRONMENT === 'sandbox';
  } catch {
    return process.env.APP_ENVIRONMENT === 'sandbox';
  }
}

export function isProdEnv() {
  try {
    return config().APP_ENVIRONMENT === 'production';
  } catch {
    return process.env.APP_ENVIRONMENT === 'production';
  }
}

export function isJnJEnvironment() {
  const jnjEnvironments: RuntimeEnvironment[] = ['production', 'sandbox:jnj', 'development:jnj', 'production:jnj', 'uat:jnj'];
  try {
    return jnjEnvironments.includes(config().RUNTIME_ENVIRONMENT);
  } catch {
    return jnjEnvironments.includes(process.env.RUNTIME_ENVIRONMENT as RuntimeEnvironment);
  }
}

export function isLowerEnv() {
  return isDevEnv() || isSandboxEnv();
}

export function isDemoEnv() {
  const envs: RuntimeEnvironment[] = ['sandbox', 'production'];
  try {
    return envs.includes(config().RUNTIME_ENVIRONMENT);
  } catch {
    return envs.includes(process.env.RUNTIME_ENVIRONMENT as RuntimeEnvironment);
  }
}

export function isVeevaPlatform() {
  try {
    return config().APP_PLATFORM === 'veeva';
  } catch {
    return process.env.APP_PLATFORM === 'veeva';
  }
}