import { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import MUIDownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import markdownToText from 'markdown-to-text';
import { downloadQueryResponse } from '@/api/queries';
import { useQueryDownloader } from '@/containers/QueryDownloader';
import { ChatResponseType } from '@/enums';
import type { Chat } from '@/types';
import { ActiveChatSessionContext } from './context';
import { BaseMessageButton } from './Message.Button';

type Props = {
  item: Chat.Query<Date, ChatResponseType>;
};

export const MessageButtons = ({ item }: Props) => {
  switch (item.response.type) {
    case ChatResponseType.LocationRouting:
      return <LocationRoutingButtons item={item} />;
    default:
      return <></>;
  }
};

const LocationRoutingButtons = ({ item }: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const onShare = useCallback(() => {
    const body = markdownToText(item.response.value, {
      stripListLeaders: false,
      listUnicodeChar: false,
    });
    const subject = 'HCP Routes';
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }, [item.response.value]);

  const { download } = useQueryDownloader({
    queryKey: ['download-query-response', item.identifier, chat.id],
    queryFn: async () => {
      return downloadQueryResponse({ queryIdentifier: item.identifier, chatId: chat.id });
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

  const onDownload = useCallback(() => {
    download({
      title: `Generating Route Report`,
      name: 'hcp-routing-result.xlsx',
    });
  }, [download]);

  return (
    <>
      {/* <BaseMessageButton onClick={onShare}>
        <ShareIcon /> Share
      </BaseMessageButton> */}
      <BaseMessageButton onClick={onDownload}>
        <DownloadIcon /> Download
      </BaseMessageButton>
    </>
  );
};

const ShareIcon = styled(ReplyIcon)(() => ({
  fontSize: 18,
  transform: 'scaleX(-1)',
}));

const DownloadIcon = styled(MUIDownloadIcon)(() => ({
  fontSize: 18,
}));