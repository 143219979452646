import { useCallback, useContext, useMemo, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { useRegisterChatPageContext } from '@/components/Chat';
// import { RowsPerPage } from '@/components/TablePagination';
// import { useCanUpload, useGoogleDriveUpload, useZoomRecordingUpload } from '@/containers/WorkspaceFileUpload/hooks';
// import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { Breadcrumb, Breadcrumbs } from '@/components/presentation/Breadcrumbs';
import { FileLayoutSelector } from '@/components/presentation/FileLayoutSelector';
import { Card, CardContent, Divider, GroupCardHeader } from '@/components/presentation/Main';
import { TablePagination } from '@/components/presentation/TablePagination';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload';
import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { useInitiateFilesReview } from '@/containers/WorkspaceFileUpload/hooks';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject';
import { ChatContextType } from '@/enums/chat';
import type { AppLoadedPayload } from '@/store/interfaces';
import type { BrandInsightsPageContext } from '@/types';
import { cx /*, useDevice */ } from '@/utils';
import { FilesTableDataContext, FilesTableLoadingContext, FilesTableContext } from './context';
// import { FilesActionsButton } from './Files.Table.Actions';
import { FilesActionsButton } from './Files.Table.Actions';
import { FilesTableEmptyState } from './Files.Table.EmptyState';
import { FilesTableGrid } from './Files.Table.Grid';
import { FilesTableList } from './Files.Table.List';
/* import { Menu } from './Files.Upload.Menu'; */
import * as TableState from './Files.Table.state';
import { useFileLayoutSelector } from './hooks/useFileLayoutSelector';
import type { IFilesTable } from './interfaces';
import styles from './style/Files.Table.module.css';

type Props = {
  Actions?: React.ComponentType;
  classes?: {
    root?: string;
    pagination?: string;
    table?: string;
    header?: string;
  };
  renderHeaderTitle?: () => JSX.Element;
};

export const FilesTable = ({ Actions = null, classes = {}, renderHeaderTitle }: Props) => {
  // const canUpload = useCanUpload();
  // const onUpload = useContext(UploadFilesContext);
  const isLoading = useContext(FilesTableLoadingContext);
  const data = useContext(FilesTableDataContext);

  // const handleUploadClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   return onUpload(Array.from(e.target.files));
  // }, [onUpload]);

  // const device = useDevice();

  // const { openPicker, loading } = useGoogleDriveUpload({
  //   onSelect: onUpload,
  // });

  // const [openZoomPicker, ZoomPickerModal] = useZoomRecordingUpload();

  // const hasConnectedFileAccounts = useSelector((state: Store.State) => !!state.user.connected.filter(a => a.typeId == ConnectedAccountType.Files).length);

  const [layout, setLayout] = useFileLayoutSelector();

  const [tableState, dispatchTable] = useReducer(TableState.reducer<IFilesTable.Item>, TableState.getInitialState<IFilesTable.Item>());

  useEffect(() => {
    dispatchTable({ type: 'load', items: data });
  }, [data]);

  const updatePageSize = useCallback((pageSize: number) => dispatchTable({ type: 'update-page-size', pageSize }), []);
  const nextPage = useCallback(() => dispatchTable({ type: 'next-page' }), []);
  const previousPage = useCallback(() => dispatchTable({ type: 'previous-page' }), []);
  const pageItems = useMemo(() => TableState.getPagedItems(tableState.items, tableState.pageIndex, tableState.pageSize), [tableState.items, tableState.pageIndex, tableState.pageSize]);

  const renderUpload = useCallback(() => {
    return (
      <div className={styles.buttons}>
        <FileLayoutSelector
          classname={styles.selector}
          selected={layout}
          onSelect={setLayout} />
        {Actions && <Actions />}
      </div>
    );
  }, [
    Actions,
    layout,
    setLayout,
  ]);

  const Header = useCallback(() => {
    return (
      <GroupCardHeader className={cx(styles.header, classes.header)}>
        <div className={styles.left}>
          {renderHeaderTitle
            ? renderHeaderTitle()
            : <div>Files</div>
          }
        </div>
        {renderUpload()}
      </GroupCardHeader>
    );
  }, [
    classes.header,
    renderHeaderTitle,
    renderUpload,
  ]);

  const updateSortBy = useCallback((sortBy: IFilesTable.SortingRule) => {
    dispatchTable({ type: 'update-sort-by', sortBy: [sortBy] });
  }, []);

  const footerClasses = useMemo(() => {
    return {
      root: styles.footer,
      pageSize: styles.rows,
      pagination: styles.pagination,
    };
  }, []);

  const renderTableBody = useCallback(() => {
    if (layout === 'list') {
      return (
        <FilesTableList
          classes={classes}
          items={pageItems}
          canUpload={false}
          onUpload={() => { }}
          pagination={{
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
            sortBy: tableState.sortBy,
            updateSortBy,
          }} />
      );
    } else if (layout === 'grid') {
      return (
        <FilesTableGrid
          classes={classes}
          items={pageItems}
          canUpload={false}
          onUpload={() => { }} />
      );
    }

    return null;
  }, [
    classes,
    pageItems,
    layout,
    tableState,
    updateSortBy,
  ]);

  const renderContent = useCallback(() => {
    if (isLoading) return null;

    if (!data?.length) {
      return (
        <>
          <FilesTableEmptyState />
        </>
      );
    }

    return (
      <>
        {renderTableBody()}
        {!!data?.length && (
          <FilesTableFooter
            classes={footerClasses}
            pageSize={tableState.pageSize}
            pageIndex={tableState.pageIndex}
            totalCount={tableState.totalCount}
            canNextPage={tableState.canNextPage}
            canPreviousPage={tableState.canPreviousPage}
            updatePageSize={updatePageSize}
            nextPage={nextPage}
            previousPage={previousPage} />
        )}
      </>
    );
  }, [
    footerClasses,
    isLoading,
    data?.length,
    renderTableBody,
    tableState,
    nextPage,
    previousPage,
    updatePageSize,
  ]);

  return (
    <Card className={cx(styles.card, classes.table)}>
      <Header />
      {!data?.length && <Divider />}
      <CardContent
        className={styles.content}
        fluid>
        {renderContent()}
      </CardContent>
    </Card>
  );
};

type FilesTableFooterProps = {
  classes?: {
    root?: string;
    pageSize?: string;
    pagination?: string;
  };
  pageSize: number;
  pageIndex: number;
  totalCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  updatePageSize: (size: number) => unknown;
  nextPage: () => unknown;
  previousPage: () => unknown;
};

const FilesTableFooter = ({
  pageSize, pageIndex, totalCount,
  nextPage, previousPage, canNextPage, canPreviousPage,
  classes = {},
}: FilesTableFooterProps) => {
  return (
    <div className={cx(styles.paginationRoot, classes.root)}>
      {/* <RowsPerPage
        className={cx(styles.pageSize, classes.pageSize)}
        value={pageSize}
        onSelect={updatePageSize}
        options={[25, 50, 100]} /> */}
      <TablePagination
        className={cx(styles.pagination, classes.pagination)}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        nextPage={nextPage}
        pageSize={pageSize}
        previousPage={previousPage}
        totalCount={totalCount} />
    </div>
  );
};

const WorkspaceFilesTableContent = () => {
  const { entity, object } = useSelector((state: AppLoadedPayload) => state.workspace.group);

  const query = useQuery({
    queryKey: [
      `get:workspaces/objects`,
      entity.id,
      object.id,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFiles({
        workspaceId: entity.id,
        objectId: object.id,
      });
    },
  });


  const chatContext: BrandInsightsPageContext.Global = useMemo(() => {
    return {
      type: ChatContextType.Global,
    };
  }, []);

  const register = useRegisterChatPageContext();

  useEffect(() => {
    if (!chatContext) return;

    register(chatContext);
  }, [register, chatContext]);

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: object.id,
    workspaceId: entity.id,
  });

  return (
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    <FilesTableDataContext.Provider value={query.data?.items || []}>
      <FilesTableLoadingContext.Provider value={query.isInitialLoading}>
        <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs || []}>
          <FilesTableContext.Provider value={{ parentObject: { id: object.id, workspaceId: entity.id } }}>
            <UploadFilesContext.Provider value={uploadFiles}>
              <div className={styles.root}>
                <div className={styles.wrap}>
                  <div className={styles.banner}>
                    <div className={styles.main}>
                      <div className={styles.breadcrumbs}>
                        <Breadcrumbs>
                          <Breadcrumb>
                            Group Workspace
                          </Breadcrumb>
                        </Breadcrumbs>
                      </div>
                      <div className={styles.h2}>
                        <div className={styles.title} />
                      </div>
                    </div>
                  </div>
                  <FilesTable Actions={FilesActionsButton} />
                </div>
              </div>
            </UploadFilesContext.Provider>
          </FilesTableContext.Provider>
        </WorkspaceBreadcrumbsContext.Provider>
      </FilesTableLoadingContext.Provider>
    </FilesTableDataContext.Provider>
  );
};

export const WorkspaceFilesTable = () => {
  return (
    <FileUploadReviewContainer>
      <WorkspaceFilesTableContent />
    </FileUploadReviewContainer>
  );
};