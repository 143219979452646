import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import SpeechRecognition from 'react-speech-recognition';
import { useSpeechRecognition as useSpeechRecLib } from 'react-speech-recognition';
import { RecordingEnd } from '@/components/Sounds/RecordingEnd';
import { RecordingStart } from '@/components/Sounds/RecordingStart';
import { MicrophoneStateContext } from '@/containers/TextToSpeechAudioPlayer/Context';

type Props = {
  onResult: (result: string, prefix: string) => void;
  onInterimResult?: (result: string, prefix: string) => void;
  onAudioLevelChange: (level: number) => void;
  timeout?: number;
};

export type MicState = 'inactive' | 'waiting-for-permission' | 'listening' | 'error';

const SPEECH_TIMEOUT_MS = 7000;

export const useSpeechRecognition = ({ onResult: onResultProp, onAudioLevelChange, onInterimResult, timeout = SPEECH_TIMEOUT_MS }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const resultPrefix = useRef<string>();
  const { listening: libListening, finalTranscript, resetTranscript, interimTranscript, transcript } = useSpeechRecLib({ clearTranscriptOnListen: true });
  const [errorMsg, setErrorMsg] = useState('');
  const [micState, setMicState] = useContext(MicrophoneStateContext);

  useEffect(() => {
    if (!libListening && finalTranscript) {
      onResultProp(finalTranscript, resultPrefix.current);
      resetTranscript();
    }
  }, [libListening, finalTranscript, resetTranscript, onResultProp]);

  useEffect(() => {
    if (libListening && transcript && onInterimResult) {
      onInterimResult(transcript, resultPrefix.current);
    }
  }, [transcript, libListening, onInterimResult]);

  useEffect(() => {
    const api = SpeechRecognition.getRecognition();
    function onStart() {
      setMicState('listening');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => SpeechRecognition.stopListening(), timeout);
      RecordingStart.play();
    }

    function onEnd() {
      setMicState(old => {
        if (old === 'error') {
          return old;
        } else {
          RecordingEnd.play();
          return 'inactive';
        }
      });
    }

    function onError(e: SpeechRecognitionErrorEvent) {
      setMicState('error');
      if (e.error === 'not-allowed') {
        setErrorMsg('Permission denied');
      } else {
        setErrorMsg(e.error);
      }
    }

    if (api) {
      api.addEventListener('audiostart', onStart);
      api.addEventListener('end', onEnd);
      api.addEventListener('error', onError);

      return () => {
        api.removeEventListener('audiostart', onStart);
        api.removeEventListener('end', onEnd);
        api.removeEventListener('error', onError);
      };
    }
  }, [setMicState, timeout]);

  useEffect(() => {
    if (micState === 'listening') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => SpeechRecognition.stopListening(), timeout);
    }
  }, [interimTranscript, micState, timeout]);

  useEffect(() => {
    if (libListening) {
      document.body.addEventListener('click', clickHandler);

      return () => document.body.removeEventListener('click', clickHandler);
    }

    function clickHandler(e: MouseEvent) {
      if ((e.target as HTMLElement).tagName.toLowerCase() === 'button') {
        SpeechRecognition.stopListening();
      }
    }
  }, [libListening]);

  useEffect(() => {
    const api = SpeechRecognition.getRecognition();
    let audioContext: AudioContext;
    let mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
    let timer: NodeJS.Timeout;
    let micStream: MediaStream;

    if (api) {
      api.addEventListener('audiostart', onAudioStart);
      api.addEventListener('audioend', onAudioEnd);

      return () => {
        api.removeEventListener('audiostart', onAudioStart);
        api.removeEventListener('audioend', onAudioEnd);
      };
    }

    function onAudioStart() {
      window.navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        micStream = stream;
        audioContext = new AudioContext();
        mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        analyserNode.smoothingTimeConstant = 0.5;
        analyserNode.fftSize = 512;
        analyserNode.minDecibels = -80;

        mediaStreamAudioSourceNode.connect(analyserNode);

        const sampleArray = new Uint8Array(analyserNode.frequencyBinCount);

        const logTime = () => {

          analyserNode.getByteFrequencyData(sampleArray);
          let values = 0;

          const length = sampleArray.length;
          for (let i = 0; i < length; i++) {
            values += sampleArray[i];
          }

          const volume = Math.min(21, Math.max(0, 14 * Math.log10(values / length)));

          onAudioLevelChange(volume);
        };

        if (timer) {
          clearInterval(timer);
        }
        timer = setInterval(logTime, 100);
      }).catch(e => console.log(e));
    }

    function onAudioEnd() {
      if (mediaStreamAudioSourceNode) {
        mediaStreamAudioSourceNode.disconnect();
        mediaStreamAudioSourceNode = null;
      }

      if (audioContext) {
        audioContext.close();
        audioContext = null;
      }

      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      if (micStream) {
        for (const track of micStream.getTracks()) {
          track.stop();
        }

        micStream = null;
      }
    }
  }, [onAudioLevelChange]);

  const start = useCallback((prefix?: string) => {
    resultPrefix.current = prefix;
    if (micState !== 'error') {
      setMicState('waiting-for-permission');
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [micState]);

  return {
    listening: micState === 'listening',
    errorMsg,
    micState,
    interimResult: transcript,
    start,
    stop: SpeechRecognition.stopListening,
    isSupported: SpeechRecognition.browserSupportsSpeechRecognition,
  };
};