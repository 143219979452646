import { useState, useCallback } from 'react';

export const useSelectedArray = <T>(initial: T[] = []) => {
  const [selected, setSelected] = useState<T[]>(initial);

  const toggle = useCallback((item: T) => {
    setSelected(prev => {
      if (prev.includes(item)) {
        return prev.filter(i => i !== item);
      }
      return [...prev, item];
    });
  }, []);

  const clear = useCallback(() => {
    setSelected([]);
  }, []);

  return {
    selected,
    toggle,
    clear,
  };
};