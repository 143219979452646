import { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { SearchInput } from '@/components/presentation';
import { Dropdown } from '@/components/presentation/Dropdown';
import { TabViewTableSkeleton } from '@/components/TabView';
import { TermsContainer, TermFilterContext, ProjectTermsContext, TranscriptReplacementsContext, SelectedTermsContext } from '@/containers/Project.Terms';
import { useDebounceCallback } from '@/hooks/useDebounce';
import { ProjectDetailsContext, ProjectStateLoadingContext } from '@/screens/Project.Main/Context';
import { cx } from '@/utils';
import { TransformedProjectTermsContext, BoostOverridesContext } from './Context';
import { ContextMenu } from './ContextMenu';
import { type PaginationResult, usePagination } from './hooks';
import styles from './style/Project.Terms.module.css';
import { TermsTable } from './Terms.Table';

type Props = unknown;

export const ProjectEntities = (props: Props) => {
  const ctx = useContext(ProjectDetailsContext);

  return (
    <TermsContainer projectId={ctx.query.data.project.id}>
      <ProjectTermsContent />
    </TermsContainer>
  );
};

ProjectEntities.displayName = 'Project.Entities';

const ProjectTermsContent = () => {
  const { mode, globalReplacements, confirmedTerms, projectId } = useContext(TranscriptReplacementsContext);
  const { query: projectTermsQuery } = useContext(ProjectTermsContext);
  const { searchTerm } = useContext(TermFilterContext);
  const highlightsOpen = useHighlightsOpen();

  const [boostOverrides, setBoostOverrides] = useState<Record<string, boolean>>({});

  const setTermBoost = useCallback((term: string, boosted: boolean) => {
    setBoostOverrides(old => ({
      ...old,
      [term]: boosted,
    }));
  }, []);

  const transformedTerms = useMemo(() => {
    return (projectTermsQuery.data?.terms || []).map(t => {
      const replacement = globalReplacements.find(r => r.originalText === t.termValue);
      if (replacement) {
        return {
          ...t,
          termValue: replacement.replacementText,
          term: {
            id: t.term?.id,
            type: 'replaced',
          },
          boosted: boostOverrides[replacement.replacementText] ?? t.boosted,
        };
      } else {
        if (boostOverrides[t.termValue] !== undefined) {
          return { ...t, boosted: boostOverrides[t.termValue] };
        } else {
          return t;
        }
      }
    });
  }, [globalReplacements, projectTermsQuery.data?.terms, boostOverrides]);

  const filteredTerms = useMemo(() => {
    let terms = transformedTerms;

    if (mode === 'typo-cleanup') {
      terms = terms.filter(t => t.term?.type === 'spell-check' && !confirmedTerms.some(ct => t.termValue === ct.term));
    }

    if (searchTerm) {
      terms = terms.filter(t => t.termValue.toLowerCase().includes(searchTerm.toLowerCase()));

      if (!terms.some(t => t.termValue.toLowerCase() === searchTerm)) {
        terms = [{
          term: null,
          entities: [],
          transcriptIds: [],
          termValue: searchTerm,
          occurrences: null,
          boosted: false,
        }, ...terms];
      }
    }

    return terms;
  }, [transformedTerms, mode, searchTerm, confirmedTerms]);

  const pagination = usePagination({
    initialPageSize: 25,
    totalCount: filteredTerms.length,
  });

  const paginatedTerms = useMemo(() => {
    return filteredTerms.slice(pagination.slice.from, pagination.slice.to);
  }, [pagination.slice.from, pagination.slice.to, filteredTerms]);

  if (!projectTermsQuery.isFetched) return <div className={styles.root}><TabViewTableSkeleton /></div>;

  return (
    <TransformedProjectTermsContext.Provider value={{ terms: transformedTerms }}>
      <BoostOverridesContext.Provider value={{ boostOverrides, setTermBoost }}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <Header />
            <div className={styles.tableWrapper}>
              <table className={styles.mainTable}>
                <tr>
                  <td>
                    <TermsTable terms={paginatedTerms} collapsed={highlightsOpen} />
                  </td>
                  {highlightsOpen &&
                    <td className={styles.highlightsCell}>
                      <table className={styles.subTable}>
                        <thead>
                          <tr>
                            <th>
                              Text
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {/* <Highlights /> */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>}
                </tr>
              </table>
            </div>
            <Footer {...pagination} />
          </div>
        </div>
      </BoostOverridesContext.Provider>
    </TransformedProjectTermsContext.Provider>
  );
};

const Header = () => {
  const ctx = useContext(ProjectDetailsContext);
  const { setSearchTerm, setHighlightTerm, searchTerm } = useContext(TermFilterContext);
  const { query } = useContext(ProjectTermsContext);
  const [inputVal, setInputVal] = useState(searchTerm);

  const debouncedUpdateFilters = useDebounceCallback((val: string) => {
    setSearchTerm(val);
    setHighlightTerm(val);
  }, 300);

  const onSearchChange = useCallback((val: string) => {
    setInputVal(val);
    debouncedUpdateFilters(val);
  }, [debouncedUpdateFilters]);

  useEffect(() => {
    setInputVal(searchTerm);
  }, [searchTerm]);

  return (
    <div className={styles.header}>
      <div className={styles.title}>Terminology</div>
      <div>
        <SearchInput
          placeholder='Search'
          value={inputVal}
          onChange={onSearchChange} />
      </div>
      <div className={styles.spacer} />
      {/* <EditHeader /> */}
      <div className={styles.actions}>
        {query.isFetched &&
          <ContextMenu />}
      </div>
    </div>
  );
};

const Footer = (props: PaginationResult) => {
  return (
    <div className={styles.footer}>
      <div className={styles.pagination}>
        <div>
          Rows Per Page
        </div>
        <div>
          <Dropdown
            text={props.pageSize.toString()}
            getItemValue={i => i.id}
            items={[{ id: 10 }, { id: 25 }, { id: 50 }]}
            onSelect={i => props.setPageSize(i.id)} />
        </div>
        <div>
          {props.slice.from + 1} - {Math.min(props.slice.to, props.totalCount)} of {props.totalCount}
        </div>
        <ChevronLeft className={cx(styles.pageBtn, props.canGoBack ? null : styles.disabled)} onClick={props.canGoBack ? props.goBack : null} />
        <ChevronRight className={cx(styles.pageBtn, props.canGoNext ? null : styles.disabled)} onClick={props.canGoNext ? props.goNext : null} />
      </div>
    </div>
  );
};

export const useHighlightsOpen = () => {
  const { highlightTerm } = useContext(TermFilterContext);

  return !!highlightTerm;
};