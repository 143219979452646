import { useContext } from 'react';
import { useObjectAccessModal, useReadOnlyObjectAccessModal } from '@/components/ObjectAccess';
import { ObjectAccessButton, SkeletonAccess } from '@/components/ObjectAccess/Button';
import { ObjectAccessContainer } from '@/containers/ObjectAccess/ObjectAccessContainer';
import { WorkspaceFilePreviewLoadingContext } from '@/containers/Workspace.File.Preview/Context';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';

type Props = unknown;

export const Access = (props: Props) => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  return isLoading
    ? <SkeletonAccess />
    : <InteractiveAccess />;
};

Access.displayName = 'File.Header.Access';

const InteractiveAccess = (props: Props) => {
  const state = useWorkspaceFileState();
  const [toggleAccessModal, ObjectAccessModal] = useReadOnlyObjectAccessModal();

  if (!state.capabilities.canManageAccess) {
    return null;
  }

  return (
    <>
      <ObjectAccessButton
        count={state.meta.accessCount}
        onClick={toggleAccessModal} />
      <ObjectAccessContainer objectId={state.object.id} workspaceId={state.object.workspaceId}>
        <ObjectAccessModal />
      </ObjectAccessContainer>
    </>
  );
};

InteractiveAccess.displayName = 'File.Header.Access.Interactive';