import { ChatCitationType } from '@/enums/chat';
import type { Chat } from '@/types';
import { trunc } from '@/utils';

export function isDocumentCitation(item: Chat.Citation): item is Chat.Citation<ChatCitationType.Document> {
  return item.type === ChatCitationType.Document;
}

export function isTranscriptCitation(item: Chat.Citation): item is Chat.Citation<ChatCitationType.Transcript> {
  return item.type === ChatCitationType.Transcript;
}

export function getSnippetLink(item: Chat.Citation, snippet: Chat.CitationSnippet): string {
  if (isDocumentCitation(item)) {
    const docSnippet = snippet as Chat.CitationSnippet<ChatCitationType.Document>;
    return docSnippet.metadata?.page
      ? `${item.link}?p=${docSnippet.metadata.page}`
      : item.link;
  } else if (isTranscriptCitation(item)) {
    const tSnippet = snippet as Chat.CitationSnippet<ChatCitationType.Transcript>;
    return tSnippet.metadata?.start
      ? `${item.link}?s=${tSnippet.metadata.start}`
      : item.link;
  }
  return item.link;
}

export function getSnippetDisplayValue(snippet: Chat.CitationSnippet, type: ChatCitationType): string {
  switch (type) {
    case ChatCitationType.Document:
      return getDocumentSnippetDisplayVale(snippet as Chat.CitationSnippet<ChatCitationType.Document>);
    case ChatCitationType.Transcript:
      return getTranscriptSnippetDisplayValue(snippet as Chat.CitationSnippet<ChatCitationType.Transcript>);
    case ChatCitationType.CrmNote:
      return getCrmNoteSnippetDisplayValue(snippet as Chat.CitationSnippet<ChatCitationType.CrmNote>);

    default:
      return trunc(snippet.title, 25);
  }
}

export function getDocumentSnippetDisplayVale(snippet: Chat.CitationSnippet<ChatCitationType.Document>): string {
  if (snippet.title) return snippet.title;

  if (snippet.metadata?.page) return `Page ${snippet.metadata.page}`;

  return 'Document';
}

export function getTranscriptSnippetDisplayValue(snippet: Chat.CitationSnippet<ChatCitationType.Transcript>): string {
  if (snippet.title) return snippet.title;

  if (snippet.metadata?.start && snippet.metadata?.end) {
    return `${secondsToTime(snippet.metadata.start)} - ${secondsToTime(snippet.metadata.end)}`;
  }

  return `Transcript`;
}

export function getCrmNoteSnippetDisplayValue(snippet: Chat.CitationSnippet<ChatCitationType.CrmNote>): string {
  if (snippet.title) return snippet.title;
  return 'Interaction';
}

function secondsToTime(value: number) {

  const time = new Date(value * 1000).toISOString().substring(11, 19);

  return time.startsWith('00') ? time.substring(3) : time;
}

export function buildSnippetCharacters(ordinal: number) {
  let result = '';
  let num = ordinal;
  while (num > 0) {
    num--; // Adjust for 0-based index
    const char = String.fromCharCode(97 + (num % 26)); // 97 is the ASCII code for 'a'
    result = char + result;
    num = Math.floor(num / 26);
  }
  return result;
}

export function getCitationsDisplayCount(citations: Chat.Citation[]): number {

  const citationHasSnippetsMap: Record<ChatCitationType, boolean> = {
    [ChatCitationType.CrmNote]: false,
    [ChatCitationType.StrategicImperative]: false,
    [ChatCitationType.ContactDetail]: false,
    [ChatCitationType.UnmetNeed]: false,
    [ChatCitationType.KolProfile]: false,

    [ChatCitationType.Document]: true,
    [ChatCitationType.Transcript]: true,
    [ChatCitationType.Affiliation]: true,
    [ChatCitationType.Association]: true,
    [ChatCitationType.ClinicalTrial]: true,
    [ChatCitationType.Conference]: true,
    [ChatCitationType.CongressAbstract]: true,
    [ChatCitationType.Collaboration]: true,
    [ChatCitationType.Publication]: true,
    [ChatCitationType.CompanySponsorship]: true,

    [ChatCitationType.ClinicalLeaderTopic]: true,
    [ChatCitationType.ScientificLeaderTopic]: true,
    [ChatCitationType.DigitalLeaderTopic]: true,


    [ChatCitationType.FbmsInteractions]: true,
    [ChatCitationType.CallCenterInteractions]: true,
    [ChatCitationType.DocumentedInterests]: true,
    [ChatCitationType.MedicalInsights]: true,
  };

  return citations.reduce((acc, item) => {
    return acc + (citationHasSnippetsMap[item.type] ? item.snippets.length : 1);
  }, 0);
}