import { memo, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { BrandThemingContext } from '@/containers/Branding/Context';
import { BrandColorCategory, ColorPaletteType } from '@/enums/branding';
import type { Chart, ChartData, LineChart, BarChart, PieChart } from '@/types/chat.chart';
import { NivoBarChart } from '../Chat.Charts/Bar';
import { NivoDoughnutChart } from '../Chat.Charts/Doughnut';
import { ChartLegend } from '../Chat.Charts/Legend';
import { NivoLineChart } from '../Chat.Charts/Line';
import { NivoPieChart } from '../Chat.Charts/Pie';
import { NivoStackedBarChart } from '../Chat.Charts/StackedBar';
import { ChartRefContext, ChartInfoContext } from './context';

type Props = {
  data: ChartData;
};

export const ChartQuantAnalysesResponse = memo((props: Props) => {
  const branding = useContext(BrandThemingContext);
  const theming = useContext(AppThemingContext);

  const ready = useMemo(() => {
    return !branding.query.isInitialLoading &&
      branding.query.isFetchedAfterMount &&
      theming.query.isFetchedAfterMount &&
      theming.query.isFetchedAfterMount;
  }, [
    branding.query.isFetchedAfterMount,
    branding.query.isInitialLoading,
    theming.query.isFetchedAfterMount,
  ]);

  const colors = useMemo(() => {
    if (theming.query.data?.enabled) {
      const paletteId = branding.query.data.theming.themes.find(x => x.categoryId === BrandColorCategory.Options)?.paletteId;
      return branding.query.data.theming.palettes.find(x => x.id === paletteId)?.data?.colors;
    }

    return branding.query.data.theming.palettes.find(x => x.typeId === ColorPaletteType.Preset && x.categoryId === BrandColorCategory.Options)?.data?.colors;
  }, [theming.query.data?.enabled, branding.query.data.theming.palettes, branding.query.data.theming.themes]);

  const legend = [];
  const [ref, setRef] = useContext(ChartRefContext);
  const [chart, setChart] = useContext(ChartInfoContext);

  const renderChart = (chartType: string) => {
    switch (chartType) {
      case 'stacked-bar': {
        const chart = props.data.chart.chart as BarChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].x, color: colors[i] });
        }
        return <NivoStackedBarChart chart={chart} colors={colors} />;
      }

      case 'bar': {
        const chart = props.data.chart.chart as BarChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].x, color: colors[i] });
        }
        return <NivoBarChart chart={chart} colors={colors} />;
      }

      case 'pie': {
        const chart = props.data.chart.chart as PieChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].label, color: colors[i] });
        }
        return <NivoPieChart chart={chart} colors={colors} />;
      }
      case 'line': {
        const chart = props.data.chart.chart as LineChart;
        return <NivoLineChart chart={chart} colors={colors} />;
      }

      case 'doughnut': {
        const chart = props.data.chart.chart as PieChart;
        for (let i = 0; i < chart.data.length; i++) {
          legend.push({ key: chart.data[i].label, color: colors[i] });
        }
        return <NivoDoughnutChart chart={chart} colors={colors} />;
      }

      default:
        return null;
    }
  };

  // if (!props.data.chart || !props.data.chart.chart || props.data.chart.type === 'none') {
  //   setChart(null);

  //   // the response should only be displayed if props.data.chart is not null
  //   return (
  //     <div>
  //       {props.data.chart &&
  //         <div>
  //           {props.data.chart.response}
  //         </div>
  //       }

  //       <NoChartMessage>
  //         <div>Sorry, I was not able to generate a chart for this analysis.</div>
  //         <br />
  //       </NoChartMessage>
  //     </div>
  //   );
  // }

  setChart(props.data.chart);

  return (

    <div>

      <div>
        {props.data.chart.response}
      </div>

      <div ref={node => setRef(node)}>
        <Chart>
          {(ready && props.data.chart.chart) &&
            <>
              <Title>{props.data.chart.chart.title}</Title>
              {renderChart(props.data.chart.type)}
              {legend.length > 0 && <ChartLegend legend={legend} />}
            </>}
        </Chart>
      </div>
    </div>

  );
});

const Chart = styled.div`
  border: 1px solid var(--gray-l);
  border-radius: 15px;
  padding: 12px;
  margin-top: 10px;
  background-color: white;
`;

const Title = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));

// const NoChartMessage = styled.div`
//   margin-top: 10px;
// `;