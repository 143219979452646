import { Children, useEffect, useState } from 'react';
import { cx } from '@/utils';
import Icon from './Icon';
import type { SimpleAccordionProps } from './interface';
import Panel from './Panel';
import styles from './style.module.css';

export const SimpleAccordion = ({
  children,
  className,
  classes = {},
  grows = true,
  height = 60,
  iconHeight,
  label = '',
  open = true,
  toggleOpen,
}: SimpleAccordionProps) => {
  const [count, setCount] = useState(Children.count(children));

  useEffect(() => {
    setCount(Children.count(children));
  }, [children]);

  return (
    <>
      <Panel
        className={cx(className, classes.panel)}
        count={count}
        grows={grows}
        height={height}
        onClick={toggleOpen}
        open={open}>
        {label}
        <Icon height={iconHeight ?? height} />
      </Panel>

      <div className={cx(open ? styles.entered : styles.hidden, classes.value)}>
        {children}
      </div>
    </>
  );
};

export default SimpleAccordion;