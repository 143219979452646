import { useCallback, useRef } from 'react';
import * as atlas from 'azure-maps-control';
import atlasCss from 'azure-maps-control/dist/atlas.min.css';
import { getAzureMapsToken } from '@/api/location';
import { useUseableCSS } from '@/utils/hooks';


function getAuthOptions() {
  const authOptions: atlas.ServiceOptions['authOptions'] = {
    authType: atlas.AuthenticationType.sas,
    getToken: async (resolve, reject) => {
      try {
        const result = await getAzureMapsToken();
        resolve(result.token);
      } catch (e) {
        reject(e);
      }
    },
  };

  return authOptions;
}

type Props = {
  dataSourceId: string;
  features: Feature[];
  layers: atlas.layer.Layer[];
  mapOptions?: atlas.CameraOptions;
  height?: string;
  width?: string;
};

const Map = ({ features, layers, dataSourceId, height = '300px', width = '100%', mapOptions = {} }: Props) => {
  useUseableCSS(atlasCss);
  const loadedRef = useRef(false);
  const initMap = useCallback((mapEl: HTMLDivElement) => {
    if (!mapEl) {
      console.log('no map element');
      return;
    };

    if (loadedRef.current) {
      console.log('map already loaded');
      return;
    }

    const map = new atlas.Map(mapEl, {
      view: 'Auto',
      authOptions: getAuthOptions(),
      ...mapOptions,
    });

    map.events.add('ready', () => {
      console.log('ready');
    });

    map.events.add('load', () => {
      console.log('loaded map');
      loadData();
    });

    map.events.add('error', (e) => {
      console.log(e);
    });

    loadedRef.current = true;

    function loadData() {
      const dataSource = new atlas.source.DataSource(dataSourceId);
      map.sources.add(dataSource);

      for (const layer of layers) {
        map.layers.add(layer);
      }

      for (const f of features) {
        dataSource.add(f);
      }

      map.setCamera({
        bounds: atlas.data.BoundingBox.fromData(new atlas.data.FeatureCollection(features as atlas.data.Feature<atlas.data.Geometry, unknown>[])),
        padding: 25,
      });
    }
  }, [dataSourceId, features, layers, mapOptions]);

  return (
    <>
      <div style={{ height, width }} ref={initMap} />
    </>
  );
};


type Feature = atlas.data.FeatureCollection | atlas.data.Feature<atlas.data.Geometry, unknown> | atlas.data.Geometry | atlas.data.GeometryCollection | atlas.Shape;
export { Map as AzureMap };