import { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { WorkspaceFilePreviewLoadingContext } from '@/containers/Workspace.File.Preview/Context';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
// import { CreatedByWhenLabel, CreatedByWhenSkeleton } from '@/components/presentation';
import { Actions } from './Header.Actions';
import { FileBreadcrumbs } from './Header.Breadcrumbs';
import styles from './style/Header.module.css';

export const Header = () => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <FileBreadcrumbs />

          <div className={styles.details}>
            <div className={styles.info}>

              <div className={styles.header}>
                <Title />
              </div>

            </div>

            <Actions />
          </div>

          <div className={styles.subtitle}>
            {/* <CreatedBy /> */}
          </div>

        </div>
      </div>
    </div>
  );
};
/*
const CreatedBy = (props: unknown) => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);
  const { object } = useWorkspaceFileState();

  if (isLoading) {
    return (
      <CreatedByWhenSkeleton />
    );
  }

  return (
    <CreatedByWhenLabel
      className={styles.age}
      by={object.owner.name}
      when={`on ${format(object.createdOn, 'M/d/yyyy')}`} />
  );
};

CreatedBy.displayName = 'File.Header.CreatedBy';
*/
const Title = (props: unknown) => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);
  const { file } = useWorkspaceFileState();

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={file.name} />
      <h1 className={styles.title}>{file.name}</h1>
    </>
  );
};

Title.displayName = 'File.Header.Title';