import type React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { ChatSessionItemsContext } from '@/components/Chat/context';
import { useAudioPlayerScan, useAudioPlayerState, useToggleAudioPlaying } from '@/containers/AudioPlayer';
import { StopTextToSpeechContext, InitiateQuerySpeechToText, TextToSpeechStateContext } from '@/containers/TextToSpeechAudioPlayer/Context';
import { ChatInstanceItemType } from '@/enums';

interface AudioPlayerControllerProps {
  children: (props: {
    duration: number;
    playing: boolean;
    position: number;
    onSeek: (value: number) => void;
    onScan: (value: number) => void;
    onStop: () => void;
    onPreviousTrack: () => void;
    onNextTrack: () => void;
    onSkipBackward: () => void;
    onSkipAhead: () => void;
    onTogglePlay: () => void;
    canSkipBackward: boolean;
    canSkipForward: boolean;
  }) => JSX.Element;
}

const AudioPlayerController: React.FC<AudioPlayerControllerProps> = ({
  children,
}) => {
  const stopTextToSpeech = useContext(StopTextToSpeechContext);
  const initiateReadAloud = useContext(InitiateQuerySpeechToText);
  const ttsState = useContext(TextToSpeechStateContext);

  const [{ duration, playing }] = useAudioPlayerState();
  const togglePlaying = useToggleAudioPlaying();
  const { scan, seek, position } = useAudioPlayerScan();
  const onStop = useCallback(() => {
    if (stopTextToSpeech) {
      stopTextToSpeech();
    }
  }, [stopTextToSpeech]);

  const items = useContext(ChatSessionItemsContext);

  const currentIndex = useMemo(() => {
    return items.findIndex(item => {
      if (ttsState.kolSearchId) {
        return item.type === ChatInstanceItemType.KolSearch && item.id === ttsState.kolSearchId;
      } else if (ttsState.queryId) {
        return item.type === ChatInstanceItemType.Query && item.query.id === ttsState.queryId;
      }
      return false;
    });
  }, [items, ttsState]);

  console.log('currentIndex:', currentIndex);

  const currentItem = useMemo(() => items[currentIndex], [items, currentIndex]);
  const canSkipBackward = useMemo(() => currentIndex > 0, [currentIndex]);
  const canSkipForward = useMemo(() => currentIndex >= 0 && currentIndex < items.length - 1, [currentIndex, items]);

  const onPreviousTrack = useCallback(() => {
    stopTextToSpeech();
    if (currentIndex === -1 || currentIndex === 0) {
      console.warn('No previous item available');
      return;
    }
    const targetItem = items[currentIndex - 1];
    initiateReadAloud({
      queryId: targetItem.type === ChatInstanceItemType.Query ? targetItem.query.id : null,
      kolSearchId: targetItem.type === ChatInstanceItemType.KolSearch ? targetItem.id : null,
      isPlaying: true,
    });
  }, [currentIndex, items, stopTextToSpeech, initiateReadAloud]);

  const onNextTrack = useCallback(() => {
    stopTextToSpeech();
    if (currentIndex === -1 || currentIndex >= items.length - 1) {
      console.warn('No next item available');
      return;
    }
    const targetItem = items[currentIndex + 1];
    initiateReadAloud({
      queryId: targetItem.type === ChatInstanceItemType.Query ? targetItem.query.id : null,
      kolSearchId: targetItem.type === ChatInstanceItemType.KolSearch ? targetItem.id : null,
      isPlaying: true,
    });
  }, [currentIndex, items, stopTextToSpeech, initiateReadAloud]);

  const onSkipBackward = useCallback(() => {
    const newTime = Math.max(position - 15, 0);
    if (currentItem) {
      console.log('Skipping backward in track:', {
        queryId: currentItem.type === ChatInstanceItemType.Query ? currentItem.query.id : null,
        kolSearchId: currentItem.type === ChatInstanceItemType.KolSearch ? currentItem.id : null,
      });
    }
    seek(newTime);
  }, [position, seek, currentItem]);

  const onSkipAhead = useCallback(() => {
    const newTime = Math.min(position + 15, duration || Infinity);
    if (currentItem) {
      console.log('Skipping ahead in track:', {
        queryId: currentItem.type === ChatInstanceItemType.Query ? currentItem.query.id : null,
        kolSearchId: currentItem.type === ChatInstanceItemType.KolSearch ? currentItem.id : null,
      });
    }
    seek(newTime);
  }, [position, duration, seek, currentItem]);

  return children({
    duration: duration || 0,
    position,
    onStop,
    onPreviousTrack,
    onNextTrack,
    onScan: scan,
    onSeek: seek,
    onSkipBackward,
    onSkipAhead,
    playing,
    onTogglePlay: togglePlaying,
    canSkipBackward,
    canSkipForward,
  });
};

export default AudioPlayerController;
