import type { BuildMapLinkParams, LocationDescriptor } from './interfaces';

export const buildGoogleMapDirectionsLink = ({ origin, destination, waypoints }: BuildMapLinkParams) => {
  const baseUrl = `https://www.google.com/maps/dir/?api=1`;
  const params: string[] = [];

  if (origin.type === 'place') {
    params.push(`origin_place_id=${origin.placeId}`);
  } else {
    params.push(`origin=${buildLocationString(origin)}`);
  }

  if (destination.type === 'place') {
    params.push(`destination_place_id=${destination.placeId}`);
  } else {
    params.push(`destination=${buildLocationString(destination)}`);
  }

  if (waypoints.length > 0) {
    params.push(`waypoints=${waypoints.map(buildLocationString).join('|')}`);
  }

  return `${baseUrl}&${params.join('&')}`;
};

const buildLocationString = (location: LocationDescriptor) => {
  switch (location.type) {
    case 'coords':
      return `${location.latitude},${location.longitude}`;
    case 'address':
      return encodeURIComponent(location.address);
    case 'place':
      return `place_id:${location.placeId}`;
  }
};