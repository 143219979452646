import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ActionMeta, MultiValue } from 'react-select';
import type {
  StylesConfig as SelectStylesConfig,
} from 'react-select';
import Select from 'react-select';
import { searchKols } from '@/api/search';
import { buildSelectStyles, buildSelectTheme } from '@/components/Chat.Filters/Presentation';
import { useTheme } from '@/components/Theme';
import type { Chat } from '@/types';

type Item = Chat.QueryState.InviteAttendee;
type Props = {
  onSelect: (value: Item[]) => void;
  value: Item[];
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function HcpEmailMultiselect({
  onSelect,
  value,
}: Props) {

  const [inputValue, setInputValue] = useState('');

  const theme = useTheme();

  const selectStyles = useMemo(() => {
    const selectStyles = buildSelectStyles(theme);
    const mergedStyles: SelectStylesConfig = {
      ...selectStyles,
      control: (provided, _state) => ({
        ...provided,
        borderWidth: 2,
        borderRadius: 7,
      }),
      placeholder: base => ({
        ...base,
        fontSize: 16,
        fontFamily: theme.fonts.regular,
      }),
    };
    return mergedStyles;
  }, [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const handleChange = useCallback((value: MultiValue<Item>, _meta: ActionMeta<Item>) => {
    onSelect(value as Item[]);
  }, [onSelect]);

  const searchQuery = useQuery({
    queryKey: ['get:kol-search', inputValue],
    queryFn: ({ queryKey }) => {
      const value = queryKey[1];
      return searchKols({ name: value }).then(res => {
        const options = res.results.map(m => ({
          id: m.id,
          name: m.name,
          email: m.email,
        }));

        if (emailRegex.test(value) && !options.some((opt) => opt.email === value)) {
          options.unshift({
            id: null,
            name: null,
            email: value,
          });
        }
        return options;
      });
    },
    enabled: inputValue?.length > 2,
    placeholderData: [],
  });

  const options = searchQuery.data;
  const isLoading = searchQuery.isLoading;

  return (
    <Select<Item, true>
      styles={selectStyles}
      inputValue={inputValue}
      onInputChange={setInputValue}
      theme={selectTheme}
      isLoading={isLoading}
      loadingMessage={_ => 'Loading...'}
      isMulti={true}
      value={value}
      options={options}
      placeholder="Search for HCPs"
      getOptionLabel={o => o.name ? `${o.name} (${o.email})` : o.email}
      getOptionValue={o => `${o.id}`}
      onChange={handleChange}
      menuPlacement="auto" />
  );
}