import * as $api from '@/api/kol';
import { useQueryDownloader } from '@/containers/QueryDownloader/hooks';

type Params = {
  kolSearchId: number;
};

export const useDownloadKolSearch = (params: Params) => {

  return useQueryDownloader({
    queryKey: [
      `brand-insights:kol-search:download`,
      params.kolSearchId,
    ],
    queryFn: ({ queryKey: [, kolSearchId] }) => {
      return $api.downloadKolSearch({ kolSearchId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

};