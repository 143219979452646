import { useCallback, useMemo, useState } from 'react';

type TablePaginationProps = {
  index: number;
  pageSize: number;
  total: number;
};

export const useTablePagination = (props: TablePaginationProps) => {
  const [index, setIndex] = useState(props.index);
  const [size, setSize] = useState(props.pageSize);
  const [total, setTotal] = useState(props.total);

  const helpers = useTablePaginationHelpers({
    pageIndex: index,
    pageSize: size,
    setPageIndex: setIndex,
    total,
  });

  return useMemo(() => ({
    index,
    size,
    total,
    setPageSize: setSize,
    setTotal,
    ...helpers,
  }), [
    index, size, total, helpers,
    setSize, setTotal,
  ]);
};

type HelperProps = {
  pageIndex: number;
  pageSize: number;
  setPageIndex: (index: number) => void;
  total: number;
};

export function useTablePaginationHelpers({
  pageIndex,
  pageSize,
  setPageIndex,
  total,
}: HelperProps) {
  const pageCount = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);

  const nextPage = useCallback(() => {
    setPageIndex(Math.min(pageIndex + 1, pageCount - 1));
  }, [pageCount, pageIndex, setPageIndex]);

  const previousPage = useCallback(() => {
    setPageIndex(Math.max(pageIndex - 1, 0));
  }, [pageIndex, setPageIndex]);

  const canNextPage = useMemo(() => pageIndex + 1 < pageCount, [pageIndex, pageCount]);
  const canPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);

  return {
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  };
};