import { Fragment, useLayoutEffect } from 'react';
import type { Chat } from '@/types';
import { useChatPermissions, useScrollToBottomOfMessages } from './hooks';
import { ChatItem } from './Item';

type Props = {
  items: Chat.InstanceItem[];
};

export const MessageList = ({ items }: Props) => {
  const scrollToBottom = useScrollToBottomOfMessages();
  const chatPermissions = useChatPermissions();

  useLayoutEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const view = {
    regenerate: chatPermissions.canModify,
  };

  return (
    <Fragment>
      {items.map((item, i) => (
        <ChatItem
          key={i}
          canRegenerate={view.regenerate && i === items.length - 1}
          item={item} />
      ))}
    </Fragment>
  );
};