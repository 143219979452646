import type { BuildMapLinkParams, LocationDescriptor } from './interfaces';

export const buildAppleMapDirectionsLink = ({ origin, destination, waypoints }: BuildMapLinkParams) => {
  const baseUrl = `http://maps.apple.com/`;
  const params: string[] = [];

  params.push(`saddr=${buildLocationString(origin)}`);

  //Apple maps has undocumented support for multiple waypoints.
  //Since the query param name is the same as the destination the destination must be added last
  for (const waypoint of waypoints) {
    params.push(`daddr=${buildLocationString(waypoint)}`);
  }

  params.push(`daddr=${buildLocationString(destination)}`);

  return `${baseUrl}?${params.join('&')}`;
};

const buildLocationString = (location: LocationDescriptor) => {
  switch (location.type) {
    case 'coords':
      return `${location.latitude},${location.longitude}`;
    case 'address':
      return encodeURIComponent(location.address);
    case 'place':
      throw new Error('Apple Maps does not support place IDs');
  }
};