import { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { LegendCollapseToggleContext } from '../Chat/context';
import { SimpleAccordion } from './Accordion/SimpleAccordion';
import { LabelWithColor } from './ColorIndicator';
import styles from './style/legend.module.css';

type LegendItem = {
  key: string;
  color: string;
};

type Props = {
  legend: LegendItem[];
};

export const ChartLegend = memo((props: Props) => {
  const [open, setOpen] = useContext(LegendCollapseToggleContext);

  return (
    <Root>
      <SimpleAccordion
        label={<LegendText>Chart Key</LegendText>}
        open={open}
        grows={true}
        height={30}
        iconHeight={50}
        toggleOpen={() => setOpen(old => !old)}
        className={
          styles.accordion
        }>
        <ColorTable>
          {props.legend.map(item => (
            <div key={item.key}>
              <Color>
                <LabelWithColor
                  color={{ value: item.color }}
                  label={{ value: item.key }} />
              </Color>
            </div>
          ))}
        </ColorTable>
      </SimpleAccordion>
    </Root>
  );
});

const Root = styled.div`
  border: 1px solid var(--gray-l);
  border-radius: 15px;
  padding: 0 5px;
`;

const LegendText = styled.div(style => ({
  fontFamily: style.theme.fonts.semiBold,
  padding: '8px',
}));


const ColorTable = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const Color = styled.div({
  padding: 10,
  borderTop: '1px solid var(--gray-l)',
});