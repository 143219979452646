import { useRef, useImperativeHandle, forwardRef, memo } from 'react';
import { Search } from '@/components/icons/Search';
import styles from './style/HeroSearchBar.module.css';

type Props = {
  query: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
};

export type HeroSearchBarRef = {
  form: HTMLFormElement;
  input: HTMLInputElement;
};

export const HeroSearchBar = memo(
  forwardRef<HeroSearchBarRef, Props>(({ query, onChange, onFocus, onSubmit }, ref) => {
    const formRef = useRef<HTMLFormElement>();
    const inputRef = useRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
      get form() {
        return formRef.current;
      },
      get input() {
        return inputRef.current;
      },
    }));

    return (
      <form
        ref={formRef}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={styles.form}
        onSubmit={onSubmit}>
        <div className={styles.wrap}>
          <Search
            className={styles.icon}
            size={22} />
          <input
            ref={inputRef}
            className={styles.input}
            name="search"
            placeholder="Search resources"
            type="text"
            onChange={onChange}
            onFocus={onFocus}
            value={query} />
        </div>
      </form>
    );
  }),
);

export default HeroSearchBar;