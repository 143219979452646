import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import type { ChatResponseType } from '@/enums';
import { createNamedContext } from '@/utils';

type TextToSpeechAudioPlayerRefValue = [HTMLAudioElement | undefined, Dispatch<HTMLAudioElement | undefined>];

type InitiateQuerySpeechToText = (data: TextToSpeechState) => unknown;
export type TextToSpeechState = {
  kolSearchId: number | null;
  queryId: number | null;
  isPlaying: boolean;
};

type VoiceModeContextValue = {
  dequeue: () => void;
  enabled: boolean;
  enqueue: (data: { queryId: number; type: ChatResponseType }) => void;
  queryId: number;
  type: ChatResponseType;
  toggle: () => void;
};

export type MicState = 'inactive' | 'waiting-for-permission' | 'listening' | 'error';

export type MicrophoneStateContextValue = [MicState, Dispatch<SetStateAction<MicState>>];

export const TextToSpeechAudioPlayerRefContext = createNamedContext<TextToSpeechAudioPlayerRefValue>(
  undefined,
  'TextToSpeechAudioPlayer.Context',
);

export const OnTextToSpeechAudioReadyContext = createNamedContext<(audioUrl: string) => void>(null, 'OnTextToSpeechAudioReady');
export const InitiateQuerySpeechToText = createNamedContext<InitiateQuerySpeechToText>(null, 'InitiateQuerySpeechToText');
export const TextToSpeechStateContext = createNamedContext<TextToSpeechState>(null, 'TextToSpeechState');
export const StopTextToSpeechContext = createNamedContext<() => void>(null, 'StopTextToSpeech');
export const VoiceModeContext = createNamedContext<VoiceModeContextValue>(undefined, 'VoiceMode.Context');
export const MicrophoneStateContext = createNamedContext<MicrophoneStateContextValue>(undefined, 'MicrophoneState.Context');
export const useTextToSpeechAudioPlayerRef = () => useContext(TextToSpeechAudioPlayerRefContext);