import { useCallback, useMemo, useState } from 'react';
import { useDebounceCallback } from '@/hooks/useDebounce';
import type { GroupUser } from '@/types/group';
import type {
  ObjectAccessContextValue,
} from './Context';
import {
  FetchObjectAccessContext,
  GroupUsersContext,
  ObjectAccessLoadingContext,
  SaveObjectAccessContext,
  ObjectAccessContext,
} from './Context';
import { useObjectAccessState } from './hooks';
import type { ObjectAccess as OA } from './interfaces';

type Props =
  OA.ObjectAccessContainerProps;

export const BaseObjectAccessContainer = ({
  fetchAccess: fetchAccessProp,
  fetchGroupUsers: fetchGroupUsersProp,
  saveAccess: saveAccessProp,
  ...props
}: Props) => {
  const [state, dispatch] = useObjectAccessState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ObjectAccessContextValue>({
    object: null,
    workspace: null,
  });

  const fetchAccess = useCallback(() => {
    setLoading(true);

    return fetchAccessProp()
      .then(data => {
        dispatch({
          object: data.object,
          items: data.items,
          type: 'load',
        });
        setValues({
          object: data.object,
          workspace: data.workspace,
        });
        setLoading(false);
      });

  }, [
    dispatch,
    fetchAccessProp,
  ]);

  const saveAccess = useCallback(() => {
    return saveAccessProp({
      items: state.items.map(m => ({
        roleId: m.roleId,
        userId: m.user.id,
      })),
    }).then(_ => {
      dispatch({
        type: 'access-saved',
      });
    });
  }, [
    dispatch,
    saveAccessProp,
    state.items,
  ]);

  const [users, setUsers] = useState<GroupUser[]>(null);

  const fetchGroupUsers = useCallback((value?: string) => {
    if (!values.object?.groupId) {
      return;
    }
    return fetchGroupUsersProp({
      excluded: state.items.map(m => m.user.id),
      groupId: values.object.groupId,
      size: 15,
      value,
    }).then(data => {
      setUsers(data.items);
    });
  }, [
    values.object?.groupId,
    fetchGroupUsersProp,
    state.items,
  ]);

  const fetchGroupUsersDebounced = useDebounceCallback(fetchGroupUsers, 200);

  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter(f => !state.items.some(s => s.user.id === f.id));
  }, [
    state.items,
    users,
  ]);

  const groupCtx = {
    items: filteredUsers,
    fetch: fetchGroupUsersDebounced,
  };

  return (
    <FetchObjectAccessContext.Provider value={fetchAccess}>
      <ObjectAccessLoadingContext.Provider value={loading}>
        <SaveObjectAccessContext.Provider value={saveAccess}>
          <ObjectAccessContext.Provider value={values}>
            <GroupUsersContext.Provider value={groupCtx}>
              {props.children}
            </GroupUsersContext.Provider>
          </ObjectAccessContext.Provider>
        </SaveObjectAccessContext.Provider>
      </ObjectAccessLoadingContext.Provider>
    </FetchObjectAccessContext.Provider>
  );
};

export default BaseObjectAccessContainer;