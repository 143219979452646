import { WorkspaceObjectRole } from '@/enums/workspace';
import type { ObjectAccessUser } from '@/types/workspace.access';

export function sortObjectAccess(a: ObjectAccessUser, b: ObjectAccessUser) {
  const isOwnerA = a.roleId === WorkspaceObjectRole.Owner;
  const isOwnerB = b.roleId === WorkspaceObjectRole.Owner;
  return (isOwnerA === isOwnerB)
    ? 0
    : isOwnerA
      ? -1
      : 1;
}