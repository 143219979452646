import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useFloating, useInteractions, useDismiss, autoUpdate, offset } from '@floating-ui/react';
import type { BuildMapLinkParams } from '@/lib/interfaces';
import { PopperWrapper } from '../Popper';
import type { MapsLinkResult } from './hooks/useGetMapTargets';
import { useMapLinks } from './hooks/useGetMapTargets';
import { BaseMessageButton } from './Message.Button';

type Props = {
  mapParams: BuildMapLinkParams;
} & ChildrenProps;

export const OpenMapsButton = ({ mapParams, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const mapLinks = useMapLinks({ mapParams });

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    whileElementsMounted: autoUpdate,
    middleware: [offset(10)],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  if (mapLinks.length === 1) {
    return (
      <a href={mapLinks[0].mapsLink} target="_blank" rel="noreferrer">
        <GetDirectionsButton>
          {children}
        </GetDirectionsButton>
      </a>
    );
  }

  return (
    <>
      <GetDirectionsButton ref={refs.setReference} onClick={() => setIsOpen(old => !old)} {...getReferenceProps()}>
        {children}
      </GetDirectionsButton>
      {isOpen && <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
        <PopperContent mapLinks={mapLinks} />
      </div>
      }
    </>
  );
};

const GetDirectionsButton = styled(BaseMessageButton)(() => ({
  marginTop: 15,
}));

type PopperContentProps = {
  mapLinks: MapsLinkResult[];
};

const PopperContent = (props: PopperContentProps) => {
  const [clickedLinks, setClickedLinks] = useState<MapsLinkResult[]>([]);
  const onClick = useCallback((link: MapsLinkResult) => {
    if (!clickedLinks.includes(link)) {
      setClickedLinks([...clickedLinks, link]);
    }
  }, [clickedLinks]);

  return (
    <PopperWrapper>
      <MapsIconContainer>
        {props.mapLinks.map((link, index) => (
          <div>
            <a key={index} href={link.mapsLink} target="_blank" rel="noreferrer" onClick={() => onClick(link)}>
              <MapsIcon src={link.icon} alt="Open in app" />
            </a>
            <MapTitle>{link.title}</MapTitle>
            {clickedLinks.includes(link) && (
              <AppLink href={link.appStoreLink} target="_blank" rel="noreferrer">Download</AppLink>
            )}
          </div>
        ))}
      </MapsIconContainer>
    </PopperWrapper>
  );
};

const MapsIconContainer = styled.div({
  display: 'flex',
  gap: 10,
  padding: 10,
});

const MapsIcon = styled.img(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: 8,
  border: `1px solid ${theme.palette.gray.light1}`,
}));

const MapTitle = styled.div({
  textAlign: 'center',
  fontSize: 12,
  marginTop: 5,
});

const AppLink = styled.a({
  marginTop: 5,
  fontSize: 12,
  textAlign: 'center',
});