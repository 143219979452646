import { useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { SocketNamespace } from '@/enums/websocket';
import { getSocket } from '@/websocket/socket';
import { useSetupDefaultSocketListeners } from './useSetupDefaultListeners';

export const useChatSocket = (chatIdentifier: string) => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (chatIdentifier) {
      const newSocket = getSocket(SocketNamespace.Chat);

      setSocket(newSocket);

      newSocket.emit('subscribe', chatIdentifier);

      return () => {
        newSocket.emit('unsubscribe', chatIdentifier);
        newSocket.disconnect();
      };
    }
  }, [chatIdentifier]);

  useSetupDefaultSocketListeners(socket, SocketNamespace.Chat);

  return socket;
};