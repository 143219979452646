import { cx } from '@/utils';
import type { AccordionProps } from './interface';
import styles from './style.module.css';

type Props = {
  children: React.ReactNode;
  count: number;
  onClick?: () => unknown;
  open: boolean;
} & Pick<AccordionProps,
  'className'
  | 'grows'
  | 'height'>;

const defaultProps = {
  grows: false,
  onClick: () => { },
};

const Panel = ({
  grows = defaultProps.grows,
  onClick = defaultProps.onClick,
  ...props
}: Props) => {

  const style = {
    minHeight: props.open ? props.height : grows ? props.height * 1.25 : props.height,
    marginBottom: grows ? 20 : 0,
  };

  const classes = props.count < 1
    ? styles.empty
    : cx(props.open ? styles.expanded : styles.collapsed);

  return (
    <div
      className={cx(classes, props.className)}
      onClick={onClick}
      style={style}>
      {props.children}
    </div>
  );
};

export { Panel };
export default Panel;