export const Sidebar = (() => {
  const HorizontalPadding = 15;

  return {
    HorizontalPadding,

    IconSize: 44,

    ItemVerticalPadding: 5,
    ItemHorizontalPadding: HorizontalPadding,

    ArrowTogglerSize: 26,

    ExpandedWidth: 240,
    Width: 75,
  };
})();

export const Message = (() => {
  return {
    CitationScoreWidth: 30,
  };
})();

export const Modal = (() => {
  return {
    CloseButtonSize: 40,
    BorderRadius: 6,
    HeaderHeight: 55,
  };
})();

export const Header = (() => {
  return {
    ItemHeight: 30,
    ItemBorderRadius: 4,
  };
})();

export const Navbar = (() => {
  return {
    Height: 60,
  };
})();

export const Veeva = (() => {
  return {
    ChatFooterBottomPadding: 60,
  };
})();