import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import SpeechRecognition from 'react-speech-recognition';
import * as $api from '@/api';
import { startAsyncAudioSynthesis } from '@/api/audio-status';
import { ActiveChatSessionContext } from '@/components/Chat/context';
import type { ChatResponseType } from '@/enums';
import { useAppSelector } from '@/store';
import { useChatVoiceSource } from '@/store/selectors';
import { AudioPlayerContainer, useAudioPlayerRef, useBootstrapAudioPlayer } from '../AudioPlayer';
import type { TextToSpeechState } from './Context';
import {
  TextToSpeechStateContext,
  InitiateQuerySpeechToText,
  MicrophoneStateContext,
  OnTextToSpeechAudioReadyContext,
  StopTextToSpeechContext,
  VoiceModeContext,
} from './Context';
import { MicrophoneStateContainer } from './MicrophoneState.Container';

type Props = {
  children: React.ReactNode;
};

const defaultTTSState: TextToSpeechState = {
  kolSearchId: null,
  queryId: null,
  isPlaying: false,
};

export const TopLevelTTSContainer = (props: Props) => {
  const chatVoiceSource = useChatVoiceSource();
  const ttsEnabled = useAppSelector(state => state.group.features.chatReadAloud);

  if (!ttsEnabled) {
    return (
      <MicrophoneStateContainer>
        <VoiceModeContainer>
          {props.children}
        </VoiceModeContainer>
      </MicrophoneStateContainer>
    );
  }

  if (chatVoiceSource === 'browser') {
    return (
      <MicrophoneStateContainer>
        <VoiceModeContainer>
          <AudioPlayerContainer>
            <BrowserTTSContainer {...props} />
          </AudioPlayerContainer>
        </VoiceModeContainer>
      </MicrophoneStateContainer>
    );
  }

  return (
    <MicrophoneStateContainer>
      <VoiceModeContainer>
        <AudioPlayerContainer>
          <TextToSpeechAudioPlayerContainer {...props} />
        </AudioPlayerContainer>
      </VoiceModeContainer>
    </MicrophoneStateContainer>
  );
};

const BrowserTTSContainer = ({ children }: Props) => {
  const [pendingAudio, setPendingAudio] = useState<TextToSpeechState>(defaultTTSState);
  const chat = useContext(ActiveChatSessionContext);

  const stopTextToSpeech = useCallback(() => {
    speechSynthesis.cancel();
    setPendingAudio(defaultTTSState);
  }, []);

  const handleTextToSpeechInitiated = useCallback(async (data: TextToSpeechState) => {
    function handleTextAvailable(value: string) {
      const utterance = new SpeechSynthesisUtterance(value);
      utterance.lang = 'en-US';
      utterance.rate = 1;
      utterance.pitch = 1;
      speechSynthesis.speak(utterance);
      setPendingAudio(data);
    }

    if (data.queryId) {
      return $api.getQueryResponseText({
        chatInstanceId: chat.id,
        queryId: data.queryId,
        tts: true,
      }).then(handleTextAvailable);
    } else if (data.kolSearchId) {
      return $api.getKolSearchProfileText({
        kolSearchId: data.kolSearchId,
        tts: true,
      }).then(handleTextAvailable);
    }
  }, [chat.id]);

  return (
    <InitiateQuerySpeechToText.Provider value={handleTextToSpeechInitiated}>
      <StopTextToSpeechContext.Provider value={stopTextToSpeech}>
        <TextToSpeechStateContext.Provider value={pendingAudio}>
          <VoiceModeContainer>
            {children}
          </VoiceModeContainer>
        </TextToSpeechStateContext.Provider>
      </StopTextToSpeechContext.Provider>
    </InitiateQuerySpeechToText.Provider>
  );
};

const TextToSpeechAudioPlayerContainer = ({ children }: Props) => {
  const [playerRef, setPlayerRef] = useAudioPlayerRef();
  const [pendingAudio, setPendingAudio] = useState<TextToSpeechState>(defaultTTSState);
  const bootstrapAudioPlayer = useBootstrapAudioPlayer();
  const [micState, setMicState] = useContext(MicrophoneStateContext);
  const voicemode = useContext(VoiceModeContext);

  const handleTextToSpeechAudioReady = useCallback((audioUrl: string) => {
    console.log('AudioStatusListener returned audio URL:', audioUrl);
    console.log('playerRef:', playerRef);
    if (playerRef) {
      playerRef.src = audioUrl;
      // Mark as playing
      setPendingAudio(prev =>
        prev ? { ...prev, isPlaying: true } : defaultTTSState,
      );
      playerRef.play();

      const onEnded = () => {
        setPendingAudio(defaultTTSState);
        playerRef.removeEventListener('ended', onEnded);
      };

      playerRef.addEventListener('ended', () => {
        if (micState !== 'error') {
          if (voicemode.enabled) {
            setMicState('waiting-for-permission');
            SpeechRecognition.startListening({ continuous: true });
          }
        }
        onEnded();
      });
    }
  }, [playerRef, micState, setMicState, voicemode]);

  const audioMutation = useMutation({
    mutationFn: (data: TextToSpeechState) => {
      return startAsyncAudioSynthesis(data);
    },
    onMutate: async (data: TextToSpeechState) => {
      console.log('Async synthesis task started:');
      setPendingAudio(data);
    },
    onSuccess: (data) => {
      console.log('audio mutation success:', data);
      if (data.status === 'ready') {
        handleTextToSpeechAudioReady(data.audioUrl);
      }
    },
  });

  const handleTextToSpeechInitiated = useCallback(async (data: TextToSpeechState) => {
    console.log('handleTextToSpeechInitiated:', data);
    return audioMutation.mutateAsync(data);
  }, [audioMutation]);

  const stopTextToSpeech = useCallback(() => {
    if (playerRef) {
      playerRef.pause();
      playerRef.currentTime = 0;
    }
    setPendingAudio(defaultTTSState);
  }, [playerRef]);

  return (
    <TextToSpeechStateContext.Provider value={pendingAudio}>
      <OnTextToSpeechAudioReadyContext.Provider value={handleTextToSpeechAudioReady}>
        <InitiateQuerySpeechToText.Provider value={handleTextToSpeechInitiated}>
          <StopTextToSpeechContext.Provider value={stopTextToSpeech}>
            {children}
            <audio {...bootstrapAudioPlayer()} ref={setPlayerRef} />
          </StopTextToSpeechContext.Provider>
        </InitiateQuerySpeechToText.Provider>
      </OnTextToSpeechAudioReadyContext.Provider>
    </TextToSpeechStateContext.Provider>
  );
};

export { TextToSpeechAudioPlayerContainer };
export default TextToSpeechAudioPlayerContainer;

type VoiceModeContainerProps = {
  children: React.ReactNode;
};

const VoiceModeContainer = (props: VoiceModeContainerProps) => {
  const [voicemode, setVoiceMode] = useState(false);
  const [queryId, setQueryId] = useState<number>(null);
  const [type, setType] = useState<ChatResponseType>(null);
  const [micState, setMicState] = useContext(MicrophoneStateContext);

  const toggleVoiceMode = useCallback(() => {
    if (!voicemode && micState !== 'listening') {
      setMicState('waiting-for-permission');
      SpeechRecognition.startListening({ continuous: true });
    }

    setVoiceMode(prev => !prev);
  }, [voicemode, micState, setMicState]);

  const enqueue = useCallback((data: { queryId: number; type: ChatResponseType }) => {
    setQueryId(data.queryId);
    setType(data.type);
  }, []);

  const dequeue = useCallback(() => {
    setQueryId(null);
  }, []);

  const vm = {
    dequeue,
    enabled: voicemode,
    enqueue,
    queryId,
    toggle: toggleVoiceMode,
    type,
  };

  return (
    <VoiceModeContext.Provider value={vm}>
      {props.children}
    </VoiceModeContext.Provider>
  );
};
