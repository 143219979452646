export enum SocketNamespace {
  AsyncActivity = 'async-activity',
  Chat = 'chat',
  Queries = 'chat/queries',
}

export enum QuerySocketEvent {
  QueryTakingTooLong = 'query/taking-too-long',
  QueryAvailable = 'query/available',
  QueryErrored = 'query/errored',
  QueryFollowupsAvailable = 'query/followups-available',
  QueryAudioAvailable = 'query-audio-available',
}

export enum ChatSocketEvent {
  ChatDeleted = 'chat-deleted',
}

export enum LifeCycleSocketEvent {
  Connect = 'connect',
  ConnectError = 'connect_error',
  Disconnect = 'disconnect',
}

export enum ReportSocketEvent {
  ReportAvailable = 'report-available',
  ReportError = 'report-error',
}

export type SocketEvent =
  | LifeCycleSocketEvent
  | ReportSocketEvent
  | ChatSocketEvent;