import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  RotateCcw,
  SkipBack,
  Play,
  Pause,
  SkipForward,
  RotateCw,
  Square,
} from 'react-feather';
import AudioPlayerTrackProgress from '@/components/AudioPlayer/Progress.Track';

interface AudioControlsProps {
  isPlaying: boolean;
  onTogglePlay: () => void;
  onPreviousTrack: () => void;
  onNextTrack: () => void;
  onSkipBackward: () => void;
  onSkipAhead: () => void;
  onStop: () => void;
  currentTime?: number;
  duration?: number;
  onScan: (time: number) => void;
  onSeek?: (time: number) => void;
  disabledPreviousTrack?: boolean;
  disabledNextTrack?: boolean;
}

const ControlsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const Button = styled('button')({
  background: 'none',
  border: 'none',
  padding: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

const Filled = styled('span')(({ theme }) => ({
  svg: {
    fill: theme.palette.gray.dark1,
    stroke: theme.palette.gray.dark1,
  },
}));

const TimeDisplay = styled('span')(({ theme }) => ({
  fontSize: '0.8rem',
  minWidth: '50px',
  textAlign: 'right',
  whiteSpace: 'nowrap',
  color: theme.palette.gray.dark1,
}));

const ProgressAndTimer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginTop: '8px',
});

const StyledProgressTrack = styled(AudioPlayerTrackProgress)({
  width: 100,
});

function formatTime(seconds?: number) {
  if (!seconds || isNaN(seconds)) return '0:00';
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${m}:${s < 10 ? '0' + s : s}`;
}

// TODO: implement disabled state (styling) for buttons

const AudioControls: React.FC<AudioControlsProps> = ({
  isPlaying,
  onTogglePlay,
  onPreviousTrack,
  onNextTrack,
  onSkipBackward,
  onSkipAhead,
  onStop,
  currentTime,
  duration,
  onScan,
  onSeek,
  disabledPreviousTrack,
  disabledNextTrack,
}) => {
  const theme = useTheme();

  return (
    <div>
      <ControlsContainer>
        <Button onClick={onSkipBackward} disabled={disabledPreviousTrack}>
          <RotateCcw size={14} color={theme.palette.gray.dark1} />
        </Button>

        <Button onClick={onPreviousTrack} disabled={disabledPreviousTrack}>
          <Filled>
            <SkipBack size={14} />
          </Filled>
        </Button>

        <Button onClick={onTogglePlay}>
          <Filled>
            {isPlaying ? <Pause size={18} /> : <Play size={18} />}
          </Filled>
        </Button>

        <Button onClick={onNextTrack} disabled={disabledNextTrack}>
          <Filled>
            <SkipForward size={14} />
          </Filled>
        </Button>

        <Button onClick={onSkipAhead} disabled={disabledNextTrack}>
          <RotateCw size={14} color={theme.palette.gray.dark1} />
        </Button>

        <Button onClick={onStop}>
          <Filled>
            <Square size={14} />
          </Filled>
        </Button>
      </ControlsContainer>
      <ProgressAndTimer>
        <StyledProgressTrack
          position={currentTime}
          scan={onScan}
          seek={onSeek}
        />
        <TimeDisplay>
          {formatTime(currentTime)} / {formatTime(duration)}
        </TimeDisplay>
      </ProgressAndTimer>
    </div>
  );
};

export default AudioControls;
