import qs from 'query-string';
import { getAuthenticatedClient } from './client';
import type { WorkspaceObjects as WS } from './interfaces/workspace.object';
import { normalizeWorkspaceObject, transformDates } from './transformers';
import { normalizeWorkspace } from './transformers/workspaces.workspace';

export const getObjectAccess = ({ objectId, workspaceId }: WS.GetObjectAccess.Request) => {
  return getAuthenticatedClient().get<WS.GetObjectAccess.Response>(`/workspaces/${workspaceId}/objects/${objectId}/access`)
    .then(res => ({
      items: res.data.items,
      object: normalizeWorkspaceObject(res.data.object),
      workspace: normalizeWorkspace(res.data.workspace),
    }));
};

export const moveObject = (data: WS.MoveObject.Request) => {
  return getAuthenticatedClient().post<WS.MoveObject.Response>(`/workspaces/${data.workspaceId}/objects/${data.objectId}/move`, {
    parentObjectId: data.parentObjectId,
  }).then(res => ({
    object: normalizeWorkspaceObject(res.data.object),
  }));
};

export const moveObjectDetails = (data: WS.GetMoveObjectDetails.Request) => {
  return getAuthenticatedClient().get<WS.GetMoveObjectDetails.Response>(`/workspaces/objects/${data.objectId}/move`, {
    transformResponse: transformDates,
  });
};

export const saveObjectAccess = (data: WS.SaveObjectAccess.Request) => {
  return getAuthenticatedClient().post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/access`, {
    items: data.items,
  });
};

export const updateObjectExpiration = (data: WS.UpdateObjectExpiration.Request) => {
  return getAuthenticatedClient().put<WS.UpdateObjectExpiration.Response<string>>(`/workspaces/${data.workspaceId}/objects/${data.objectId}/expiration`, {
    expiresOn: data.expiresOn,
  }).then(res => ({
    object: normalizeWorkspaceObject(res.data.object),
  }));
};

export const updateObjectOwnership = (data: WS.UpdateObjectOwnership.Request) => {
  return getAuthenticatedClient().post<WS.UpdateObjectOwnership.Response>(`/workspaces/${data.workspaceId}/objects/${data.objectId}/owner`, {
    ownerId: data.ownerId,
  }).then(res => ({
    object: normalizeWorkspaceObject(res.data.object),
  }));
};

export const getObjectChildren = (data: WS.GetObjectChildren.Request): Promise<WS.GetObjectChildren.Response> => {
  const query = qs.stringify({
    filters: JSON.stringify(data.filters || {}),
  }, { arrayFormat: 'bracket' });
  return getAuthenticatedClient().get(`/workspaces/${data.workspaceId}/objects/${data.parentObjectId}/children?${query}`, {
    transformResponse: transformDates,
  });
};

export const getSharedObjects = (data: WS.GetSharedObjects.Request): Promise<WS.GetSharedObjects.Response> => {
  const query = qs.stringify({
    parentObjectId: data.parentObjectId,
    filters: JSON.stringify(data.filters || {}),
  }, { arrayFormat: 'bracket' });
  return getAuthenticatedClient().get(`/workspaces/shared/objects?${query}`, {
    transformResponse: transformDates,
  });
};