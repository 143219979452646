import { useState } from 'react';
import { type MicState, MicrophoneStateContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const MicrophoneStateContainer = (props: Props) => {
  const [micState, setMicState] = useState<MicState>('inactive');

  return (
    <MicrophoneStateContext.Provider value={[micState, setMicState]}>
      {props.children}
    </MicrophoneStateContext.Provider>
  );
};

MicrophoneStateContainer.displayName = 'MicrophoneState.Container';