import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { NavBar } from '@/components/Nav/NavBar';
import Toast from '@/components/Toast';
import { DocumentHelmet } from './Helmet';

export const Layout = () => {
  return (
    <>
      <DocumentHelmet />
      <NavBar />
      <MainContent>
        <Outlet />
      </MainContent>
      <Toast />
    </>
  );
};

const MainContent = styled.main({
  paddingTop: `var(--navbar-height)`,
});