import { z } from 'zod';

export enum ChatActionType {
  ReaskWithHcp = 'reask-with-hcp',
  SelectLocation = 'select-location',
  BuildRoute = 'build-route',
}
export const ChatActionTypeModel = z.nativeEnum(ChatActionType);

export const ReaskWithHcpModel = z.object({
  type: z.literal(ChatActionType.ReaskWithHcp),
  payload: z.object({
    hcpId: z.number(),
    queryId: z.number(),
  }),
});
export type ReaskWithHcpChatAction = z.infer<typeof ReaskWithHcpModel>;

export const SelectLocationModel = z.object({
  type: z.literal(ChatActionType.SelectLocation),
  payload: z.object({
    location: z.discriminatedUnion('type', [z.object({
      type: z.literal('browser'),
      latitude: z.number(),
      longitude: z.number(),
    }), z.object({
      type: z.literal('search'),
      longitude: z.number(),
      latitude: z.number(),
      name: z.string(),
      id: z.string(),
    })]),
    queryId: z.number(),
  }),
});
export type SelectionLocationChatAction = DeepRequired<z.infer<typeof SelectLocationModel>>;

export const BuildRouteModel = z.object({
  type: z.literal(ChatActionType.BuildRoute),
  payload: z.object({
    origin: z.object({
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
    }),
    kols: z.array(z.object({
      kolId: z.number(),
      location: z.optional(z.object({
        latitude: z.number(),
        longitude: z.number(),
        address: z.string(),
      })),
    })),
  }),
});
export type BuildRouteChatAction = z.infer<typeof BuildRouteModel>;

export type ChatAction<T extends ChatActionType = ChatActionType> = {
  [ChatActionType.ReaskWithHcp]: ReaskWithHcpChatAction;
  [ChatActionType.SelectLocation]: SelectionLocationChatAction;
  [ChatActionType.BuildRoute]: BuildRouteChatAction;
}[T];

type DeepRequired<T> = {
  [K in keyof T]: Required<DeepRequired<T[K]>>
};