import { useCallback, useContext, useEffect } from 'react';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { ChatQueryStatus } from '@/enums';
import { QuerySocketEvent } from '@/enums/websocket';
import { useChatQuerySocket } from '@/websocket';
import type { QueryFollowupsAvailable } from '@/websocket/interfaces';

type Props = {
  queryIdentifier: string;
  queryStatus: ChatQueryStatus;
} & ChildrenProps;

export const QueryFollowupsContainer = (props: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);
  const socket = useChatQuerySocket(props.queryIdentifier);

  const handleFollowupsAvailable = useCallback((data: QueryFollowupsAvailable.Data) => {
    dispatch({
      type: 'query-followups-available',
      payload: {
        queryIdentifier: data.queryIdentifier,
        items: data.items,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;

    if (props.queryStatus === ChatQueryStatus.Complete) {
      socket.on(QuerySocketEvent.QueryFollowupsAvailable, handleFollowupsAvailable);
    }

    return () => {
      socket.off(QuerySocketEvent.QueryFollowupsAvailable, handleFollowupsAvailable);
    };
  }, [handleFollowupsAvailable, socket, props.queryStatus]);

  return (
    <>
      {props.children}
    </>
  );
};