import { useQuery } from '@tanstack/react-query';
// import * as api from '@/api';
import { ActivityIndicator } from '@/components/presentation/ActivityIndicator';
import { EntityTypesContext } from './Context';

export const EntityTypesContainer = (props: ChildrenProps) => {
  const entities = useQuery({
    queryKey: [`get:search/transcriptions/entities`],
    // queryFn: api.search.transcriptions.fetchEntityTypes,
    queryFn: () => {
      return Promise.resolve({
        items: [
          { id: 'organization', name: 'Organization' },
          { id: 'medical_condition', name: 'Medical Condition' },
          { id: 'medical_process', name: 'Medical Process' },
          { id: 'injury', name: 'Injury' },
          { id: 'drug', name: 'Drug' },
        ],
      });
    },
    refetchOnWindowFocus: false,
  });

  if (entities.isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <EntityTypesContext.Provider value={entities.data}>
      {props.children}
    </EntityTypesContext.Provider>
  );
};